import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import {
  BandFan,
  Friendship,
  FriendshipRequest,
  FriendshipRequestStatus,
  User,
  UserPreferences,
} from "src/API";
import * as queries from "./queries";
import * as mutations from "./mutations";
import { isValidUsername } from "src/utils/username";

const createUserPreferencesForUser = async (
  userId: string,
): Promise<UserPreferences> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createUserPreferences, { input: { userId } }),
  )) as GraphQLResult<{ createUserPreferences: UserPreferences }>;

  if (result.errors) {
    console.log(
      "[ERROR] error creating user preferences for user that didnt have them",
      result.errors,
    );
    throw result.errors;
  }
  if (result.data?.createUserPreferences) {
    return result.data.createUserPreferences;
  }
  throw new Error("Failed to create user preferences for user");
};

export const getUserPreferncesForUser = async (
  userId: string,
): Promise<UserPreferences> => {
  const result = (await API.graphql(
    graphqlOperation(queries.userPreferencesByUser, { userId }),
  )) as GraphQLResult<{ userPreferencesByUser: { items: UserPreferences[] } }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting user preferneces",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (
    result.data?.userPreferencesByUser &&
    result.data.userPreferencesByUser.items?.length > 0
  ) {
    return result.data.userPreferencesByUser.items[0];
  }
  const userPreferences = await createUserPreferencesForUser(userId);
  return userPreferences;
};

type GetUserResponse = {
  getUser: User;
};

export const getUser = async (userId: string): Promise<User | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.getUser, { id: userId }),
  )) as GraphQLResult<GetUserResponse>;

  if (result.errors) {
    console.log("[ERROR] error getting user", result.errors);
  }
  if (result.data?.getUser) {
    return result.data.getUser;
  }
  return null;
};

type UpdateUserProps = {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  profilePictureUrl?: string;
};

type UpdateUserResponse = {
  updateUser: User;
};

export const updateUser = async ({
  id,
  firstName,
  lastName,
  username,
  profilePictureUrl,
}: UpdateUserProps): Promise<User | null> => {
  if (!username || !isValidUsername(username)) {
    console.log("[ERROR] invalid username", username);
    return null;
  }
  const input = { id, firstName, lastName, username, profilePictureUrl };
  console.log("[DEBUG] input is", input);
  const result = (await API.graphql(
    graphqlOperation(mutations.updateUser, {
      input,
    }),
  )) as GraphQLResult<UpdateUserResponse>;

  if (result.errors) {
    console.log("[ERROR] error updating user", result.errors);
  }
  if (result.data?.updateUser) {
    return result.data.updateUser;
  }
  return null;
};

export const saveUserPreferences = async (
  userPreferencesId: string,
  userPreferences: UserPreferences,
): Promise<UserPreferences | null> => {
  const input = {
    ...userPreferences,
    id: userPreferencesId,
    createdAt: undefined,
    updatedAt: undefined,
    userId: undefined,
  };
  const result = (await API.graphql(
    graphqlOperation(mutations.updateUserPreferences, {
      input,
    }),
  )) as GraphQLResult<{ updateUserPreferences: UserPreferences }>;

  if (result.errors) {
    console.log("[ERROR] error updating user preferences", result.errors);
    throw result.errors;
  }
  if (result.data?.updateUserPreferences) {
    return result.data.updateUserPreferences;
  }
  throw new Error("Failed to update user preferences");
};

export const getUserFans = async (userId: string): Promise<BandFan[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandFansByUser, { userId }),
  )) as GraphQLResult<{ bandFansByUser: { items: BandFan[] } }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting user fans",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.bandFansByUser?.items) {
    return result.data.bandFansByUser.items;
  }
  return [];
};

export const friendshipsByUserAndFriend = async ({
  userId,
  friendId,
}: {
  userId: string;
  friendId: string;
}): Promise<Friendship | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.friendshipsByUserAndFriend, {
      userId,
      friendId: { eq: friendId },
    }),
  )) as GraphQLResult<{ friendshipsByUserAndFriend: { items: Friendship[] } }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting user fans",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (
    result.data &&
    result.data?.friendshipsByUserAndFriend?.items?.length > 0
  ) {
    return result.data.friendshipsByUserAndFriend.items[0];
  }
  if (
    result.data &&
    result.data?.friendshipsByUserAndFriend?.items?.length === 0
  ) {
    return null;
  }
  throw new Error("No friendships found");
};

export const requestFriendship = async ({
  userId,
  friendId,
}: {
  userId: string;
  friendId: string;
}): Promise<FriendshipRequest | null> => {
  const input = {
    senderId: userId,
    receiverId: friendId,
    status: FriendshipRequestStatus.PENDING,
  };
  console.log("[DEBUG] requestFriendship", input);
  const result = (await API.graphql(
    graphqlOperation(mutations.createFriendshipRequest, {
      input,
    }),
  )) as GraphQLResult<{ createFriendshipRequest: FriendshipRequest }>;

  if (result.errors) {
    console.log(
      "[ERROR] error creating friendship request",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.createFriendshipRequest) {
    return result.data.createFriendshipRequest;
  }
  return null;
};

export const friendshipRequestBySenderAndReciever = async ({
  senderId,
  receiverId,
}: {
  senderId: string;
  receiverId: string;
}): Promise<FriendshipRequest | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.friendshipRequestsBySenderAndReceiver, {
      senderId,
      receiverId: { eq: receiverId },
      filter: { status: { eq: FriendshipRequestStatus.PENDING } },
    }),
  )) as GraphQLResult<{
    friendshipRequestsBySenderAndReceiver: { items: FriendshipRequest[] };
  }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting friendship request",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (
    result.data &&
    result.data?.friendshipRequestsBySenderAndReceiver?.items?.length > 0
  ) {
    return result.data.friendshipRequestsBySenderAndReceiver.items[0];
  }
  if (
    result.data &&
    result.data?.friendshipRequestsBySenderAndReceiver?.items?.length === 0
  ) {
    return null;
  }

  console.log(
    "result.data?.friendshipRequestsBySenderAndReceiver",
    result.data?.friendshipRequestsBySenderAndReceiver,
  );
  throw new Error("No friendship request found");
};

export const acceptFriendshipRequest = async (id: string): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateFriendshipRequest, {
      input: { id, status: FriendshipRequestStatus.ACCEPTED },
    }),
  )) as GraphQLResult<{ updateFriendshipRequest: FriendshipRequest }>;

  if (result.errors) {
    console.log(
      "[ERROR] error accepting friendship request",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.updateFriendshipRequest) {
    return true;
  }
  return false;
};

export const rejectFriendshipRequest = async (id: string): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateFriendshipRequest, {
      input: { id, status: FriendshipRequestStatus.REJECTED },
    }),
  )) as GraphQLResult<{ updateFriendshipRequest: FriendshipRequest }>;

  if (result.errors) {
    console.log(
      "[ERROR] error rejecting friendship request",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.updateFriendshipRequest) {
    return true;
  }
  return false;
};

export const deleteFriendshipRequest = async (id: string): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.deleteFriendshipRequest, {
      input: { id },
    }),
  )) as GraphQLResult<{ deleteFriendshipRequest: FriendshipRequest }>;

  if (result.errors) {
    console.log(
      "[ERROR] error deleting friendship request",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.deleteFriendshipRequest) {
    return true;
  }
  return false;
};

export const deleteFriendship = async ({
  friendId,
}: {
  friendId: string;
}): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.removeFriendship, {
      friendId,
    }),
  )) as GraphQLResult<{ removeFriendship: string }>;

  if (result.errors) {
    console.log(
      "[ERROR] error deleting friendship",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.removeFriendship) {
    return true;
  }
  return false;
};

export const searchUsersByUsername = async (
  username: string,
): Promise<User[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.searchUsersByUsername, {
      query: username,
      searchableAttrs: ["username"],
      typoTolerance: false,
    }),
  )) as GraphQLResult<{ searchUsers: { items: User[] } }>;

  if (result.errors) {
    console.log(
      "[ERROR] error searching users",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.searchUsers?.items) {
    return result.data.searchUsers.items;
  }
  return [];
};
