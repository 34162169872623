import { Breadcrumbs, Container, Link, Typography } from "@mui/material";
import { getBandShowsForShow, getShow } from "./backend";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ShowForm } from "./ShowForm";
import { useEffect } from "react";

import { Link as RRLink } from "react-router-dom";

const useShow = (id?: string) => {
  return useQuery(
    ["show", id],
    async () => {
      if (!id) {
        throw new Error("No show id");
      }
      const show = await getShow(id);
      return show;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandShowsForShow = (id?: string) => {
  return useQuery(
    ["show", id, "bands"],
    async () => {
      if (!id) {
        return null;
      }
      const bandShows = await getBandShowsForShow(id);
      return bandShows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const EditShow = () => {
  const { id } = useParams<{ id: string }>();
  const { data: show, isFetching, refetch } = useShow(id);
  const {
    data: bandShows,
    isFetching: isFetchingBands,
    refetch: refetchBands,
  } = useBandShowsForShow(id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const showName = () => {
    if (!show || !show.name) {
      return "...";
    }
    const MAX_LENGTH = 25;
    if (show.name.length > MAX_LENGTH) {
      return show.name.substring(0, MAX_LENGTH) + "...";
    }
    return show.name;
  };
  return (
    <Container maxWidth="md" sx={{ pt: 5 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RRLink} to={`/shows/mine`}>
          My Shows
        </Link>
        <Link component={RRLink} to={`/shows/${id}`}>
          {showName()}
        </Link>
        <Typography>Edit</Typography>
      </Breadcrumbs>
      {isFetching && <Typography>Loading...</Typography>}
      {!isFetching && show && (
        <ShowForm
          isNew={false}
          show={show}
          bandShows={bandShows}
          isFetchingBands={isFetchingBands}
          refetchBands={refetchBands}
        />
      )}
    </Container>
  );
};
