export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      profilePictureUrl
      createdAt
      updatedAt
    }
  }
`;

export const createUserPreferences = /* GraphQL */ `
  mutation CreateUserPreferences(
    $input: CreateUserPreferencesInput!
    $condition: ModelUserPreferencesConditionInput
  ) {
    createUserPreferences(input: $input, condition: $condition) {
      id
      userId
      wantsEmailNotifications
      wantsBandRequestEmailNotif
      wantsBandFriendEmailNotif
      wantsUserFollowEmailNotif
      createdAt
      updatedAt
    }
  }
`;

export const updateUserPreferences = /* GraphQL */ `
  mutation UpdateUserPreferences(
    $input: UpdateUserPreferencesInput!
    $condition: ModelUserPreferencesConditionInput
  ) {
    updateUserPreferences(input: $input, condition: $condition) {
      id
      userId
      wantsEmailNotifications
      wantsBandRequestEmailNotif
      wantsBandFriendEmailNotif
      wantsUserFollowEmailNotif
      createdAt
      updatedAt
    }
  }
`;

export const createFriendshipRequest = /* GraphQL */ `
  mutation CreateFriendshipRequest(
    $input: CreateFriendshipRequestInput!
    $condition: ModelFriendshipRequestConditionInput
  ) {
    createFriendshipRequest(input: $input, condition: $condition) {
      id
      senderId
      receiverId
      sender {
        id
        firstName
        lastName
        username
        profilePictureUrl
        profileBannerUrl
        createdAt
        updatedAt
      }
      receiver {
        id
        firstName
        lastName
        username
        profilePictureUrl
        profileBannerUrl
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const updateFriendshipRequest = /* GraphQL */ `
  mutation UpdateFriendshipRequest(
    $input: UpdateFriendshipRequestInput!
    $condition: ModelFriendshipRequestConditionInput
  ) {
    updateFriendshipRequest(input: $input, condition: $condition) {
      id
      senderId
      receiverId
      status
      createdAt
      updatedAt
    }
  }
`;

export const deleteFriendshipRequest = /* GraphQL */ `
  mutation DeleteFriendshipRequest(
    $input: DeleteFriendshipRequestInput!
    $condition: ModelFriendshipRequestConditionInput
  ) {
    deleteFriendshipRequest(input: $input, condition: $condition) {
      id
      senderId
      receiverId
      status
      createdAt
      updatedAt
    }
  }
`;

export const removeFriendship = /* GraphQL */ `
  mutation RemoveFriendship($friendId: String!) {
    removeFriendship(friendId: $friendId)
  }
`;
