import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import {
  SyntheticEvent,
  useCallback,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  BandShow,
  BandShowStatus,
  Show,
  ShowOpeningStatus,
  Venue,
} from "src/API";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  addOrUpdateVenue,
  createShow,
  removeBandShow,
  updateShow,
} from "./backend";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { useQueryClient } from "react-query";

import { getImageUrl } from "src/utils/images";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { FSVenue, searchVenues } from "src/utils/foursquare";
import TabPanel from "src/components/TabPanel";
import PhotoCropper from "src/components/PhotoCropper";
import BandSearchDialog from "./components/BandSearchDialog";
import { Check, Close, Warning } from "@mui/icons-material";
import NoBandPicture from "src/components/NoBandPicture";
import { useTimedMessage } from "src/utils/timedMessage";

type ShowFormState = {
  name: string;
  description: string;
  date: Date;
  venueName: string;
  venueAddress: string;
  venueCity: string;
  venueState: string;
  venueZipcode: string;
  showPictureUrl: string;
  openingStatus?: ShowOpeningStatus;
};

type SET_NAME = {
  type: "SET_NAME";
  payload: string;
};

type SET_DESCRIPTION = {
  type: "SET_DESCRIPTION";
  payload: string;
};

type SET_DATE = {
  type: "SET_DATE";
  payload: Date;
};

type SET_VENUE_NAME = {
  type: "SET_VENUE_NAME";
  payload: string;
};

type SET_VENUE_ADDRESS = {
  type: "SET_VENUE_ADDRESS";
  payload: string;
};

type SET_VENUE_CITY = {
  type: "SET_VENUE_CITY";
  payload: string;
};

type SET_VENUE_STATE = {
  type: "SET_VENUE_STATE";
  payload: string;
};

type SET_VENUE_ZIPCODE = {
  type: "SET_VENUE_ZIPCODE";
  payload: string;
};

type SET_SHOW_PICTURE_URL = {
  type: "SET_SHOW_PICTURE_URL";
  payload: string;
};

type SET_SHOW_OPENING_STATUS = {
  type: "SET_SHOW_OPENING_STATUS";
  payload: ShowOpeningStatus;
};

type ShowFormAction =
  | SET_NAME
  | SET_DESCRIPTION
  | SET_DATE
  | SET_VENUE_NAME
  | SET_VENUE_ADDRESS
  | SET_VENUE_CITY
  | SET_VENUE_STATE
  | SET_VENUE_ZIPCODE
  | SET_SHOW_PICTURE_URL
  | SET_SHOW_OPENING_STATUS;

const showReducer = (state: ShowFormState, action: ShowFormAction) => {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "SET_DATE":
      return { ...state, date: action.payload };
    case "SET_VENUE_NAME":
      return { ...state, venueName: action.payload };
    case "SET_VENUE_ADDRESS":
      return { ...state, venueAddress: action.payload };
    case "SET_VENUE_CITY":
      return { ...state, venueCity: action.payload };
    case "SET_VENUE_STATE":
      return { ...state, venueState: action.payload };
    case "SET_VENUE_ZIPCODE":
      return { ...state, venueZipcode: action.payload };
    case "SET_SHOW_PICTURE_URL":
      return { ...state, showPictureUrl: action.payload };
    case "SET_SHOW_OPENING_STATUS":
      return { ...state, openingStatus: action.payload };
    default:
      return state;
  }
};

type ShowFormProps = {
  isNew: boolean;
  show?: Show;
  bandShows?: BandShow[] | null;
  isFetchingBands?: boolean;
  refetchBands?: () => void;
};

export const ShowForm = ({
  isNew,
  show,
  bandShows,
  isFetchingBands,
  refetchBands,
}: ShowFormProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");
  const [tabValue, setTabValue] = useState(
    selectedTab ? parseInt(selectedTab) : 0,
  );

  const {
    message: successMessage,
    setMessage: setSuccessMessage,
    clearMessage: clearSuccessMessage,
  } = useTimedMessage(5000);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [error, setError] = useState<string | null>(null);
  const [state, dispatch] = useReducer(showReducer, {
    name: show?.name || "",
    description: show?.description || "",
    date: show?.date ? new Date(show.date) : new Date(),
    venueName: show?.venueName || "",
    venueAddress: show?.venueAddress || "",
    venueCity: show?.venueCity || "",
    venueState: show?.venueState || "",
    venueZipcode: show?.venueZipcode || "",
    showPictureUrl: show?.showPictureUrl || "",
    openingStatus: show?.openingStatus || ShowOpeningStatus.HAS_NONE,
  });

  const { id: userId } = useSelector((state: RootState) => state.user);
  const [isChangingPicture, setIsChangingPicture] = useState<boolean>(false);
  const [isAddingBand, setIsAddingBand] = useState<boolean>(false);
  const [removingBand, setRemovingBand] = useState<BandShow | null>(null);

  const [latLong, setLatLong] = useState<string | null>(null);
  const [isLoadingVenue, setIsLoadingVenue] = useState<boolean>(false);
  const [showPictureUrlValue, setShowPictureUrlValue] = useState<string | null>(
    show?.showPictureUrl || null,
  );

  const [venueResult, setVenueResult] = useState<FSVenue[] | null>(null);
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(
    show?.venue || null,
  );

  const {
    value: placeValue,
    suggestions: { loading: placesLoading, data: placeSuggestions },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 750,
  });

  const searchLocation = useRef((val: string) => {
    setValue(val);
  }).current;

  const searchFSVenue = useRef(
    debounce(async (val: string, ll: string) => {
      const result = await searchVenues(val, ll);
      setVenueResult(result);
    }, 333),
  ).current;

  const navigate = useNavigate();
  const client = useQueryClient();

  const handleChange =
    (type: "SET_NAME" | "SET_DESCRIPTION") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type, payload: event.target.value });
    };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue) {
      dispatch({ type: "SET_DATE", payload: newValue });
    }
  };

  const handleSelect = useCallback(
    ({ description }: { description: string }) =>
      async () => {
        setValue(description, false);
        setVenueResult(null);
        clearSuggestions();
        try {
          const results = await getGeocode({ address: description });
          if (results.length > 0) {
            const { lat, lng } = await getLatLng(results[0]);

            setLatLong(`${lat},${lng}`);
          } else {
            setError("No locations found. Please try again.");
          }
        } catch (error) {
          setError(`Error getting location ${error}`);
        }
      },
    [clearSuggestions, setValue],
  );

  const handleSelectVenue = async (fsq_id: string) => {
    try {
      setIsLoadingVenue(true);
      const venue = await addOrUpdateVenue(fsq_id);
      if (venue) {
        setSelectedVenue(venue);
        setVenueResult(null);
      }
    } catch (error) {
      console.log("[ERROR] error getting venue", error);
      setError("Error getting venue");
    } finally {
      setIsLoadingVenue(false);
    }
  };

  const handleSaveShow = async () => {
    try {
      let newShow;
      if (!userId) {
        return;
      }
      clearSuccessMessage();
      const dateTimeStamp = state.date.getTime();
      const _geoloc = selectedVenue ? selectedVenue?._geoloc : undefined;
      if (isNew) {
        newShow = await createShow({
          name: state.name,
          description: state.description,
          date: state.date.toISOString(),
          dateTimeStamp,
          creatorId: userId,
          venueId: selectedVenue?.id,
          showPictureUrl: state.showPictureUrl || undefined,
          openingStatus: state.openingStatus,
          _geoloc,
        });
      } else {
        newShow = await updateShow({
          id: show?.id,
          name: state.name,
          description: state.description,
          date: state.date.toISOString(),
          dateTimeStamp,
          creatorId: userId,
          venueId: selectedVenue?.id,
          showPictureUrl: state.showPictureUrl || undefined,
          openingStatus: state.openingStatus,
          _geoloc,
        });
      }
      if (newShow) {
        client.invalidateQueries(["shows", newShow.id]);
        if (isNew) {
          navigate(`/shows/${newShow.id}/edit`);
        } else {
          setSuccessMessage("Show saved successfully!");
        }
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error saving show", e);
      setError(e.message);
    }
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue.toString() });
  };

  const handleAddNewBand = async () => {
    setIsAddingBand(true);
  };

  const handleSearchChange = (value: string) => {
    searchLocation(value);
  };

  const handleVenueNameSearch = async (value: string) => {
    if (value.length > 2 && latLong) {
      searchFSVenue(value, latLong);
    }
  };

  const handleRemoveBandShow = async (bandShow: BandShow) => {
    setRemovingBand(bandShow);
  };
  const handleConfirmRemoveBandShow = async (bandShow: BandShow | null) => {
    try {
      if (!bandShow) {
        return null;
      }
      const result = await removeBandShow(bandShow);
      if (result) {
        client.invalidateQueries(["show", bandShow.showId, "bands"]);
        if (refetchBands) {
          refetchBands();
          setRemovingBand(null);
        }
      }
    } catch (error) {
      console.log("[ERROR] error removing band show", error);
      setError(`Error removing band.`);
    }
  };

  const handleUploadImage = (url: string) => {
    const imageUrl = getImageUrl(url);
    dispatch({ type: "SET_SHOW_PICTURE_URL", payload: imageUrl });
    setShowPictureUrlValue(imageUrl);
    setIsChangingPicture(false);
  };

  const handleCheckHasOpening = (val: boolean) => {
    if (val) {
      dispatch({
        type: "SET_SHOW_OPENING_STATUS",
        payload: ShowOpeningStatus.HAS_ONE,
      });
    } else {
      dispatch({
        type: "SET_SHOW_OPENING_STATUS",
        payload: ShowOpeningStatus.HAS_NONE,
      });
    }
  };

  const renderPlacesSuggestions = () => {
    if (placesLoading) {
      return (
        <Box
          sx={{
            position: "absolute",
            zIndex: 99999,
            top: "100%",
            backgroundColor: "#fff",
            border: "1px #ccc solid",
            width: "100%",
            padding: 2,
            borderRadius: "4px",
          }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!placeSuggestions || placeSuggestions.length === 0) {
      return null;
    }
    const filtered = placeSuggestions.filter((suggestion) => {
      const { types } = suggestion;

      return types.includes("locality") || types.includes("postal_code");
    });
    return (
      <Box
        sx={{
          position: "absolute",
          zIndex: 99999,
          top: "100%",
          backgroundColor: "white",
          borderRadius: "2px 2px 5px 5px",
          boxShadow: "0 0 10px rgba(0,0,0,0.5)",
          p: 1,
          width: "100%",
          maxWidth: "400px",
        }}>
        {filtered.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <Box
              sx={{
                cursor: "pointer",
                p: 1,
                borderBottom: "1px solid #ccc",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
              key={place_id}
              className="list-group-item pointer google-location"
              onClick={handleSelect(suggestion)}>
              <Typography>
                <strong>{main_text}</strong> <small>{secondary_text}</small>
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderFSVenueResults = () => {
    if (!venueResult) {
      return null;
    }
    if (isLoadingVenue) {
      return (
        <Card>
          <CardContent>
            <Typography>Locating venue in our records...</Typography>
          </CardContent>
        </Card>
      );
    }

    if (venueResult.length === 0) {
      return (
        <Card>
          <CardContent>
            <Typography>No results found</Typography>
          </CardContent>
        </Card>
      );
    }
    return (
      <Box
        sx={{
          backgroundColor: "white",
          position: "absolute",
          border: "1px #eee solid",
          left: 0,
          px: 1,
          zIndex: 9999,
        }}>
        {venueResult.map((fsvr) => {
          return (
            <Card
              sx={{
                width: "100%",
                mb: 1,
                cursor: "pointer",
                "&:hover": { backgroundColor: "#f5f5f5" },
              }}
              key={`fsvr-${fsvr.fsq_id}`}
              onClick={() => handleSelectVenue(fsvr.fsq_id)}>
              <CardContent>
                <Typography variant="body1">{fsvr.name}</Typography>
                <Typography variant="body2">
                  {fsvr.location.formatted_address}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    );
  };

  const renderVenueOrSearch = () => {
    if (selectedVenue) {
      return (
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Box>
            <Typography variant="h6">{selectedVenue.name}</Typography>
            <Typography variant="body2">
              {selectedVenue.address}, {selectedVenue.city},{" "}
              {selectedVenue.state}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="error"
            size="small"
            sx={{ ml: 2 }}
            onClick={() => setSelectedVenue(null)}>
            Change Venue
          </Button>
        </Box>
      );
    }
    return (
      <>
        <Box sx={{ position: "relative", mb: 3 }}>
          <TextField
            sx={{
              width: "100%",
              backgroundColor: "#fff",
            }}
            type="text"
            value={placeValue}
            onChange={(e) => handleSearchChange(e.target.value)}
            id="location-input"
            label="City or postal code"
            variant="outlined"
          />
          {renderPlacesSuggestions()}
        </Box>
        {latLong && (
          <Box sx={{ position: "relative", mb: 3 }}>
            <TextField
              type="text"
              fullWidth
              label="search venue by name"
              onChange={(e) => handleVenueNameSearch(e.target.value)}
            />
            {renderFSVenueResults()}
          </Box>
        )}
      </>
    );
  };

  const renderBands = () => {
    if (isFetchingBands) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!bandShows) {
      return null;
    }

    return (
      <Grid container spacing={1} sx={{ justifyContent: "center" }}>
        {bandShows.map((bandShow, idx) => {
          const { band, status } = bandShow;
          if (!band) {
            return null;
          }
          return (
            <Grid item xs={12} sm={6} md={4} key={`band-${idx}`}>
              <Card
                sx={{
                  position: "relative",
                  opacity: status === BandShowStatus.CANCELLED ? "0.75" : 1,
                }}>
                {band.profilePictureUrl && (
                  <CardMedia
                    component="img"
                    height={200}
                    src={band.profilePictureUrl}
                    alt={band.name}
                  />
                )}
                {!band.profilePictureUrl && (
                  <CardMedia sx={{ display: "flex", justifyContent: "center" }}>
                    <NoBandPicture size={200} iconSize="5x" />
                  </CardMedia>
                )}
                <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {status === BandShowStatus.PENDING && (
                      <Chip variant="filled" label="Pending" color="warning" />
                    )}
                    {status === BandShowStatus.REJECTED && (
                      <Chip
                        variant="filled"
                        icon={<Warning />}
                        label="Rejected"
                        color="error"
                      />
                    )}
                    {status === BandShowStatus.ACCEPTED && (
                      <Chip
                        variant="outlined"
                        icon={<Check />}
                        label="Confirmed"
                        color="secondary"
                      />
                    )}
                    {status === BandShowStatus.CANCELLED && (
                      <Chip
                        variant="outlined"
                        icon={<Close />}
                        label="Removed"
                        color="error"
                      />
                    )}
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ ml: 1 }}>
                        <Typography variant="h6">{band.name}</Typography>
                        <Typography variant="body2">
                          {band.city}, {band.state}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {status !== BandShowStatus.CANCELLED && (
                    <Button
                      color="error"
                      onClick={() => handleRemoveBandShow(bandShow)}>
                      Remove Band
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Container maxWidth="md" sx={{ pt: 5 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant={isMobile ? "fullWidth" : undefined}
        sx={{
          mb: 3,
        }}>
        <Tab label="General" />
        <Tab label="Venue" />
        <Tab label="Bands" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <TextField
          label="Show name"
          sx={{ mb: 2 }}
          value={state.name}
          onChange={handleChange("SET_NAME")}
          fullWidth
        />
        <TextField
          label="Tell about this show"
          multiline
          fullWidth
          value={state.description}
          rows={4}
          sx={{ mb: 2 }}
          onChange={handleChange("SET_DESCRIPTION")}
        />
        <Box>
          <DateTimePicker
            label="Show date &amp; time"
            renderInput={(params) => <TextField sx={{ mb: 2 }} {...params} />}
            value={state.date}
            onChange={handleDateChange}
          />
        </Box>
        {show && (
          <Box sx={{ mt: 1, mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleCheckHasOpening(e.target.checked)}
                  defaultChecked={
                    show.openingStatus === ShowOpeningStatus.HAS_ONE
                  }
                />
              }
              label="Are there opening spots for this show?"
            />
            <Box>
              <Typography variant="body2">
                (Can other bands request to open for you?)
              </Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ border: "1px #eee solid", py: 3, px: 1 }}>
          <Typography variant="h6">Show image</Typography>
          {showPictureUrlValue && (
            <Box>
              <img
                src={showPictureUrlValue}
                alt="show placeholder"
                style={{ maxWidth: 150, maxHeight: 300 }}
              />
              <Button onClick={() => setIsChangingPicture(true)}>Change</Button>
            </Box>
          )}
          {!showPictureUrlValue && (
            <Typography>No show image selected</Typography>
          )}
          {(!showPictureUrlValue || isChangingPicture) && (
            <PhotoCropper
              onCancel={() => null}
              showPreviewSquare={true}
              onComplete={handleUploadImage}
              containerSx={{ width: { xs: "100%", md: "550px" } }}
              imageSx={{ width: { xs: "100%", md: "400px" } }}
              keyFolder="show-images"
            />
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box sx={{ py: 5 }}>{renderVenueOrSearch()}</Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: show ? "flex-end" : "center",
            mb: 2,
          }}>
          {show && (
            <Button variant="contained" onClick={handleAddNewBand}>
              Add New Band
            </Button>
          )}
          {!show && (
            <Typography>
              You must save the show before you can add bands
            </Typography>
          )}
        </Box>
        {renderBands()}
      </TabPanel>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Button variant="contained" onClick={handleSaveShow}>
          Save
        </Button>
        <Button onClick={() => navigate("/shows/mine")}>Cancel</Button>
      </Box>
      <BandSearchDialog
        onRefresh={() => {
          if (refetchBands) {
            refetchBands();
          }
        }}
        onClose={() => {
          if (refetchBands) {
            refetchBands();
          }
          setIsAddingBand(false);
        }}
        isOpen={isAddingBand}
        showId={show?.id || ""}
      />
      <Dialog
        open={removingBand !== null}
        onClose={() => setRemovingBand(null)}>
        <DialogTitle>Remove Band</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this band from the show?
          </Typography>
          <Typography fontWeight="bold">
            You won't be able to invite them again.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => handleConfirmRemoveBandShow(removingBand)}>
            Remove
          </Button>
          <Button onClick={() => setRemovingBand(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
