export const showsByVenueAndDate = /* GraphQL */ `
  query ShowsByVenueAndDate(
    $venueId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByVenueAndDate(
      venueId: $venueId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        date
        dateTimeStamp
        venueName
        venueAddress
        venueCity
        venueState
        venueZipcode
        showPictureUrl
        imGoing
        goingCount
        openingStatus
        creatorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      address
      city
      createdAt
      icon
      foursquareId
      latitude
      longitude
      name
      state
      profileImageUrl
      status
      updatedAt
      isBlocked
      _geoloc {
        lat
        lng
      }
    }
  }
`;

export const searchVenues = /* GraphQL */ `
  query SearchVenues(
    $query: String
    $filters: String
    $searchableAttrs: [String]
    $typoTolerance: Boolean
  ) {
    searchVenues(
      query: $query
      filters: $filters
      searchableAttrs: $searchableAttrs
      typoTolerance: $typoTolerance
    ) {
      items {
        id
        address
        city
        createdAt
        icon
        foursquareId
        latitude
        longitude
        profileImageUrl
        name
        state
        status
        updatedAt
        isBlocked
        _geoloc {
          lat
          lng
        }
      }
      nextToken
      total
    }
  }
`;
