import { TouchEvent } from "react";

export const useSwipeListener = ({
  onRight,
  onLeft,
  onUp,
  onDown,
  sensitivity = 5,
}: {
  onRight?: () => void;
  onLeft?: () => void;
  onUp?: () => void;
  onDown?: () => void;
  sensitivity?: number;
}) => {
  let xDown: number | null = null;
  let yDown: number | null = null;

  function getTouches(evt: TouchEvent<unknown>) {
    return evt.touches;
  }

  function handleTouchStart(evt: TouchEvent<unknown>) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt: TouchEvent<unknown>) {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > sensitivity) {
        if (onRight) {
          onRight();
        }
      } else if (xDiff < -sensitivity) {
        if (onLeft) {
          onLeft();
        }
      }
    } else {
      if (yDiff > sensitivity) {
        if (onDown) {
          onDown();
        }
      } else if (yDiff < -sensitivity) {
        if (onUp) {
          onUp();
        }
      }
    }
    xDown = null;
    yDown = null;
  }

  return {
    handleTouchStart,
    handleTouchMove,
  };
};
