export const bandPostTimelinesByUserAndStatusAndTime = /* GraphQL */ `
  query BandPostTimelinesByUserAndStatusAndTime(
    $userId: ID!
    $statusCreatedAt: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandPostTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandPostTimelinesByUserAndStatusAndTime(
      userId: $userId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bandPostId
        bandId
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          createdAt
          updatedAt
        }
        bandPost {
          id
          postImageUrl
          postImageUrls
          content
          bandId
          isCheered
          numCheers
          creatorId
          createdAt
          updatedAt
        }
        createdAt
        status
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandPostCheersByUserAndBandPost = /* GraphQL */ `
  query BandPostCheersByUserAndBandPost(
    $userId: ID!
    $bandPostId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandPostCheerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandPostCheersByUserAndBandPost(
      userId: $userId
      bandPostId: $bandPostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bandPostId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const searchUsers = /* GraphQL */ `
  query SearchUsers($query: String) {
    searchUsers(query: $query) {
      items {
        id
        firstName
        lastName
        username
        profilePictureUrl
        profileBannerUrl
      }
      nextToken
      total
    }
  }
`;
