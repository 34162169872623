import { Groups, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Header } from "src/components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { searchBands } from "./backend";
import { Band } from "src/API";
import { debounce } from "lodash";

export const Bands = () => {
  const [searchResult, setSearchResult] = useState<Band[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = searchParams.get("q");
  const [search, setSearch] = useState<string>(searchQuery || "");
  const [initialSearchDone, setInitialSearchDone] = useState(false);

  const { isAuthed } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const debouncedSearch = useRef(
    debounce(
      async (query: string) => {
        try {
          setIsSearching(true);
          setSearchParams({ q: query });
          const result = await searchBands(query);
          setSearchResult(result);
        } catch (e) {
          console.log("[ERROR] error searching for band", e);
        } finally {
          setIsSearching(false);
        }
      },
      500,
      { leading: true },
    ),
  ).current;

  const onClickSearch = async () => {
    await debouncedSearch(search);
  };

  useEffect(() => {
    if (!initialSearchDone && searchQuery && searchQuery.length > 2) {
      debouncedSearch(searchQuery);
      setInitialSearchDone(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSearchDone]);
  return (
    <div>
      <Header
        title="Bands"
        right={
          isAuthed ? (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<Groups />}
              onClick={() => navigate("mine")}>
              My Bands
            </Button>
          ) : null
        }
      />
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            my: 3,
            mx: "auto",
            alignItems: "center",
          }}
          maxWidth="md">
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onClickSearch();
              return false;
            }}>
            <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
              <InputLabel htmlFor="band-search-box">Band Search</InputLabel>
              <OutlinedInput
                id="band-search-box"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                endAdornment={
                  <Button variant="contained" onClick={onClickSearch}>
                    <Search />
                  </Button>
                }
                label="band search button"
              />
            </FormControl>
          </form>
        </Box>
        <Grid container spacing={1}>
          {searchResult.map((band) => (
            <Grid item xs={12} sm={6} md={4} key={`band-${band.id}`}>
              <Card
                onClick={() => navigate(`/bands/${band.id}`)}
                sx={{
                  cursor: "pointer",
                  border: "2px transparent solid",
                  transition: "all 0.111s ease-in-out",
                  "&:hover": {
                    border: "2px solid rgba(0,0,0,.25)",
                    backgroundColor: "#fafafa",
                  },
                }}>
                {band.profilePictureUrl && (
                  <CardMedia
                    component="img"
                    src={band.profilePictureUrl}
                    height={200}
                  />
                )}
                <CardContent>
                  <Box>
                    <Typography variant="h6">{band.name}</Typography>
                  </Box>
                  {band.city && band.state && (
                    <Typography>
                      {band.city}, {band.state}{" "}
                      {band.zipcode ? `(${band.zipcode})` : ""}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Dialog open={isSearching}>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <CircularProgress />
            <Typography fontWeight="bold" sx={{ mt: 1 }}>
              Searching...
            </Typography>
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
};
