import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Venue } from "src/API";
import { Header } from "src/components/Header";
import VenuePicture from "src/components/VenuePicture";
import { useSearch } from "src/utils/search";
import { searchVenues } from "./backend";

export const Venues = () => {
  const [search, setSearch] = useState("");

  const [venues, setVenues] = useState<Venue[] | null>(null);
  const navigate = useNavigate();

  const debouncedSearch = useRef(
    debounce(
      async (query: string) => {
        try {
          const venues = await searchVenues(query);
          setVenues(venues);
        } catch (e) {
          console.log("[ERROR] error searching for venues", e);
        }
      },
      500,
      { leading: true },
    ),
  ).current;

  const { isSearching, error, search: searchFn } = useSearch(debouncedSearch);

  const onClickSearch = () => {
    searchFn(search);
  };

  const renderVenues = () => {
    if (isSearching) {
      return <CircularProgress />;
    }
    if (!venues) {
      return null;
    }
    if (venues.length === 0) {
      return <Typography>No Venues Found</Typography>;
    }
    if (error) {
      return <Typography>{error}</Typography>;
    }
    return (
      <Grid container spacing={3}>
        {venues.map((venue) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={`venue-${venue.id}`}>
              <Card>
                <CardActionArea onClick={() => navigate(`/venues/${venue.id}`)}>
                  <CardContent>
                    <VenuePicture venue={venue} />
                    <Typography>{venue.name}</Typography>
                    <Typography>
                      {venue.address}, {venue.city}, {venue.state}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  return (
    <div>
      <Header title="Venues" />
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            my: 3,
            mx: "auto",
            alignItems: "center",
          }}
          maxWidth="md">
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onClickSearch();
              return false;
            }}>
            <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
              <InputLabel htmlFor="venue-search-box">Venue Search</InputLabel>
              <OutlinedInput
                id="venue-search-box"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                endAdornment={
                  <Button variant="contained" onClick={onClickSearch}>
                    <Search />
                  </Button>
                }
                label="venue search button"
              />
            </FormControl>
          </form>
        </Box>
        {renderVenues()}
      </Container>
    </div>
  );
};
