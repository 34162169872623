import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Band } from "src/API";
import { selectRandom } from "src/utils/random";
import { getBandsPublic } from "../Bands/backend";

const bgCSS = {
  borderBottom: "1px solid #eee",
};

const useHighlightedBands = () => {
  return useQuery(
    ["band", "public"],
    async () => {
      const res = await getBandsPublic();
      const random = res ? selectRandom(res, 5) : [];
      return random;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const UnAuthedHome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const { data: bands } = useHighlightedBands();

  const renderBandCard = (band: Band) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={`band-card-${band.id}`}>
        <Card sx={{ minWidth: 275 }}>
          <CardActionArea onClick={() => navigate(`/bands/${band.id}`)}>
            {band.profilePictureUrl && (
              <CardMedia
                component="img"
                height="194"
                image={band.profilePictureUrl}
              />
            )}
            <CardContent>
              <Typography fontWeight="bold">{band.name}</Typography>
              <Typography>
                {band.city}, {band.state}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };
  return (
    <Grid container spacing={2} mb={10}>
      <Grid item xs={12} pb={3} sx={bgCSS}>
        <Typography
          variant={isMobile ? "h5" : "h3"}
          sx={{
            textAlign: { xs: "center", md: "left" },
            fontWeight: { xs: "bold", sm: "inherit" },
            mb: { xs: 2, sm: 0 },
          }}>
          Connecting bands and fans
        </Typography>
        <Typography variant="subtitle1">
          ToTheMusic connects bands with other bands and lets fans get the
          latest on their favorite bands.
        </Typography>
        <Typography variant="h6" sx={{ mt: 3 }}>
          How it works:
        </Typography>
        <ul>
          <li>
            <Typography>
              Create a user account to create or join your band
            </Typography>
          </li>
          <li>
            <Typography>Bands connect to other bands to trade shows</Typography>
          </li>
          <li>
            <Typography>Follow bands for the latest updates</Typography>
          </li>
          <li>
            <Typography>
              See what shows your friends are going to and join them
            </Typography>
          </li>
        </ul>
        <Typography variant="h6">
          Sign up now to create your band and start trading shows!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ my: 3 }}>
          Popular Bands
        </Typography>
        <Grid container spacing={2}>
          {bands?.map((band) => renderBandCard(band))}
        </Grid>
      </Grid>
    </Grid>
  );
};
