export const respondToBandMemberRequest = /* GraphQL */ `
  mutation RespondToBandMemberRequest(
    $requestId: String!
    $response: BandMemberRequestResponse!
  ) {
    respondToBandMemberRequest(requestId: $requestId, response: $response)
  }
`;

export const updateBandShow = /* GraphQL */ `
  mutation UpdateBandShow(
    $input: UpdateBandShowInput!
    $condition: ModelBandShowConditionInput
  ) {
    updateBandShow(input: $input, condition: $condition) {
      id
      date
      bandId
      showId
      status
      createdAt
      updatedAt
    }
  }
`;
