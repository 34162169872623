import {
  Container,
  Link,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Badge,
} from "@mui/material";
import { isBefore } from "date-fns";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactLink } from "react-router-dom";
import { BandPostTimeline } from "src/API";
import { BandPostCard } from "src/components/BandPostCard";
import { Header } from "src/components/Header";
import { RootState } from "src/stores";
import { addCheers } from "src/stores/CheersStore";
import {
  getMyBandRequests,
  getMyBandShowRequests,
  getMyPendingFriendRequests,
} from "../Notifications/backend";
import { getMyTimelines } from "./backend";

import { UnAuthedHome } from "./UnAuthedHome";

const useMyTimelines = (userId?: string | null) => {
  return useQuery(
    ["myTimelines", userId],
    async () => {
      if (!userId) {
        return null;
      }
      const timelines = await getMyTimelines(userId);
      const sorted = timelines.sort((a, b) => {
        return isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1;
      });
      return sorted;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useMyBandRequests = (userId?: string | null) => {
  return useQuery(
    ["myBandRequests", userId],
    async () => {
      if (!userId) {
        return null;
      }
      const bandRequests = await getMyBandRequests(userId);
      return bandRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandShowRequestsForUser = (id?: string | null) => {
  return useQuery(
    ["user", id, "bandShowRequests"],
    async () => {
      if (!id) {
        return null;
      }
      const bandShowRequests = await getMyBandShowRequests(id);
      return bandShowRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const usePendingFriendshipRequests = (id?: string | null) => {
  return useQuery(
    ["user", id, "pendingFriendshipRequests"],
    async () => {
      if (!id) {
        return null;
      }
      const pendingFriendRequests = await getMyPendingFriendRequests(id);
      return pendingFriendRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const Home = () => {
  const { isAuthed } = useSelector((state: RootState) => state.auth);
  const { firstName, id: userId } = useSelector(
    (state: RootState) => state.user,
  );

  const dispatch = useDispatch();

  const { data: bandRequests } = useMyBandRequests(userId);
  const { data: bandShowRequests } = useBandShowRequestsForUser(userId);
  const { data: pendingFriendRequests } = usePendingFriendshipRequests(userId);

  const { data: timelines, isLoading: isLoadingTimelines } =
    useMyTimelines(userId);

  const getNotificationsCount = () => {
    const pending = bandShowRequests?.reduce((acc, cur) => {
      const amount = cur?.band?.pendingBandShows?.items.length || 0;
      return acc + amount;
    }, 0);
    const bandReqCount = bandRequests?.length || 0;
    const pendingCount = pending || 0;
    const pendingFriendCount = pendingFriendRequests?.length || 0;
    return pendingCount + bandReqCount + pendingFriendCount;
  };

  useEffect(() => {
    if (timelines) {
      const posts = timelines.map((timeline) => timeline.bandPost);
      dispatch(addCheers(posts));
    }
  }, [dispatch, timelines]);

  const renderBandPosts = () => {
    if (!timelines) {
      return null;
    }
    if (isLoadingTimelines) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Box>
        {timelines &&
          timelines.map((timeline: BandPostTimeline) => {
            const { bandPost, band } = timeline;
            if (!bandPost || !band) {
              return null;
            }
            return (
              <Box key={timeline.id}>
                <BandPostCard
                  band={band}
                  post={bandPost}
                  timeline={timeline}
                  canHide
                />
              </Box>
            );
          })}
      </Box>
    );
  };

  const renderHome = () => {
    if (isAuthed) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2} sx={{ borderRight: "1px #f0f0f0 solid" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: "space-evenly",
              }}>
              <Link component={ReactLink} to="/bands/mine">
                <Typography>My Bands</Typography>
              </Link>
              <Link component={ReactLink} to="/shows/mine">
                <Typography>My Shows</Typography>
              </Link>
              <Link component={ReactLink} to="/notifications">
                <Badge
                  badgeContent={getNotificationsCount()}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  color="error">
                  <Typography>
                    Notifications
                    {bandRequests && bandRequests.length > 0 && (
                      <>&nbsp;&nbsp;</>
                    )}
                  </Typography>
                </Badge>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={10}>
            {renderBandPosts()}
          </Grid>
        </Grid>
      );
    }
    return <UnAuthedHome />;
  };
  return (
    <div>
      <Header
        title={`Welcome ${isAuthed ? `${firstName}!` : "to ToTheMusic!"}`}
      />
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}></Box>
        {renderHome()}
      </Container>
    </div>
  );
};
