import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhotoCropper from "src/components/PhotoCropper";
import { RootState } from "src/stores";
import { getImageUrl } from "src/utils/images";
import { isValidUsername } from "src/utils/username";
import { updateUser, searchUsersByUsername } from "./backend";
import { debounce } from "lodash";
import { setUser } from "src/stores/UserStore";
import NoUserPicture from "src/components/NoUserPicture";

export const EditProfile = () => {
  const {
    id: userId,
    firstName,
    lastName,
    username,
    profilePictureUrl,
  } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);
  const [isChangingProfileImage, setIsChangingProfileImage] = useState(false);
  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const [isUsernameChecking, setIsUsernameChecking] = useState(false);
  const [hasChangedUsername, setHasChangedUsername] = useState(false);

  const [firstNameValue, setFirstNameValue] = useState(firstName);
  const [lastNameValue, setLastNameValue] = useState(lastName);
  const [usernameValue, setUsernameValue] = useState(username);
  const [profilePictureUrlValue, setProfilePictureUrlValue] =
    useState(profilePictureUrl);

  const handlePhotoCropped = (croppedImage: string) => {
    setProfilePictureUrlValue(getImageUrl(croppedImage));
  };

  const searchUsername = useRef(
    debounce(async (val: string) => {
      if (!userId) {
        return;
      }
      setIsUsernameChecking(true);
      try {
        const searched = await searchUsersByUsername(val);

        if (searched) {
          const isUnique = searched.every(
            (user) =>
              user.username.toLowerCase() !== val.toLowerCase() ||
              user.id === userId,
          );

          if (isUnique) {
            setIsUsernameUnique(true);
            setIsUsernameInvalid(false);
          } else {
            setIsUsernameUnique(false);
          }
        }
      } catch (e) {
        console.error("[ERROR] error searching by username", e);
      }
      setIsUsernameChecking(false);
    }, 500),
  ).current;

  const handleUsernameChange = (val: string) => {
    setUsernameValue(val);
    setIsUsernameChecking(true);
    setHasChangedUsername(true);

    if (val.length < 2 || !isValidUsername(val)) {
      setIsUsernameInvalid(true);
      setIsUsernameChecking(false);
    } else {
      searchUsername(val);
    }
  };

  const handleSave = async () => {
    try {
      if (userId && !isUsernameInvalid && !isUsernameChecking) {
        const updatedUser = await updateUser({
          id: userId,
          firstName: firstNameValue,
          lastName: lastNameValue,
          username: usernameValue,
          profilePictureUrl: profilePictureUrlValue || undefined,
        });
        if (updatedUser) {
          setIsSaved(true);
          setTimeout(() => {
            setIsSaved(false);
            dispatch(
              setUser({
                id: userId,
                firstName: firstNameValue,
                lastName: lastNameValue,
                username: usernameValue,
                isNewUser: false,
                profilePictureUrl: profilePictureUrlValue,
              }),
            );
          }, 5000);
        }
      } else {
        console.log("[ERROR] no user ID found");
      }
    } catch (error) {
      console.error("[ERROR] error updating user", error);
    }
  };

  const renderUsernameResults = () => {
    if (isUsernameChecking) {
      return <CircularProgress />;
    }
    if (isUsernameInvalid) {
      return <Alert severity="error">Invalid username</Alert>;
    }
    if (!isUsernameUnique) {
      return <Alert severity="error">Username is already in use</Alert>;
    }
    if (hasChangedUsername && isUsernameUnique && !isUsernameInvalid) {
      return <Alert severity="success">Username is valid</Alert>;
    }
  };
  const renderProfilePicture = () => {
    if (profilePictureUrlValue) {
      return (
        <>
          <img
            src={profilePictureUrlValue}
            alt="users profile"
            style={{ width: 200, height: "auto" }}
          />
        </>
      );
    }
    return <NoUserPicture size={200} iconSize="5x" />;
  };
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      {isSaved && (
        <Alert severity="success" sx={{ mb: 3 }}>
          Saved!
        </Alert>
      )}
      <Box>
        <Box sx={{ my: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}>
            <Box sx={{ position: "relative" }}>
              {renderProfilePicture()}
              <Button
                sx={{
                  position: "absolute",
                  top: 0,
                  right: -46,
                  opacity: 0.75,
                  "&:hover": { opacity: 1 },
                }}
                variant="contained"
                color="secondary"
                onClick={() => setIsChangingProfileImage(true)}>
                Change
              </Button>
            </Box>
          </Box>
          {isChangingProfileImage && (
            <Box
              sx={{
                border: "1px #f0f0f0 solid",
                p: 1,
                display: "flex",
                mt: 1,
                flexDirection: { xs: "column", sm: "row" },
              }}>
              <Typography variant="body1">
                Change profile photo: &nbsp;
              </Typography>
              <PhotoCropper
                onComplete={handlePhotoCropped}
                showPreviewSquare={true}
                onCancel={() => setIsChangingProfileImage(false)}
                aspect={1}
                containerSx={{ width: { xs: "100%", md: "550px" } }}
                imageSx={{ width: { xs: "100%", md: "400px" } }}
                keyFolder="user-profile"
              />
              <Button
                variant="contained"
                sx={{ mt: { xs: 2, sm: 0 } }}
                onClick={() => setIsChangingProfileImage(false)}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
        <Box
          sx={{ flexDirection: { xs: "column", md: "row" }, display: "flex" }}>
          <TextField
            label="First Name"
            value={firstNameValue}
            sx={{ mr: { xs: 0, md: 1 } }}
            onChange={(e) => setFirstNameValue(e.target.value)}
          />
          <TextField
            label="Last Name"
            value={lastNameValue}
            sx={{ ml: { xs: 0, md: 1 }, my: { xs: 2, md: 0 } }}
            onChange={(e) => setLastNameValue(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 3, mb: 1 }}>
          <TextField
            label="Username"
            value={usernameValue}
            helperText="Valid characters are: a-z A-Z 0-9 . -"
            onChange={(e) => handleUsernameChange(e.target.value)}
          />
          <Box>{renderUsernameResults()}</Box>
        </Box>

        <Button
          variant="contained"
          sx={{ mt: 1 }}
          onClick={handleSave}
          disabled={
            !isUsernameUnique || isUsernameInvalid || isUsernameChecking
          }>
          Save
        </Button>
      </Box>
    </Container>
  );
};
