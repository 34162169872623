import { Auth } from "aws-amplify";

export const refreshSession = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    await new Promise<void>((resolve, reject) => {
      cognitoUser.refreshSession(
        // @ts-ignore
        currentSession.refreshToken,
        // @ts-ignore
        (err, data) => {
          if (err) {
            reject(err);
          }
          resolve();
        },
      );
    });
  } catch (e) {
    console.log("Unable to refresh Token", e);
  }
};

export const cognitoGroups = async (): Promise<string[] | undefined> => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const groups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    return groups;
  } catch (e) {
    console.log("Unable to get cognito groups", e);
  }
};
