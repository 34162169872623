import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { BandShow } from "src/API";
import { getTimeDisplay } from "src/utils/time";
import { getPendingBandShows } from "./backend";

const useBandShows = (bandId?: string | null) => {
  return useQuery(
    ["band", bandId, "pending-shows"],
    async () => {
      if (!bandId) {
        return null;
      }
      const bandShows = await getPendingBandShows(bandId);
      return bandShows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const ShowInvites = () => {
  const { id } = useParams<{ id: string }>();

  const { data: bandShows, isFetching } = useBandShows(id);

  const renderBandShows = () => {
    if (isFetching) {
      return <CircularProgress />;
    }
    if (!bandShows) {
      return null;
    }
    return (
      <Grid container spacing={1}>
        {bandShows.map((bandShow: BandShow) => {
          const { band, show } = bandShow;
          if (!band || !show) {
            return null;
          }
          return (
            <Grid item xs={12} sm={6} md={4} key={`bandshow-${bandShow.id}`}>
              <Card>
                {show.showPictureUrl && (
                  <CardMedia
                    component="img"
                    src={show.showPictureUrl}
                    height={200}
                  />
                )}
                <CardContent>
                  <Typography fontWeight="bold">
                    You've been invited to {show?.name}
                  </Typography>
                  {show.date && (
                    <Typography variant="body2">
                      On {getTimeDisplay(show.date)}
                    </Typography>
                  )}
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="contained" color="secondary">
                    Accept
                  </Button>
                  <Button variant="outlined" color="error">
                    Decline
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  return (
    <Container sx={{ mt: 3 }} maxWidth="lg">
      {renderBandShows()}
    </Container>
  );
};
