export const updateBandPostTimeline = /* GraphQL */ `
  mutation UpdateBandPostTimeline(
    $input: UpdateBandPostTimelineInput!
    $condition: ModelBandPostTimelineConditionInput
  ) {
    updateBandPostTimeline(input: $input, condition: $condition) {
      id
      userId
      bandId
      bandPostId
      status
      updatedAt
    }
  }
`;

export const createBandPostCheer = /* GraphQL */ `
  mutation CreateBandPostCheer(
    $input: CreateBandPostCheerInput!
    $condition: ModelBandPostCheerConditionInput
  ) {
    createBandPostCheer(input: $input, condition: $condition) {
      id
      bandPostId
      userId
      createdAt
      updatedAt
    }
  }
`;

export const deleteBandPostCheer = /* GraphQL */ `
  mutation DeleteBandPostCheer(
    $input: DeleteBandPostCheerInput!
    $condition: ModelBandPostCheerConditionInput
  ) {
    deleteBandPostCheer(input: $input, condition: $condition) {
      id
      bandPostId
      userId
      createdAt
      updatedAt
    }
  }
`;
