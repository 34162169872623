import { Tune } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PhotoCropper from "src/components/PhotoCropper";
import ShowCard from "src/components/ShowCard";
import VenuePicture from "src/components/VenuePicture";
import { RootState } from "src/stores";
import { getImageUrl } from "src/utils/images";
import { useTimedMessage } from "src/utils/timedMessage";
import { getShowsForVenue, getVenueById, updateVenue } from "./backend";

const useVenue = (id?: string | null) => {
  return useQuery(
    ["venue", id],
    async () => {
      if (!id) {
        throw new Error("No venue id");
      }

      const venue = await getVenueById(id);

      return venue;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useVenueShows = (id?: string | null) => {
  return useQuery(
    ["venue", id, "shows"],
    async () => {
      if (!id) {
        throw new Error("No venue id");
      }

      const shows = await getShowsForVenue(id);

      return shows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const VenuePage = () => {
  const { id } = useParams<{ id?: string }>();
  // const { id: userId } = useSelector((state: RootState) => state.user);

  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const [isAdminActionsOpen, setIsAdminActionsOpen] = useState(false);
  const { message: error, setMessage: setError } = useTimedMessage();
  const { message: success, setMessage: setSuccess } = useTimedMessage();
  const [newVenueName, setNewVenueName] = useState("");
  const [newVenueAddress, setNewVenueAddress] = useState("");
  const {
    data: venue,
    isFetching: isFetchingVenue,
    refetch: refetchVenue,
  } = useVenue(id);
  const [newVenueStatus, setNewVenueStatus] = useState<
    string | null | undefined
  >(venue?.status);
  const { data: shows, isFetching: isFetchingShows } = useVenueShows(id);

  const [newCroppedImage, setNewCroppedImage] = useState<string | null>(null);

  const handleUploadVenueImage = (croppedImageUrl: string) => {
    const imageUrl = getImageUrl(croppedImageUrl);
    setNewCroppedImage(imageUrl);
  };

  const handleSaveVenue = async () => {
    try {
      if (!isAdmin || !id) {
        return;
      }
      const venue = await updateVenue({
        id,
        profileImageUrl: newCroppedImage ? newCroppedImage : undefined,
        name: newVenueName.length > 2 ? newVenueName : undefined,
        address: newVenueAddress.length > 2 ? newVenueAddress : undefined,
        status: newVenueStatus || undefined,
      });
      if (venue) {
        refetchVenue();
        setSuccess("Venue updated");
      }
    } catch (e) {
      console.log("[ERROR] Error updating venue", e);
      setError("Error updating venue");
    }
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setNewVenueStatus(event.target.value as string);
  };

  const renderVenue = () => {
    if (isFetchingVenue) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h4">Loading...</Typography>
        </Box>
      );
    }
    if (!venue) {
      return null;
    }
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <VenuePicture venue={venue} size={150} />
        <Typography variant="h4">{venue.name}</Typography>
        <Typography variant="body1">
          {venue.address}, {venue.city}, {venue.state}
        </Typography>
      </Box>
    );
  };
  const renderVenueShows = () => {
    if (!venue || isFetchingVenue) {
      return null;
    }
    if (isFetchingShows) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!shows || venue.status === "CLOSED") {
      return null;
    }
    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Shows
        </Typography>
        <Grid container spacing={3}>
          {shows.map((show) => (
            <Grid item xs={12} sm={6} md={4} key={show.id}>
              <ShowCard show={show} />
            </Grid>
          ))}
          {shows.length === 0 && <Typography variant="h6">No shows</Typography>}
        </Grid>
      </Box>
    );
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      {venue?.status === "CLOSED" && (
        <Alert severity="error" sx={{ mb: 2 }}>
          This venue is closed
        </Alert>
      )}
      {renderVenue()}
      {renderVenueShows()}

      {isAdmin && (
        <>
          {!isAdminActionsOpen && (
            <Box
              sx={{
                "& > :not(style)": {
                  m: 1,
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                },
              }}>
              <Fab
                color="primary"
                variant="extended"
                aria-label="add"
                onClick={() => setIsAdminActionsOpen(true)}>
                <Tune />
                Admin
              </Fab>
            </Box>
          )}
          <Dialog open={isAdminActionsOpen}>
            <DialogTitle>Admin Options</DialogTitle>
            <DialogContent>
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity="success">{success}</Alert>}
              <Typography>Change Venue Image</Typography>
              {newCroppedImage && (
                <img
                  src={newCroppedImage}
                  alt={`new profile for ${venue?.name}`}
                  style={{ height: 50, width: 50, borderRadius: 50 }}
                />
              )}
              <PhotoCropper
                onCancel={() => null}
                onComplete={handleUploadVenueImage}
                showPreviewSquare={true}
                showPreviewCircle={true}
                aspect={1}
                containerSx={{ width: { xs: "100%", md: "550px" } }}
                imageSx={{ width: { xs: "100%", md: "400px" } }}
                keyFolder="venue-image"
              />
              <TextField
                placeholder="Venue Name"
                fullWidth
                onChange={(e) => setNewVenueName(e.target.value)}
                defaultValue={venue?.name}
                sx={{ mt: 1 }}
              />
              <TextField
                placeholder="Address"
                fullWidth
                onChange={(e) => setNewVenueAddress(e.target.value)}
                defaultValue={venue?.address}
                sx={{ mt: 1 }}
              />
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="venue-status">Venue Status</InputLabel>
                <Select
                  labelId="venue-status"
                  label="Venue Status"
                  value={newVenueStatus || "UNVERIFIED"}
                  onChange={handleChangeStatus}>
                  <MenuItem value="UNVERIFIED">Unverified</MenuItem>
                  <MenuItem value="VERIFIED">Verified</MenuItem>
                  <MenuItem value="CLOSED">Closed</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => handleSaveVenue()}>
                Save
              </Button>
              <Button onClick={() => setIsAdminActionsOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};
