import { useState } from "react";

export const useTimedMessage = (timeout: number = 5000) => {
  const [message, setTimedMessageMsg] = useState<string | null>(null);

  let _timeout: NodeJS.Timeout | null = null;

  const clearMessage = () => {
    setTimedMessageMsg(null);
    if (_timeout) {
      clearTimeout(_timeout);
      _timeout = null;
    }
  };

  const setMessage = (msg: string | null) => {
    setTimedMessageMsg(msg);
    if (_timeout) {
      clearTimeout(_timeout);
    }
    if (msg) {
      _timeout = setTimeout(clearMessage, timeout);
    }
  };

  return { message, setMessage, clearMessage };
};
