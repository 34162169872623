import { Route, Routes } from "react-router-dom";
import {
  MyBands,
  Bands,
  NewBand,
  EditBand,
  BandPage,
  BandMembers,
  BandMemberForm,
  EditSongs,
  BandPost,
  BandShows,
  BandMap,
} from "src/containers/Bands";
import {
  NewShow,
  EditShow,
  MyShows,
  Shows,
  ShowPage,
} from "src/containers/Shows";
import { Home } from "src/containers/Home";
import { EditProfile, Profile, Settings } from "src/containers/Profile";
import { Venues, VenuePage } from "src/containers/Venues";
import { ReactNode } from "react";
import { Notifications, ShowInvites } from "src/containers/Notifications";
import { Search } from "src/containers/Search";

type RouteProps = {
  isAuthOnly?: boolean;
};

const AllRoutes = ({ isAuthOnly }: RouteProps) => {
  const authedOnly = (route: ReactNode) => {
    if (isAuthOnly) {
      return route;
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/search" element={<Search />} />
      <Route path="bands">
        <Route index element={<Bands />} />
        {authedOnly(<Route path="mine" element={<MyBands />} />)}
        {authedOnly(<Route path="new" element={<NewBand />} />)}
        {authedOnly(<Route path=":id/edit" element={<EditBand />} />)}
        {authedOnly(<Route path=":id/members" element={<BandMembers />} />)}
        {authedOnly(<Route path=":id/songs" element={<EditSongs />} />)}
        {authedOnly(<Route path=":id/posts" element={<BandPost />} />)}
        {authedOnly(<Route path=":id/map" element={<BandMap />} />)}
        {authedOnly(
          <Route path=":id/members/add" element={<BandMemberForm />} />,
        )}
        <Route path=":id/shows" element={<BandShows />} />
        <Route path=":id" element={<BandPage />} />
      </Route>
      <Route path="venues">
        <Route index element={<Venues />} />
        <Route path=":id" element={<VenuePage />} />
      </Route>
      <Route path="shows">
        <Route index element={<Shows />} />
        {authedOnly(<Route path="mine" element={<MyShows />} />)}
        {authedOnly(<Route path="new" element={<NewShow />} />)}
        {authedOnly(<Route path=":id/edit" element={<EditShow />} />)}
        <Route path=":id" element={<ShowPage />} />
      </Route>
      {authedOnly(
        <Route path="profile">
          <Route index element={<Profile />} />
          <Route path="edit" element={<EditProfile />} />
        </Route>,
      )}
      {authedOnly(<Route path="settings" element={<Settings />} />)}
      {authedOnly(<Route path="notifications" element={<Notifications />} />)}
      {authedOnly(<Route path="/invites/:id" element={<ShowInvites />} />)}
      <Route path="users/:id" element={<Profile />} />
      <Route path="b/:slug" element={<BandPage />} />
      <Route path="u/:slug" element={<Profile />} />
    </Routes>
  );
};
export default AllRoutes;
