import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LikeState = {
  [key: string]: boolean;
};

const initialState: LikeState = {};

export const likeSlice = createSlice({
  name: "like",
  initialState,
  reducers: {
    resetLikes: (state) => {
      state = {};
    },
    addLike: (state, action: PayloadAction<{ key: string }>) => {
      state[action.payload.key] = true;
    },
    removeLike: (state, action: PayloadAction<{ key: string }>) => {
      state[action.payload.key] = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetLikes, addLike, removeLike } = likeSlice.actions;

export default likeSlice.reducer;
