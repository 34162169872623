export const bandMemberRequestsByUserAndStatus = /* GraphQL */ `
  query BandMemberRequestsByUserAndStatus(
    $userId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandMemberRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandMemberRequestsByUserAndStatus(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bandId
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
        }
      }
      nextToken
    }
  }
`;

export const bandShowRequestsByUser = /* GraphQL */ `
  query BandShowRequestsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandMembersByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        band {
          id
          name
          hasPendingBandShow
          profilePictureUrl
          profileBannerUrl
          pendingBandShows {
            items {
              id
              show {
                id
                name
                date
                showPictureUrl
              }
              band {
                id
                name
                profilePictureUrl
                profileBannerUrl
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const bandShowsByBandAndStatus = /* GraphQL */ `
  query BandShowsByBandAndStatus(
    $bandId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandShowsByBandAndStatus(
      bandId: $bandId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        bandId
        showId
        status
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          slug
        }
        show {
          id
          name
          description
          date
          venueName
          venueAddress
          venueCity
          venueState
          venueZipcode
          showPictureUrl
          venueId
        }
      }
      nextToken
    }
  }
`;

export const friendshipRequestsByReceiverAndStatus = /* GraphQL */ `
  query FriendshipRequestsByReceiverAndStatus(
    $receiverId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendshipRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    friendshipRequestsByReceiverAndStatus(
      receiverId: $receiverId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        receiverId
        sender {
          id
          firstName
          lastName
          username
          profilePictureUrl
          profileBannerUrl
          isFriend
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
