import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import * as queries from "./queries";
import * as mutations from "./mutations";
import {
  BandShow,
  BandShowStatus,
  GPS,
  Show,
  ShowGoing,
  ShowOpeningStatus,
  Venue,
} from "src/API";
import { apiGraphqlOperationWithAuth } from "src/utils/query";
import { Auth } from "aws-amplify";

export const addOrUpdateVenue = async (foursquareId: string) => {
  const result = (await API.graphql(
    graphqlOperation(queries.addFSVenue, {
      foursquareId,
    }),
  )) as GraphQLResult<{ addFSVenue: Venue }>;
  if (result.errors) {
    throw result.errors;
  }
  return result.data?.addFSVenue;
};

type ShowProps = {
  id?: string;
  name: string;
  description: string;
  date: string;
  dateTimeStamp: number;
  creatorId: string;
  venueId?: string;
  showPictureUrl?: string;
  openingStatus?: ShowOpeningStatus;
  _geoloc?: GPS | null;
};

export const createShow = async (
  input: ShowProps,
): Promise<Show | undefined> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createShow, { input }),
  )) as GraphQLResult<{ createShow: Show }>;

  if (result.errors) {
    throw result.errors;
  }
  return result.data?.createShow;
};

export const updateShow = async (
  input: ShowProps,
): Promise<Show | undefined> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateShow, { input }),
  )) as GraphQLResult<{ updateShow: Show }>;

  if (result.errors) {
    throw result.errors;
  }
  return result.data?.updateShow;
};

export const getShow = async (id: string): Promise<Show | undefined> => {
  const result = (await apiGraphqlOperationWithAuth(queries.getShow, {
    id,
  })) as GraphQLResult<{ getShow: Show }>;

  if (result.errors) {
    throw result.errors;
  }
  return result.data?.getShow;
};

export const getMyShows = async (id: string): Promise<Show[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.showsByCreator, { creatorId: id }),
  )) as GraphQLResult<{
    showsByCreator: { items: Show[]; nextToken?: string };
  }>;

  return result.data?.showsByCreator.items || [];
};

export const searchShows = async (query: string): Promise<Show[]> => {
  const now = new Date().getTime();
  const isAuthed = await Auth.currentAuthenticatedUser();
  let result;
  if (isAuthed) {
    result = (await API.graphql(
      graphqlOperation(queries.searchShows, {
        query,
        filters: `dateTimeStamp > ${now}`,
      }),
    )) as GraphQLResult<{
      searchShows: { items: Show[]; nextToken?: string };
    }>;
  } else {
    result = (await apiGraphqlOperationWithAuth(queries.searchShows, {
      query,
      filters: `dateTimeStamp > ${now}`,
    })) as GraphQLResult<{
      searchShows: { items: Show[]; nextToken?: string };
    }>;
  }

  return result.data?.searchShows.items || [];
};

export const searchShowsByLocation = async (ll: string): Promise<Show[]> => {
  const now = new Date().getTime();
  let isAuthed = false;
  try {
    isAuthed = Boolean(await Auth.currentAuthenticatedUser());
  } catch (e) {
    isAuthed = false;
  }
  let result;
  if (isAuthed) {
    result = (await API.graphql(
      graphqlOperation(queries.searchShowsByLocation, {
        query: " ",
        filters: `dateTimeStamp > ${now}`,
        aroundLatLng: ll,
      }),
    )) as GraphQLResult<{
      searchShows: { items: Show[]; nextToken?: string };
    }>;
  } else {
    result = (await apiGraphqlOperationWithAuth(queries.searchShowsByLocation, {
      query: " ",
      filters: `dateTimeStamp > ${now}`,
      aroundLatLng: ll,
    })) as GraphQLResult<{
      searchShows: { items: Show[]; nextToken?: string };
    }>;
  }

  return result.data?.searchShows.items || [];
};

export const getBandShowsForShow = async (id: string): Promise<BandShow[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandShowsByShow, { showId: id }),
  )) as GraphQLResult<{ bandShowsByShow: { items: BandShow[] } }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting bands for show",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.bandShowsByShow?.items) {
    const bandShows = result.data.bandShowsByShow.items;
    return bandShows;
  }
  throw new Error("[ERROR] error getting bands for show");
};

export const getAcceptedBandShowsForShow = async (
  id: string,
): Promise<BandShow[]> => {
  const result = (await API.graphql({
    query: queries.bandShowsByShowAndStatus,
    variables: {
      showId: id,
      status: { eq: BandShowStatus.ACCEPTED },
    },
    authMode: "API_KEY",
  })) as GraphQLResult<{ bandShowsByShowAndStatus: { items: BandShow[] } }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting accepted bands for show",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.bandShowsByShowAndStatus?.items) {
    const bandShows = result.data.bandShowsByShowAndStatus.items;
    return bandShows;
  }
  throw new Error("[ERROR] error getting accepted bands for show");
};

type InviteBandShowResponse = {
  success: boolean;
  message: string;
};

export const inviteBand = async ({
  showId,
  bandId,
}: {
  showId: string;
  bandId: string;
}): Promise<InviteBandShowResponse> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.inviteBandShow, {
      showId,
      bandId,
    }),
  )) as GraphQLResult<{ inviteBandShow: InviteBandShowResponse }>;

  if (result.errors) {
    console.log(
      "[ERROR] error inviting band to show",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.inviteBandShow) {
    return result.data?.inviteBandShow;
  }
  throw new Error("[ERROR] error inviting band to show");
};

export const removeBandShow = async (bandShow: BandShow): Promise<BandShow> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.removeBandShow, { bandShowId: bandShow.id }),
  )) as GraphQLResult<{ removeBandShow: string }>;

  if (result.errors) {
    console.log(
      "[ERROR] error removing band show",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.removeBandShow) {
    return JSON.parse(result.data?.removeBandShow) as BandShow;
  }
  throw new Error("[ERROR] error removing band show");
};

export const getShowGoingByUserAndShow = async ({
  showId,
  userId,
}: {
  showId: string;
  userId: string;
}): Promise<ShowGoing | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.showGoingsByUserAndShow, {
      userId,
      showId: { eq: showId },
    }),
  )) as GraphQLResult<{ showGoingsByUserAndShow: { items: ShowGoing[] } }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting show going by user and show",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.showGoingsByUserAndShow.items) {
    return result.data?.showGoingsByUserAndShow.items[0] || null;
  }
  return null;
};
