import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

type LocationSearchProps = {
  onLatLongSelected: ({
    lat,
    lng,
    city,
    state,
    formatted_address,
  }: {
    lat: number;
    lng: number;
    city: string;
    state: string;
    formatted_address: string;
  }) => void;
  onLocationCancelled?: () => void;
  onError?: (error: string) => void;
  defaultValue?: string | null;
  sx?: SxProps;
  textFieldSx?: SxProps;
  label?: string;
  allowPostalCode?: boolean;
};

export const LocationSearch = ({
  onLatLongSelected,
  onError,
  onLocationCancelled,
  sx = {},
  textFieldSx = {},
  label = "Search by City",
  allowPostalCode = false,
  defaultValue,
}: LocationSearchProps) => {
  const {
    value: placeValue,
    suggestions: { loading: placesLoading, data: placeSuggestions },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 750,
  });

  const [hasSearched, setHasSearched] = useState(false);

  const searchLocation = useRef((val: string) => {
    setValue(val);
  }).current;

  const handleSelect = useCallback(
    ({
        description,
        terms,
      }: {
        description: string;
        terms?: Array<{ value: string }>;
      }) =>
      async () => {
        setValue(description, false);
        clearSuggestions();
        try {
          const results = await getGeocode({ address: description });
          if (results.length > 0 && terms && terms.length > 1) {
            const { lat, lng } = await getLatLng(results[0]);
            const { formatted_address } = results[0];
            const city = terms[0] && terms[0].value ? terms[0].value : "";
            const state = terms[1] && terms[1].value ? terms[1].value : "";

            onLatLongSelected({
              lat,
              lng,
              city,
              state,
              formatted_address,
            });
          } else if (onError) {
            onError("No locations found. Please try again.");
          }
        } catch (error) {
          console.log("[ERROR]", error);
          if (onError) {
            onError(`Error getting location ${error}`);
          }
        }
      },
    [clearSuggestions, onLatLongSelected, setValue, onError],
  );

  const handleSearchChange = (value: string) => {
    searchLocation(value);
    setHasSearched(true);
  };

  const renderPlacesSuggestions = () => {
    if (placesLoading) {
      return (
        <Box
          sx={{
            position: "absolute",
            zIndex: 99999,
            top: "100%",
            backgroundColor: "#fff",
            border: "1px #ccc solid",
            width: "100%",
            padding: 2,
            borderRadius: "4px",
          }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!placeSuggestions || placeSuggestions.length === 0) {
      return null;
    }
    const filtered = placeSuggestions.filter((suggestion) => {
      const { types } = suggestion;

      const postal = allowPostalCode && types.includes("postal_code");

      return types.includes("locality") || postal;
    });
    return (
      <Box
        sx={{
          position: "absolute",
          zIndex: 99999,
          top: "100%",
          backgroundColor: "white",
          borderRadius: "2px 2px 5px 5px",
          boxShadow: "0 0 10px rgba(0,0,0,0.5)",
          p: 1,
          width: "100%",
          maxWidth: "400px",
        }}>
        {filtered.length === 0 && <Typography>No cities found</Typography>}
        {filtered.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <Box
              sx={{
                cursor: "pointer",
                p: 1,
                borderBottom: "1px solid #ccc",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
              key={place_id}
              className="list-group-item pointer google-location"
              onClick={handleSelect(suggestion)}>
              <Typography>
                <strong>{main_text}</strong> <small>{secondary_text}</small>
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };
  return (
    <Box sx={{ ...sx, position: "relative" }}>
      <Box
        sx={{
          dislay: "flex",
        }}>
        <Box>
          <TextField
            sx={{
              display: "flex",
              flexGrow: 1,
              backgroundColor: "#fff",
              ...textFieldSx,
            }}
            type="text"
            value={hasSearched ? placeValue : defaultValue}
            onChange={(e) => handleSearchChange(e.target.value)}
            id="location-input"
            label={label || "Search by City"}
            variant="outlined"
          />
        </Box>
        {onLocationCancelled && (
          <Button
            variant="contained"
            color="error"
            size="small"
            sx={{ position: "absolute", right: 10, top: 15 }}
            onClick={onLocationCancelled}>
            Cancel
          </Button>
        )}
      </Box>
      {renderPlacesSuggestions()}
    </Box>
  );
};
