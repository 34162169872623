import { Breadcrumbs, Container, Link, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import BandForm from "./BandForm";
import { Link as RRLink } from "react-router-dom";

import { useQuery } from "react-query";
import { getBand } from "./backend";

const useBand = (id?: string | null) => {
  return useQuery(
    ["band", id],
    async () => {
      if (!id) {
        throw new Error("No band id");
      }
      const band = await getBand(id);
      return band;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const EditBand = () => {
  const { id } = useParams<{ id: string }>();

  const { data: band, isFetching } = useBand(id);
  return (
    <Container maxWidth="md" sx={{ pt: 5 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RRLink} to={`/bands/mine`}>
          My Bands
        </Link>
        <Link component={RRLink} to={`/bands/${id}`}>
          {band?.name || "..."}
        </Link>
        <Typography>Edit</Typography>
      </Breadcrumbs>
      {isFetching && <Typography>Loading...</Typography>}
      {band && <BandForm isNew={false} band={band} />}
    </Container>
  );
};
