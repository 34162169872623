import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Band, User } from "src/API";
import NoBandPicture from "src/components/NoBandPicture";
import NoUserPicture from "src/components/NoUserPicture";
import { searchBands } from "../Bands/backend";
import { searchUsers } from "../Home/backend";

import SearchIcon from "@mui/icons-material/Search";

export const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q") || "";

  const navigate = useNavigate();

  const [isSearchingUsers, setIsSearchingBands] = useState<boolean>(false);
  const [isSearchingBands, setIsSearchingUsers] = useState<boolean>(false);
  const [userResults, setUserResults] = useState<User[]>([]);
  const [bandresults, setBandResults] = useState<Band[]>([]);

  const performUserSearch = async (search: string) => {
    try {
      const users = await searchUsers(search);
      setUserResults(users);
    } catch (err) {
    } finally {
      setIsSearchingUsers(false);
    }
  };
  const performBandSearch = async (search: string) => {
    try {
      const bands = await searchBands(search);
      setBandResults(bands);
    } catch (err) {
    } finally {
      setIsSearchingBands(false);
    }
  };

  const searchRef = useRef(
    debounce((query: string) => {
      if (query.length > 2) {
        performUserSearch(query);
        performBandSearch(query);
        setSearchParams({ q: query });
      }
    }, 1000),
  ).current;

  const handleSearch = (query: string) => {
    setIsSearchingUsers(query.length > 2);
    setIsSearchingBands(query.length > 2);
    searchRef(query);
  };

  useEffect(() => {
    if (query.length > 2) {
      handleSearch(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const renderUserProfilePicture = (user: User) => {
    if (user.profilePictureUrl) {
      return (
        <Avatar
          src={user.profilePictureUrl}
          sx={{ width: 50, height: "auto" }}
        />
      );
    }
    return <NoUserPicture size={50} iconSize="xl" />;
  };
  const renderBandProfilePicture = (band: Band) => {
    if (band.profilePictureUrl) {
      return (
        <Avatar
          src={band.profilePictureUrl}
          sx={{ width: 50, height: "auto" }}
        />
      );
    }
    return <NoBandPicture size={50} iconSize="xl" />;
  };
  const renderResult = () => {
    if (isSearchingUsers || isSearchingBands) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress size={50} color="secondary" />
        </Box>
      );
    }
    return (
      <>
        {((userResults && userResults.length > 0) ||
          bandresults.length > 0) && (
          <Card sx={{ mt: 1 }}>
            <CardContent>
              {userResults.map((user) => (
                <Box
                  onClick={() => {
                    navigate(`/users/${user.id}`);
                  }}
                  key={`user-${user.id}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#fafafa",
                    },
                  }}>
                  {renderUserProfilePicture(user)}
                  <Box>
                    <Typography sx={{ ml: 1 }}>
                      {user.firstName}, {user.lastName}
                    </Typography>
                    <Typography sx={{ ml: 1 }}>({user.username})</Typography>
                  </Box>
                  <Divider />
                </Box>
              ))}
              {bandresults.map((band) => (
                <Box
                  onClick={() => {
                    navigate(`/bands/${band.id}`);
                  }}
                  key={`band-${band.id}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#fafafa",
                    },
                  }}>
                  {renderBandProfilePicture(band)}
                  <Box>
                    <Typography sx={{ ml: 1 }}>{band.name}</Typography>
                    <Typography
                      sx={{ ml: 1 }}
                      variant="body2"
                      fontWeight="bold">
                      Band from{" "}
                      {[band.city, band.state]
                        .filter((b) => Boolean(b))
                        .join(", ")}
                      {!band.city && !band.state && "Unknown"}
                    </Typography>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </CardContent>
          </Card>
        )}
      </>
    );
  };
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <TextField
        placeholder="Search ToTheMusic"
        type="search"
        autoCapitalize="none"
        autoCorrect="off"
        defaultValue={query}
        onChange={(e) => handleSearch(e.target.value)}
        InputProps={{
          startAdornment: <SearchIcon sx={{ mr: 1 }} />,
        }}
        sx={{
          borderRadius: 10,
          display: "flex",
          backgroundColor: "#fff",
          flex: 1,
          width: "100%",
          border: "#ccc 1px solid",
          "& *": {
            border: "none",
          },
        }}
      />
      {renderResult()}
    </Container>
  );
};
