import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandsClapping, faHands } from "@fortawesome/free-solid-svg-icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Band, BandPost, BandPostTimeline } from "src/API";
import { ago } from "src/utils/time";
import LightBox from "./LightBox";
import NoBandPicture from "./NoBandPicture";
import { useDispatch, useSelector } from "react-redux";
import { addLike, removeLike } from "src/stores/LikeStore";
import { setWantsAuth } from "src/stores/AuthStore";
import { RootState } from "src/stores";
import {
  cheerPost,
  hidePostTimeline,
  unHidePostTimeline,
  flagPostTimeline,
  unCheerPost,
} from "src/containers/Home/backend";
import {
  Close,
  Flag,
  MoreHoriz,
  Undo,
  VisibilityOff,
} from "@mui/icons-material";
import { setCheerCount } from "src/stores/CheersStore";

const MAX_POST_LENGTH = 300;

export const BandPostCard = ({
  post,
  band,
  timeline,
  handleDeleteClicked,
  canHide,
}: {
  band: Band;
  post: BandPost;
  timeline?: BandPostTimeline;
  handleDeleteClicked?: (post: BandPost) => void;
  canHide?: boolean;
}) => {
  const { id: userId } = useSelector((state: RootState) => state.user);
  const cheerCount: Record<string, number> = useSelector(
    (state: RootState) => state.cheers,
  );
  const navigate = useNavigate();
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isFlagged, setIsFlagged] = useState<boolean>(false);
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const [isActing, setIsActing] = useState<boolean>(false);

  const likes = useSelector((state: RootState) => state.likes);

  const dispatch = useDispatch();

  const [isViewingMenu, setIsViewingMenu] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const POST_PICTURES_TO_SHOW = 3;

  const [isViewingMedia, setIsViewingMedia] = useState(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [activePicture, setActivePicture] = useState<number>(0);
  const needsMore =
    post && post.content && post?.content?.length > MAX_POST_LENGTH;

  const isLiked =
    likes[post.id] !== undefined ? likes[post.id] : post.isCheered;

  const handleLikeClicked = async () => {
    const _cheerCount = cheerCount[post.id] || post.numCheers || 0;
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      if (!post) {
        return;
      }
      dispatch(
        addLike({
          key: post.id,
        }),
      );
      dispatch(
        setCheerCount({
          id: post.id,
          count: _cheerCount + 1,
        }),
      );
      const result = await cheerPost({
        bandPostId: post.id,
        userId,
      });

      if (!result) {
        dispatch(
          setCheerCount({
            id: post.id,
            count: _cheerCount - 1,
          }),
        );
        dispatch(
          removeLike({
            key: post.id,
          }),
        );
      }
    } catch (err) {
      console.log("[ERROR] error liking post", err);
    }
  };

  const handleUnlikeClicked = async () => {
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      if (!post) {
        return;
      }
      const _cheerCount = cheerCount[post.id] || post.numCheers || 0;
      dispatch(
        setCheerCount({
          id: post.id,
          count: Math.max(0, _cheerCount - 1),
        }),
      );
      dispatch(
        removeLike({
          key: post.id,
        }),
      );
      const result = await unCheerPost({
        bandPostId: post.id,
        userId,
      });

      if (!result) {
        dispatch(
          setCheerCount({
            id: post.id,
            count: Math.max(0, _cheerCount),
          }),
        );
        dispatch(
          addLike({
            key: post.id,
          }),
        );
      }
    } catch (err) {
      console.log("[ERROR] error unliking post", err);
    }
  };

  const handleHidePostTimeline = async () => {
    try {
      if (!timeline) {
        return;
      }
      setIsActing(true);
      const result = await hidePostTimeline(timeline);

      if (result) {
        setIsFlagged(false);
        setIsHidden(true);
        setIsViewingMenu({});
      }
    } catch (err) {
      console.log("[ERROR] error hiding post timeline", err);
    } finally {
      setIsActing(false);
    }
  };

  const handleUndoHidePost = async () => {
    try {
      if (!timeline) {
        return;
      }
      setIsActing(true);
      const result = await unHidePostTimeline(timeline);

      if (result) {
        setIsFlagged(false);
        setIsHidden(false);
        setIsViewingMenu({});
      }
    } catch (err) {
      console.log("[ERROR] error unhiding post timeline", err);
    } finally {
      setIsActing(false);
    }
  };

  const handleFlagPostTimeline = async () => {
    try {
      if (!timeline) {
        return;
      }
      setIsActing(true);
      const result = await flagPostTimeline(timeline);

      if (result) {
        setIsHidden(false);
        setIsFlagged(true);
        setIsViewingMenu({});
      }
    } catch (err) {
      console.log("[ERROR] error flagging post timeline", err);
    } finally {
      setIsActing(false);
    }
  };

  const handleViewMoreLess =
    (isMore: boolean) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setShowMore(isMore);
    };

  const handleSelectMedia = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    idx: number = 0,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsViewingMedia(true);
    setActivePicture(idx);
  };
  const pictures = post.postImageUrls?.filter((p) => p !== null) as
    | string[]
    | null;

  if (isClosed) {
    return null;
  }
  if (isActing) {
    return (
      <Box sx={{ mb: 1 }}>
        <Card>
          <CardContent>
            <CircularProgress />
          </CardContent>
        </Card>
      </Box>
    );
  }
  if (isHidden || isFlagged) {
    return (
      <Alert
        severity="info"
        sx={{ my: 1 }}
        action={
          <Button startIcon={<Close />} onClick={() => setIsClosed(true)}>
            Close
          </Button>
        }>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            {isHidden && (
              <AlertTitle sx={{ fontWeight: "bold" }}>Post hidden</AlertTitle>
            )}
            {isFlagged && (
              <AlertTitle sx={{ fontWeight: "bold" }}>Post flagged</AlertTitle>
            )}
          </Box>
          {isHidden && (
            <>
              <Typography>
                You will no longer see this post on your timeline.
              </Typography>
              <Typography fontWeight="bold" sx={{ mt: 2 }}>
                Please note:
              </Typography>
              <ul>
                <li>
                  If you go to the band's page you will still see this post
                </li>
                <li>
                  If you think this post violates ToTheMusic policies, please
                  flag it. <Flag />
                </li>
              </ul>
            </>
          )}
          {isFlagged && (
            <Typography>
              We will review the post and take action if the content violates
              our policies.
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
          <Button
            startIcon={<Undo />}
            variant="outlined"
            onClick={handleUndoHidePost}>
            Undo {isHidden ? "hide" : "flag"}
          </Button>
          {isHidden && (
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={handleFlagPostTimeline}
              startIcon={<Flag />}>
              Flag Post
            </Button>
          )}
        </Box>
      </Alert>
    );
  }
  const renderCheerCount = () => {
    if (!post) {
      return null;
    }
    const _cheerCount = cheerCount[post.id] || post.numCheers || 0;
    return (
      <Typography sx={{ mr: 3 }}>
        {_cheerCount} <FontAwesomeIcon icon={faHandsClapping} />
      </Typography>
    );
  };
  return (
    <Box>
      <Card sx={{ my: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}>
            <Box
              onClick={() => navigate(`/bands/${band.id}`)}
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
              }}>
              {band.profilePictureUrl && (
                <img
                  src={band.profilePictureUrl}
                  style={{ height: 50, width: 50 }}
                  alt="band profile"
                />
              )}
              {!band.profilePictureUrl && <NoBandPicture size={50} />}
              <Box sx={{ ml: 1 }}>
                <Typography fontWeight="bold">{band.name}</Typography>
                <Typography>{ago(post.createdAt)}</Typography>
              </Box>
            </Box>
            {canHide && (
              <Box>
                <IconButton
                  onClick={(e) => {
                    setIsViewingMenu({
                      [post.id]: e.currentTarget,
                    });
                  }}>
                  <MoreHoriz />
                </IconButton>
                <Menu
                  onClose={() => {
                    setIsViewingMenu({});
                  }}
                  open={Boolean(isViewingMenu[post.id])}
                  anchorEl={isViewingMenu[post.id]}>
                  <MenuItem onClick={() => handleHidePostTimeline()}>
                    <VisibilityOff />
                    <Typography sx={{ ml: 1 }}>Hide Post</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => handleFlagPostTimeline()}>
                    <Flag />
                    <Typography sx={{ ml: 1 }}>Flag Post</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
          <Typography>
            {showMore
              ? post.content
              : post.content?.substring(0, MAX_POST_LENGTH)}
            {needsMore && !showMore && (
              <Button
                onClick={handleViewMoreLess(true)}
                startIcon={<ArrowDropDownIcon />}>
                Show more
              </Button>
            )}
            {needsMore && showMore && (
              <Button
                onClick={handleViewMoreLess(false)}
                startIcon={<ArrowDropUpIcon />}>
                Show less
              </Button>
            )}
          </Typography>
          {post.postImageUrl && (
            <Box onClick={(e) => handleSelectMedia(e)}>
              <img
                src={post.postImageUrl}
                alt="post"
                style={{
                  width: "auto",
                  maxWidth: "100%",
                  maxHeight: 200,
                  objectFit: "contain",
                }}
              />
            </Box>
          )}
          {post.postImageUrls && (
            <Box>
              <Grid container spacing={2}>
                {post.postImageUrls
                  .slice(0, POST_PICTURES_TO_SHOW)
                  .map((url, idx) => {
                    if (!url) {
                      return null;
                    }
                    return (
                      <Grid
                        item
                        key={`img-${idx}`}
                        xs={6}
                        sm={3}
                        onClick={(e) => handleSelectMedia(e, idx)}>
                        <img
                          src={url}
                          alt="post"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: 150,
                            objectFit: "cover",
                          }}
                        />
                      </Grid>
                    );
                  })}
                {post.postImageUrls.length > POST_PICTURES_TO_SHOW && (
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    onClick={(e) =>
                      handleSelectMedia(e, POST_PICTURES_TO_SHOW - 1)
                    }>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        border: "1px #eee solid",
                        borderRadius: "10px",
                      }}>
                      <Button variant="contained">
                        See all {post.postImageUrls.length}
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          {renderCheerCount()}
        </CardContent>

        <CardActions sx={{ display: "flex" }}>
          {handleDeleteClicked && (
            <Button
              color="error"
              onClick={() => {
                if (handleDeleteClicked) {
                  handleDeleteClicked(post);
                }
              }}>
              Delete Post
            </Button>
          )}
          {!isLiked && (
            <Button
              onClick={handleLikeClicked}
              startIcon={
                <FontAwesomeIcon
                  style={{ transform: "rotate(45deg)" }}
                  icon={faHands}
                />
              }>
              Cheer
            </Button>
          )}
          {isLiked && (
            <Button
              onClick={handleUnlikeClicked}
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faHandsClapping} />}>
              Uncheer
            </Button>
          )}
        </CardActions>
      </Card>
      <LightBox
        activeIndex={activePicture}
        onClose={() => setIsViewingMedia(false)}
        pictures={pictures || []}
        open={isViewingMedia}
      />
    </Box>
  );
};
