import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import * as queries from "./queries";
import * as mutations from "./mutations";
import {
  BandMemberRequest,
  BandShow,
  BandShowStatus,
  FriendshipRequest,
  FriendshipRequestStatus,
} from "src/API";

export const getMyBandRequests = async (
  userId: string,
): Promise<BandMemberRequest[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandMemberRequestsByUserAndStatus, {
      userId,
      status: { eq: "PENDING" },
    }),
  )) as GraphQLResult<{
    bandMemberRequestsByUserAndStatus: { items: BandMemberRequest[] };
  }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data) {
    return result.data.bandMemberRequestsByUserAndStatus.items;
  }
  throw new Error("No data returned");
};

export const respondToBandMemberRequest = async (
  request: BandMemberRequest,
  status: "ACCEPT" | "REJECT",
) => {
  const result = (await API.graphql(
    graphqlOperation(mutations.respondToBandMemberRequest, {
      requestId: request.id,
      response: status,
    }),
  )) as GraphQLResult<{ respondToBandMemberRequest: string }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data) {
    return result.data.respondToBandMemberRequest;
  }

  throw new Error("No data returned");
};

export const getPendingBandShows = async (
  bandId: string,
): Promise<BandShow[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandShowsByBandAndStatus, {
      bandId,
      status: { eq: BandShowStatus.PENDING },
    }),
  )) as GraphQLResult<{ bandShowsByBandAndStatus: { items: BandShow[] } }>;
  if (result.errors) {
    console.log(
      "[ERROR] error getting pending band shows",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.bandShowsByBandAndStatus?.items) {
    const bandShows = result.data.bandShowsByBandAndStatus.items;
    return bandShows;
  }
  throw new Error("[ERROR] error getting pending band shows");
};

type MyBandShowRequestsResponse = {
  id: string;
  band: {
    id: string;
    name: string;
    hasPendingBandShow: boolean;
    profilePictureUrl?: string;
    profileBannerUrl?: string;
    pendingBandShows?: {
      items: BandShow[];
    };
  };
};

export const getMyBandShowRequests = async (
  userId: string,
): Promise<MyBandShowRequestsResponse[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandShowRequestsByUser, {
      userId,
    }),
  )) as GraphQLResult<{
    bandMembersByUser: { items: MyBandShowRequestsResponse[] };
  }>;
  if (result.errors) {
    console.log(
      "[ERROR] error getting band shows for user",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data) {
    return result.data.bandMembersByUser.items;
  }
  throw new Error("No data returned");
};

export const updateBandShow = async ({
  id,
  status,
}: {
  id: string;
  status: BandShowStatus;
}) => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBandShow, {
      input: {
        id,
        status,
      },
    }),
  )) as GraphQLResult<{ updateBandShow: BandShow }>;

  if (result.errors) {
    console.log(
      "[ERROR] error updating band show",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data) {
    return result.data.updateBandShow;
  }
  throw new Error("[ERROR] No data returned");
};

export const getMyPendingFriendRequests = async (
  userId: string,
): Promise<FriendshipRequest[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.friendshipRequestsByReceiverAndStatus, {
      receiverId: userId,
      status: { eq: FriendshipRequestStatus.PENDING },
    }),
  )) as GraphQLResult<{
    friendshipRequestsByReceiverAndStatus: { items: FriendshipRequest[] };
  }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting pending friend requests",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data) {
    return result.data.friendshipRequestsByReceiverAndStatus.items;
  }
  throw new Error("No data returned");
};
