import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ShowCard from "src/components/ShowCard";
import { upcomingShowsForBand } from "./backend";

const useBandShows = (id?: string | null) => {
  return useQuery(
    ["band", id, "shows"],
    async () => {
      if (!id) {
        return null;
      }

      const shows = await upcomingShowsForBand(id);

      return shows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const BandShows = () => {
  const { id: bandId } = useParams<{ id: string }>();

  const { data: bandShows, refetch: refetchBandShows } = useBandShows(bandId);

  const navigate = useNavigate();

  const renderBand = () => {
    if (!bandShows) {
      return null;
    }

    return (
      <Box>
        <Button onClick={() => refetchBandShows()}>Refresh</Button>
        <Grid container spacing={1}>
          {bandShows.map((show) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={`show-${show.id}`}>
                <ShowCard show={show} />
              </Grid>
            );
          })}
        </Grid>
        {bandShows.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Typography variant="h5">No upcoming shows</Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/shows/new")}>
              Create a show
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Container sx={{ mt: 3 }} maxWidth="lg">
      {renderBand()}
    </Container>
  );
};
