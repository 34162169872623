import { Box, CircularProgress, Container, Typography } from "@mui/material";
import BandMemberRequests from "./segments/BandMemberRequests";
import BandShowRequests from "./segments/BandShowRequests";
import FriendshipRequests from "./segments/FriendshipRequests";
import { useState } from "react";

export const Notifications = () => {
  const [stillLoading, setStillLoading] = useState<number>(3);

  const [notificationCount, setNotificationCount] = useState<number>(0);

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      {stillLoading > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
          }}>
          <CircularProgress />
        </Box>
      )}
      {stillLoading <= 0 && notificationCount === 0 && (
        <Typography variant="h4" textAlign="center">
          No notifications
        </Typography>
      )}
      <BandMemberRequests
        onDoneLoading={(notifs: number) => {
          setStillLoading((s) => s - 1);
          setNotificationCount((s) => s + notifs);
        }}
      />
      <BandShowRequests
        onDoneLoading={(notifs: number) => {
          setStillLoading((s) => s - 1);
          setNotificationCount((s) => s + notifs);
        }}
      />
      <FriendshipRequests
        onDoneLoading={(notifs: number) => {
          setStillLoading((s) => s - 1);
          setNotificationCount((s) => s + notifs);
        }}
      />
    </Container>
  );
};
