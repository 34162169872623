import {
  Box,
  Dialog,
  DialogProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactElement } from "react";

const CenterModal = ({
  children,
  open,
  onClose,
  ...props
}: DialogProps): ReactElement => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog open={open} onClose={onClose} {...props} fullScreen={fullScreen}>
      <Box
        sx={{
          p: { xs: 1, md: 2 },
          display: "flex",
          justifyContent: "center",
        }}>
        {children}
      </Box>
    </Dialog>
  );
};

export default CenterModal;
