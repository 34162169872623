import { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getBand,
  getActiveSongsForBand,
  getBandFanByUserIdAndBandId,
  createBandFan,
  deleteBandFan,
  getBandPostsByBand,
  upcomingShowsForBand,
  addShowGoing,
  removeShowGoing,
  getBandHighlights,
  createBandHighlight,
  removeBandHighlight,
} from "./backend";
import { Add, Favorite, HeartBroken, Remove, Tune } from "@mui/icons-material";

import NoBandPicture from "src/components/NoBandPicture";
import { Show, Song } from "src/API";
import { getDuration } from "src/utils/songs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/stores";
import { followerCount } from "src/utils/numbers";
import { BandPostCard } from "src/components/BandPostCard";
import { getTimeDisplay } from "src/utils/time";
import LightBox from "src/components/LightBox";
import { isBefore } from "date-fns";
import { setWantsAuth } from "src/stores/AuthStore";
import { renderVenueAddress } from "src/utils/location";

const useBand = (id?: string) => {
  return useQuery(
    ["band", id],
    async () => {
      if (!id) {
        throw new Error("No id");
      }
      const band = await getBand(id);
      return band;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandHighlights = (id?: string) => {
  return useQuery(
    ["band", id, "highlight"],
    async () => {
      if (!id) {
        return null;
      }
      const highlights = await getBandHighlights(id);
      return highlights;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandFan = (userId?: string | null, bandId?: string | null) => {
  return useQuery(
    ["bandFan", userId, bandId],
    async () => {
      if (!userId || !bandId) {
        return null;
      }
      const result = await getBandFanByUserIdAndBandId({
        userId,
        bandId,
      });
      return result;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandPosts = (bandId?: string | null) => {
  return useQuery(
    ["band", bandId, "posts"],
    async () => {
      if (!bandId) {
        return null;
      }
      const posts = await getBandPostsByBand(bandId);

      const sorted = posts.sort((a, b) => {
        return isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1;
      });
      return sorted;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandSongs = (id?: string) => {
  return useQuery(
    ["band", id, "songs"],
    async () => {
      if (!id) {
        return null;
      }
      const songs = await getActiveSongsForBand(id);

      if (songs && songs.length > 4) {
        return songs.slice(0, 4);
      }
      return songs;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useUpcomingBandShows = (id?: string | null) => {
  return useQuery(
    ["band", id, "shows"],
    async () => {
      if (!id) {
        return null;
      }
      const shows = await upcomingShowsForBand(id);
      return shows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const BandPage = () => {
  const { id } = useParams<{ id: string }>();

  const { id: userId } = useSelector((state: RootState) => state.user);
  const { isAdmin } = useSelector((state: RootState) => state.auth);

  const theme = useTheme();

  const navigate = useNavigate();

  const [activePictureIndex, setActivePictureIndex] = useState<number>(0);

  const [isAdminActionsOpen, setIsAdminActionsOpen] = useState(false);

  const {
    data: band,
    isFetching: isFetchingBand,
    isError: isBandError,
  } = useBand(id);

  const { data: songs } = useBandSongs(id);

  const { data: bandPosts } = useBandPosts(id);

  const { data: bandFan, refetch: refetchBandFan } = useBandFan(userId, id);

  const { data: bandHighlights, refetch: refetchBandHighlighted } =
    useBandHighlights(id);

  const { data: upcomingShows, isLoading: isLoadingUpcomingShows } =
    useUpcomingBandShows(id);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const dispatch = useDispatch();

  const [selectedSongIndex, setSelectedSongIndex] = useState<number>(-1);
  const [selectedSong, setSelectedSong] = useState<Song | null>(null);
  const [hasFanned, setHasFanned] = useState<boolean>(false);
  const [hasUnfanned, setHasUnfanned] = useState<boolean>(false);
  const [isShowingPictures, setIsShowingPictures] = useState<boolean>(false);
  const [isBannerLoaded, setIsBannerLoaded] = useState<boolean>(false);

  const [selectedAudio, setSelectedAudio] = useState<HTMLAudioElement | null>(
    null,
  );

  const [isSettingGoing, setIsSettingGoing] = useState<{
    [id: string]: boolean;
  }>({});

  const [isGoingDict, setIsGoingDict] = useState<{ [id: string]: boolean }>({});
  const [isFanning, setIsFanning] = useState<boolean>(false);

  const [readMoreDesc, setReadMoreDesc] = useState<boolean>(false);

  const pictures = [band?.profilePictureUrl, band?.profileBannerUrl].filter(
    (b) => Boolean(b),
  ) as string[];

  const handleFollowBand = async () => {
    if (!userId) {
      dispatch(setWantsAuth(true));
      return;
    }
    if (!band?.id) {
      return;
    }

    try {
      setIsFanning(true);
      const newBandFan = await createBandFan({
        userId,
        bandId: band.id,
      });

      if (newBandFan) {
        refetchBandFan();
        setHasFanned(true);
      }
    } catch (e) {
      console.log("[ERROR] error favoriting", e);
    } finally {
      setIsFanning(false);
    }
  };

  const handleAddBandHighlight = async () => {
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      if (!id) {
        return;
      }
      await createBandHighlight(id);
      await refetchBandHighlighted();
    } catch (e) {
      console.log("[ERROR] error adding band highlight", e);
    }
  };

  const handleRemoveBandHighlight = async () => {
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      if (!bandHighlights || bandHighlights.length === 0) {
        return;
      }
      await removeBandHighlight(bandHighlights[0].id);
      await refetchBandHighlighted();
    } catch (e) {
      console.log("[ERROR] error adding band highlight", e);
    }
  };

  const handleUnfollowBand = async () => {
    if (!userId) {
      dispatch(setWantsAuth(true));
      return;
    }
    if (!bandFan || !bandFan.isFan) {
      return;
    }
    const { bandFan: bf } = bandFan;

    if (!bf || !bf.id) {
      return;
    }

    try {
      setIsFanning(true);
      const oldBandFan = await deleteBandFan(bf.id);

      if (oldBandFan) {
        refetchBandFan();
        setHasUnfanned(true);
      }
    } catch (e) {
      console.log("[ERROR] error unfavoriting", e);
    } finally {
      setIsFanning(false);
    }
  };

  const handleSongSelect = (song: Song, index: number) => {
    setSelectedSongIndex(-1);
    setSelectedSong(null);
    setSelectedAudio(null);
    setTimeout(() => {
      setSelectedSongIndex(index);
      setSelectedSong(song);
      setSelectedAudio(null);
    }, 150);
  };

  const handleClickProfileBanner = () => {
    if (band?.profilePictureUrl) {
      setActivePictureIndex(1);
    } else {
      setActivePictureIndex(0);
    }
    setIsShowingPictures(true);
  };
  const handleClickProfilePicture = () => {
    setActivePictureIndex(0);
    setIsShowingPictures(true);
  };

  const handleGoing = async (showId: string) => {
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      setIsSettingGoing((d) => ({
        ...d,
        [showId]: true,
      }));
      const result = await addShowGoing({
        userId,
        showId,
      });

      setIsGoingDict((d) => ({
        ...d,
        [showId]: true,
      }));

      if (result) {
      }
    } catch (e) {
      console.log("[ERROR] error going", e);
    } finally {
      setIsSettingGoing((d) => ({
        ...d,
        [showId]: false,
      }));
    }
  };

  const handleNotGoing = async (showId: string) => {
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      setIsSettingGoing((d) => ({
        ...d,
        [showId]: true,
      }));
      const result = await removeShowGoing({
        userId,
        showId,
      });

      setIsGoingDict((d) => ({
        ...d,
        [showId]: false,
      }));

      if (result) {
      }
    } catch (e) {
      console.log("[ERROR] error going", e);
    } finally {
      setIsSettingGoing((d) => ({
        ...d,
        [showId]: false,
      }));
    }
  };

  useEffect(() => {
    if (selectedAudio && selectedAudio.paused) {
      selectedAudio.play();
    } else if (selectedAudio) {
      selectedAudio.pause();
    }
  }, [selectedAudio]);

  const renderGoing = (show: Show) => {
    let goingStatus = false;
    if (isGoingDict[show.id] === undefined) {
      goingStatus = Boolean(show.imGoing);
    } else {
      goingStatus = isGoingDict[show.id];
    }
    const fn = goingStatus ? handleNotGoing : handleGoing;
    return (
      <LoadingButton
        onClick={() => fn(show.id)}
        color={goingStatus ? "secondary" : "primary"}
        variant="outlined"
        loading={isSettingGoing[show.id]}
        startIcon={
          goingStatus ? <EventAvailableIcon /> : <CalendarTodayIcon />
        }>
        Going
      </LoadingButton>
    );
  };

  const renderShowVenue = (show: Show) => {
    if (!show.venue && !show.venueName) {
      return <Typography fontWeight="bold">No Venue</Typography>;
    }

    return (
      <Box mb={2}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            fontWeight="bold"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}>
            at {show.venue?.name || show.venueName}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {show.venue && (
            <Typography
              fontWeight="bold"
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}>
              {renderVenueAddress(show.venue, { skip: ["address"] })}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const renderShowCard = (show: Show) => {
    if (!band) {
      return null;
    }
    return (
      <Grid item xs={12} sm={6} md={6} key={`show-${show.id}`}>
        <Card>
          <CardActionArea onClick={() => navigate(`/shows/${show.id}`)}>
            {show.showPictureUrl && (
              <CardMedia
                component="img"
                height="194"
                image={show.showPictureUrl}
              />
            )}

            <CardContent>
              {show.date && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography>{getTimeDisplay(show.date)}</Typography>
                </Box>
              )}

              {renderShowVenue(show)}

              <Typography textAlign="center">{show.name}</Typography>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {show.goingCount !== undefined && (
                  <Chip
                    sx={{ my: 1 }}
                    label={`
                  ${followerCount(show.goingCount || 0)} ${
                      show.goingCount === 1 ? "fan" : "fans"
                    } going
                  `}
                  />
                )}
              </Box>
            </CardContent>
          </CardActionArea>
          {
            <CardActions
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-end", sm: "center" },
              }}>
              {renderGoing(show)}
            </CardActions>
          }
        </Card>
      </Grid>
    );
  };

  const renderSong = (song: Song, index: number) => {
    const active = index === selectedSongIndex;
    return (
      <Box
        onClick={() => handleSongSelect(song, index)}
        key={`song-${index}`}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          cursor: "pointer",
          border: active ? "1px solid #def" : "1px solid transparent",
          borderRadius: "2px",
          borderBottom: active ? "1px solid #def" : "1px #f0f0f0 solid",
          transition: "all 0.2s",
          "&:hover": {
            borderColor: "#eee",
            backgroundColor: "#f7f7f7",
          },
          flexShrink: 1,
          p: 2,
          mb: 1,
        }}>
        <Box
          sx={{
            display: "flex",
          }}>
          {song.songPictureUrl && (
            <Avatar src={song.songPictureUrl} sx={{ mr: 1 }} />
          )}
          {!song.songPictureUrl && <NoBandPicture size={40} />}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontWeight="bold">{song.name}</Typography>
            <Typography variant="body2">{song.albumName}</Typography>
          </Box>
        </Box>
        <Typography>{getDuration(song.durationSeconds)}</Typography>
      </Box>
    );
  };

  const renderUpcomingShows = () => {
    if (isLoadingUpcomingShows) {
      return <CircularProgress />;
    }
    if (!upcomingShows) {
      return null;
    }
    if (upcomingShows.length === 0) {
      return <Typography variant="body2">No upcoming shows</Typography>;
    }
    return (
      <Grid container spacing={1}>
        {upcomingShows.map((show) => renderShowCard(show))}
      </Grid>
    );
  };

  const renderDescription = () => {
    if (!band) {
      return null;
    }
    const length = 250;
    const { description } = band;
    if (description && description.length > length) {
      return (
        <Box>
          <Typography variant="body2">
            {!readMoreDesc && `${description.substring(0, length)}...`}
            {readMoreDesc && description}
          </Typography>
          {!readMoreDesc && (
            <Button size="small" onClick={() => setReadMoreDesc(true)}>
              Read More
            </Button>
          )}
          {readMoreDesc && (
            <Button size="small" onClick={() => setReadMoreDesc(false)}>
              Read Less
            </Button>
          )}
        </Box>
      );
    }
    return <Typography>{description}</Typography>;
  };

  const fanCount = (): number => {
    if (band) {
      const count = band.fanCount || 0;
      return count + (hasUnfanned ? -1 : 0) + (hasFanned ? 1 : 0);
    }
    return 0;
  };

  const renderBand = () => {
    if (isFetchingBand) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 1 }}>
            Loading...
          </Typography>
        </Box>
      );
    }
    if (isBandError) {
      return (
        <Alert severity="error">
          <Typography>Error getting the band.</Typography>
        </Alert>
      );
    }
    if (band) {
      const topPosition =
        band.profileBannerUrl && isBannerLoaded ? "-90px" : "0px";
      return (
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ position: "relative", top: topPosition }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "center", sm: "flex-start" },
                textAlign: { xs: "center", sm: "left" },
              }}>
              <Box
                sx={{
                  m: "0 auto",
                  mr: { xs: "auto", md: 1 },
                  ml: { xs: "auto", md: 0 },
                  width: 160,
                  height: 160,
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "2px",
                }}>
                {band.profilePictureUrl && (
                  <Box
                    component="img"
                    src={band.profilePictureUrl}
                    onClick={handleClickProfilePicture}
                    sx={{
                      width: 150,
                      height: 150,
                      cursor: "pointer",
                      position: "relative",
                      transition: "all 0.25 ease-in-out",
                      opacity: 1,
                      "&:hover": {
                        opacity: 0.9,
                      },
                    }}
                    alt="band profile"
                  />
                )}
                {!band.profilePictureUrl && (
                  <NoBandPicture size={150} iconSize="5x" />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  flex: 1,
                  mb: 2,
                }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h5"
                    sx={{ display: "flex", flexWrap: "wrap" }}>
                    {band.name.slice(0, 100)}
                  </Typography>

                  {renderDescription()}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", sm: "column" },
                    alignItems: { xs: "flex-start", sm: "stretch" },
                    mt: { xs: 2, sm: 0 },
                  }}>
                  {bandFan?.isFan && (
                    <LoadingButton
                      loading={isFanning}
                      variant="outlined"
                      startIcon={<HeartBroken />}
                      onClick={handleUnfollowBand}>
                      Unfollow
                    </LoadingButton>
                  )}
                  {bandFan && !bandFan?.isFan && (
                    <LoadingButton
                      loading={isFanning}
                      variant="contained"
                      onClick={handleFollowBand}
                      startIcon={<Favorite color="secondary" />}>
                      Follow
                    </LoadingButton>
                  )}
                </Box>
              </Box>

              <Divider sx={{ mb: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: { xs: "center", sm: "flex-start" },
                }}>
                <Chip
                  variant="outlined"
                  label={`${followerCount(fanCount())} ${
                    band.fanCount === 1 ? "follower" : "followers"
                  }`}
                  sx={{ mr: 0.5 }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ position: "relative", top: topPosition }}>
            <Card>
              <CardContent>
                {!selectedSong && band && (
                  <Box>
                    <Typography>&nbsp;</Typography>
                    <audio
                      controls
                      controlsList="nodownload"
                      style={{
                        width: "100%",
                        marginBottom: 10,
                        backgroundColor: "#f5f5f5",
                      }}></audio>
                  </Box>
                )}
                {selectedSong && (
                  <>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>
                        {selectedSong.name} - {selectedSong.albumName}
                      </Typography>
                      <audio
                        controls
                        ref={(input) => {
                          setSelectedAudio(input);
                        }}
                        style={{
                          width: "100%",
                          marginBottom: 10,
                          backgroundColor: "#f5f5f5",
                        }}>
                        <source src={selectedSong.songUrl} />
                      </audio>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    maxHeight: "250px",
                    w: "100%",
                    flexDirection: "column",
                    overflow: "auto",
                  }}>
                  {songs && songs.length === 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Typography sx={{ mr: 1 }} variant="h6">
                        No songs
                      </Typography>
                      <SentimentVeryDissatisfiedIcon />
                    </Box>
                  )}
                  {songs &&
                    songs.map((song, index) => {
                      return renderSong(song, index);
                    })}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: 2, position: "relative", top: topPosition }}>
            {bandPosts && bandPosts.length === 0 && (
              <Typography>No activity</Typography>
            )}
            {bandPosts &&
              bandPosts.map((post) => {
                if (!post || !post.band) {
                  return null;
                }
                return (
                  <BandPostCard
                    key={`post-${post.id}`}
                    post={post}
                    band={post.band}
                  />
                );
              })}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: 2, position: "relative", top: topPosition }}>
            <Typography variant="h5">Upcoming Shows</Typography>
            {renderUpcomingShows()}
          </Grid>
        </Grid>
      );
    }
  };
  return (
    <Box>
      {band && band.profileBannerUrl && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={band.profileBannerUrl}
            alt="band profile banner"
            onLoad={() => setIsBannerLoaded(true)}
            onClick={handleClickProfileBanner}
            style={{
              maxWidth: theme.breakpoints.values.lg,
              cursor: "pointer",
              width: "100%",
              borderRadius: "2px",
              height: "auto",
              margin: "0 auto",
            }}
          />
        </Box>
      )}
      <Container
        maxWidth="lg"
        sx={{ my: band && band.profileBannerUrl && isBannerLoaded ? 0 : 5 }}>
        {renderBand()}
        {isAdmin && (
          <>
            {!isAdminActionsOpen && (
              <Box
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                  },
                }}>
                <Fab
                  color="primary"
                  variant="extended"
                  aria-label="add"
                  onClick={() => setIsAdminActionsOpen(true)}>
                  <Tune />
                  Admin
                </Fab>
              </Box>
            )}
            <Dialog open={isAdminActionsOpen}>
              <DialogTitle>Admin Options</DialogTitle>
              <DialogContent>
                {(!bandHighlights || bandHighlights.length === 0) && (
                  <Card>
                    <CardContent>
                      <Typography>
                        Band is not highlighted on the home page.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={handleAddBandHighlight}>
                        Add to highlights
                      </Button>
                    </CardActions>
                  </Card>
                )}
                {bandHighlights && bandHighlights.length > 0 && (
                  <Card>
                    <CardContent>
                      <Typography>
                        Band is not highlighted on the home page.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        onClick={handleRemoveBandHighlight}
                        startIcon={<Remove />}
                        color="error">
                        Remove from Highlights
                      </Button>
                    </CardActions>
                  </Card>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsAdminActionsOpen(false)}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setSuccess(null)}
        open={Boolean(success)}
        autoHideDuration={6000}>
        <Alert
          severity="success"
          variant="filled"
          onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setError(null)}
        open={Boolean(error)}
        autoHideDuration={6000}>
        <Alert severity="error" variant="filled" onClose={() => setError(null)}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
      <LightBox
        activeIndex={activePictureIndex || 0}
        pictures={pictures}
        open={isShowingPictures}
        onClose={() => setIsShowingPictures(false)}
      />
    </Box>
  );
};
