export const createShow = /* GraphQL */ `
  mutation CreateShow(
    $input: CreateShowInput!
    $condition: ModelShowConditionInput
  ) {
    createShow(input: $input, condition: $condition) {
      id
      name
      description
      date
      creatorId
      createdAt
      updatedAt
    }
  }
`;
export const updateShow = /* GraphQL */ `
  mutation UpdateShow(
    $input: UpdateShowInput!
    $condition: ModelShowConditionInput
  ) {
    updateShow(input: $input, condition: $condition) {
      id
      name
      description
      date
      creatorId
      createdAt
      updatedAt
    }
  }
`;

export const inviteBandShow = /* GraphQL */ `
  mutation InviteBandShow($showId: String!, $bandId: String!) {
    inviteBandShow(showId: $showId, bandId: $bandId)
  }
`;

export const removeBandShow = /* GraphQL */ `
  mutation RemoveBandShow($bandShowId: String!) {
    removeBandShow(bandShowId: $bandShowId)
  }
`;
