import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Auth, Storage } from "aws-amplify";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams, Link as RRLink } from "react-router-dom";
import { Song, SongStatus } from "src/API";
import PhotoCropper from "src/components/PhotoCropper";
import SongCard from "src/components/SongCard";
import { RootState } from "src/stores";
import { getImageUrl } from "src/utils/images";
import {
  updateSong,
  createSong,
  getSongsForBand,
  updateSongStatus,
  getBand,
} from "./backend";
import LinearProgressWithLabel from "src/components/LinearProgressWithLabel";

const useSongsForBand = (id?: string | null) => {
  return useQuery(
    ["band", id, "songs"],
    async () => {
      if (!id) {
        return null;
      }
      const songs = await getSongsForBand(id);
      return songs;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBand = (id?: string | null) => {
  return useQuery(
    ["band", id],
    async () => {
      if (!id) {
        return null;
      }
      const band = await getBand(id);
      return band;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const EditSongs = () => {
  const { id: bandId } = useParams<{ id: string }>();
  const { id: userId } = useSelector((state: RootState) => state.user);

  const { data: band } = useBand(bandId);

  const {
    data: songs,
    isFetching: isFetchingSongs,
    refetch: refetchSongs,
  } = useSongsForBand(bandId);

  const [error, setError] = useState<string | null>(null);

  const [isAddingSong, setIsAddingSong] = useState(false);
  const [editingSongId, setEditingSongId] = useState<string | null>(null);
  const [deactivatingSong, setDeactivatingSong] = useState<Song | null>(null);
  const [activatingSong, setActivatingSong] = useState<Song | null>(null);

  const [songName, setSongName] = useState("");
  const [songAlbumName, setSongAlbumName] = useState("");
  const [songDurationSeconds, setSongDurationSeconds] = useState<number | null>(
    null,
  );
  const [songPictureUrlValue, setSongPictureUrlValue] = useState<string | null>(
    null,
  );

  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [isEditingPicture, setIsEditingPicture] = useState<boolean>(false);
  const [songValue, setSongValue] = useState<File | null>(null);
  const [songType, setSongType] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const handleClickAddSong = () => {
    setIsAddingSong(true);
    setSongValue(null);
    setSongAlbumName("");
    setSongDurationSeconds(null);
    setSongName("");
    setSongPictureUrlValue(null);
    setIsEditingPicture(false);
  };

  const handleClickEditSong = (song: Song) => {
    setEditingSongId(song.id);
    setSongName(song.name);
    setSongAlbumName(song.albumName);
    setSongDurationSeconds(song.durationSeconds);
    setSongPictureUrlValue(song.songPictureUrl || null);
    setIsEditingPicture(false);
  };

  const handleClickDeactivateSong = (song: Song) => {
    setDeactivatingSong(song);
  };

  const handleClickActivatingSong = (song: Song) => {
    setActivatingSong(song);
  };

  const handleFinishedDeactivatingSong = () => {
    setDeactivatingSong(null);
  };

  const handleFinishedActivatingSong = () => {
    setActivatingSong(null);
  };

  const handleConfirmDeactivate = async () => {
    setIsWorking(true);
    try {
      if (deactivatingSong && deactivatingSong.id) {
        await updateSongStatus({
          songId: deactivatingSong.id,
          status: SongStatus.INACTIVE,
        });
        handleFinishedDeactivatingSong();
        refetchSongs();
      }
    } catch (err) {
    } finally {
      setIsWorking(false);
    }
  };
  const handleConfirmActivate = async () => {
    setIsWorking(true);
    try {
      if (activatingSong && activatingSong.id) {
        await updateSongStatus({
          songId: activatingSong.id,
          status: SongStatus.ACTIVE,
        });
        handleFinishedActivatingSong();
        refetchSongs();
      }
    } catch (err) {
    } finally {
      setIsWorking(false);
    }
  };

  const handleSaveClicked = async () => {
    setIsWorking(true);
    if (!editingSongId) {
      if (songValue && songType && songDurationSeconds && bandId && userId) {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const date = new Date();
          const uploadKey = `songs/${user.attributes.sub}-${+date}.mp3`;
          const storageResp = (await Storage.put(uploadKey, songValue, {
            contentType: songType,
            progressCallback(progress) {
              const progressAmount = Math.round(
                (progress.loaded / (progress.total + 1)) * 100,
              );
              setProgress(progressAmount);
            },
          })) as { key: string };
          const { key } = storageResp;
          const newSongUrl = getImageUrl(key);

          const newSong = await createSong({
            creatorId: userId,
            bandId,
            name: songName,
            albumName: songAlbumName,
            durationSeconds: songDurationSeconds,
            songUrl: newSongUrl,
            songPictureUrl: songPictureUrlValue,
          });

          if (newSong) {
            setIsAddingSong(false);
            refetchSongs();
          }
        } catch (e) {
          setError("Error creating song");
        }
      } else {
        setError("Please fill out all fields");
      }
    } else {
      if (songName && songAlbumName && songDurationSeconds) {
        try {
          const updatedSong = await updateSong({
            id: editingSongId,
            name: songName,
            albumName: songAlbumName,
            songPictureUrl: songPictureUrlValue,
          });
          if (updatedSong) {
            setEditingSongId(null);
            refetchSongs();
          }
        } catch (e) {
          setError("Error updating song");
        }
      } else {
        setError("Please fill out all fields");
      }
    }
    setIsWorking(false);
  };

  const handleUploadImage = (url: string) => {
    setSongPictureUrlValue(getImageUrl(url));
    setIsEditingPicture(false);
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const audio = new Audio();
      audio.addEventListener("loadedmetadata", () => {
        setSongDurationSeconds(Math.floor(audio.duration));
      });
      audio.src = URL.createObjectURL(e.target.files[0]);
      setSongValue(e.target.files[0]);
      setSongType(e.target.files[0].type);
    }
  };

  const renderSongs = () => {
    if (isFetchingSongs) {
      return <CircularProgress />;
    }
    if (!songs) {
      return null;
    }
    if (songs && songs.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography variant="h4">No songs yet!</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickAddSong}>
            Add a song
          </Button>
        </Box>
      );
    }
    return (
      <Box>
        <>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickAddSong}>
              Add a song
            </Button>
          </Box>

          <Grid container spacing={1} sx={{ mt: 1 }}>
            {songs.map((song: Song) => {
              return (
                <Grid item key={song.id} xs={12} sm={4}>
                  <SongCard
                    song={song}
                    onClickEdit={() => handleClickEditSong(song)}
                    onClickActivate={() => handleClickActivatingSong(song)}
                    onClickDeactivate={() => handleClickDeactivateSong(song)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      </Box>
    );
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <Breadcrumbs sx={{ mb: 2 }}>
        <Link component={RRLink} to="/bands/mine">
          My Bands
        </Link>
        {band && (
          <Link component={RRLink} to={`/bands/${bandId}`}>
            {band.name}
          </Link>
        )}
        <Typography color="textPrimary">Songs</Typography>
      </Breadcrumbs>
      {renderSongs()}
      <Dialog open={isAddingSong}>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {error}
            </Alert>
          )}
          <TextField
            label="Song name"
            value={songName}
            fullWidth
            onChange={(e) => setSongName(e.target.value)}
          />
          <TextField
            label="Album name"
            value={songAlbumName}
            fullWidth
            sx={{ my: 1 }}
            onChange={(e) => setSongAlbumName(e.target.value)}
          />
          <Box sx={{ border: "1px #eee solid", p: 2, mb: 1 }}>
            <Typography variant="h6">Song picture</Typography>
            {songPictureUrlValue && (
              <img
                src={songPictureUrlValue}
                alt={`Album art for ${songName}`}
                style={{ width: 100, height: 100 }}
              />
            )}
            <PhotoCropper
              onCancel={() => null}
              showPreviewSquare={true}
              onComplete={handleUploadImage}
              aspect={1}
              containerSx={{ width: { xs: "100%", md: "550px" } }}
              imageSx={{ width: { xs: "100%", md: "400px" } }}
              keyFolder="song-image"
            />
          </Box>

          <Box sx={{ border: "1px #eee solid", p: 2 }}>
            <Typography variant="h6">Song file</Typography>
            <label htmlFor="contained-button-file">
              <input
                accept="audio/*"
                id="contained-button-file"
                onChange={onSelectFile}
                type="file"
              />
            </label>
            {progress > 0 && progress < 100 && (
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={progress} />
              </Box>
            )}
          </Box>
          <Box>
            {songDurationSeconds && (
              <Typography>
                Song length: {Math.floor(songDurationSeconds / 60)}:
                {songDurationSeconds % 60 < 10 ? "0" : ""}
                {songDurationSeconds % 60}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={isWorking}
            onClick={handleSaveClicked}>
            {isWorking ? "Saving..." : "Save"}
          </Button>
          <Button onClick={() => setIsAddingSong(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editingSongId !== null}>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {error}
            </Alert>
          )}
          <TextField
            label="Song name"
            value={songName}
            fullWidth
            onChange={(e) => setSongName(e.target.value)}
          />
          <TextField
            label="Album name"
            value={songAlbumName}
            fullWidth
            sx={{ my: 1 }}
            onChange={(e) => setSongAlbumName(e.target.value)}
          />
          {!isEditingPicture && (
            <Box>
              <Typography variant="h6">Song picture</Typography>
              {songPictureUrlValue && (
                <img
                  src={songPictureUrlValue}
                  alt={`Album art for ${songName}`}
                  style={{ width: 100, height: 100 }}
                />
              )}
              <Button onClick={() => setIsEditingPicture(true)}>
                Change Picture
              </Button>
            </Box>
          )}
          {isEditingPicture && (
            <PhotoCropper
              onCancel={() => null}
              showPreviewSquare={true}
              onComplete={handleUploadImage}
              aspect={1}
              containerSx={{ width: { xs: "100%", md: "550px" } }}
              imageSx={{ width: { xs: "100%", md: "400px" } }}
              keyFolder="song-image"
            />
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isWorking}
            onClick={handleSaveClicked}>
            Save
          </LoadingButton>
          <Button onClick={() => setEditingSongId(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deactivatingSong !== null}>
        <DialogTitle>Deactivate this song?</DialogTitle>
        <DialogContent>
          <Typography>
            Deactivated songs do not appear on your band's profile.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isWorking}
            onClick={handleConfirmDeactivate}>
            Yes
          </LoadingButton>
          <Button onClick={() => setDeactivatingSong(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={activatingSong !== null}>
        <DialogTitle>Activate this song?</DialogTitle>
        <DialogContent>
          <Typography>
            Activated songs appear on your band's profile. You can have up to 4
            active songs.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isWorking}
            onClick={handleConfirmActivate}>
            Yes
          </LoadingButton>
          <Button onClick={() => setActivatingSong(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
