import React, { useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import GroupsIcon from "@mui/icons-material/Groups";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import { House, Search } from "@mui/icons-material";
import { Link as RRLink, useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/stores";
import { setUserAuthed, setWantsAuth } from "src/stores/AuthStore";
import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { resetUser } from "src/stores/UserStore";
import { useQuery, useQueryClient } from "react-query";
import {
  getMyBandRequests,
  getMyBandShowRequests,
  getMyPendingFriendRequests,
} from "../Notifications/backend";

import { searchBands } from "../Bands/backend";
import { searchUsers } from "src/containers/Home/backend";
import { debounce } from "lodash";
import { Band, User } from "src/API";
import NoUserPicture from "src/components/NoUserPicture";
import NoBandPicture from "src/components/NoBandPicture";

const useNotifications = (userId: string | null) => {
  return useQuery(
    ["user", userId, "notifications"],
    async () => {
      if (!userId) {
        return {
          hasNotifications: false,
          bandMemberRequests: [],
        };
      }
      const bandMemberRequests = await getMyBandRequests(userId);
      const bandShowRequests = await getMyBandShowRequests(userId);
      const pendingFriendRequests = await getMyPendingFriendRequests(userId);

      const pendingShows = bandShowRequests?.reduce((acc, cur) => {
        const amount = cur?.band?.pendingBandShows?.items.length || 0;
        return acc + amount;
      }, 0);

      const notificationTotal =
        bandMemberRequests.length + pendingShows + pendingFriendRequests.length;

      return {
        hasNotifications: notificationTotal > 0,
        bandMemberRequests,
        pendingFriendRequests,
      };
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 60,
    },
  );
};

const pages = [
  {
    label: "Home",
    icon: <House />,
    path: "/",
  },
  {
    label: "Bands",
    icon: <GroupsIcon />,
    path: "/bands",
  },
  {
    label: "Venues",
    icon: <CoPresentIcon />,
    path: "/venues",
  },
  {
    label: "Shows",
    icon: <QueueMusicIcon />,
    path: "/shows",
  },
];

export const Navigation = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [mobileDrawer, setMobileDrawer] = useState<boolean>(false);
  const [isShowingSignOut, setIsShowingSignOut] = useState<boolean>(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { isAuthed } = useSelector((state: RootState) => state.auth);
  const { profilePictureUrl, id: userId } = useSelector(
    (state: RootState) => state.user,
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: notifications } = useNotifications(userId);

  const [currentQuery, setCurrentQuery] = useState("");

  const [isSearchingUsers, setIsSearchingBands] = useState<boolean>(false);
  const [isSearchingBands, setIsSearchingUsers] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [userResults, setUserResults] = useState<User[]>([]);
  const [bandresults, setBandResults] = useState<Band[]>([]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMobileDrawer(true);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setMobileDrawer(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (path: string) => () => {
    setHasSearched(false);
    navigate(path);
    handleCloseNavMenu();
  };

  const handleClickSignIn = () => {
    dispatch(setWantsAuth(true));
  };

  const handleClickSignOut = () => {
    setIsShowingSignOut(true);
  };

  const handleConfirmSignOut = async () => {
    setIsShowingSignOut(false);
    dispatch(resetUser());
    dispatch(setWantsAuth(false));
    dispatch(setUserAuthed(false));
    await queryClient.invalidateQueries();
    await Auth.signOut();
  };

  const performUserSearch = async (search: string) => {
    try {
      const users = await searchUsers(search);
      setUserResults(users.slice(0, 3));
    } catch (err) {
    } finally {
      setIsSearchingUsers(false);
    }
  };
  const performBandSearch = async (search: string) => {
    try {
      const bands = await searchBands(search);
      setBandResults(bands.slice(0, 3));
    } catch (err) {
    } finally {
      setIsSearchingBands(false);
    }
  };

  const searchRef = useRef(
    debounce((query: string) => {
      if (query.length > 2) {
        performUserSearch(query);
        performBandSearch(query);
      }
    }, 1000),
  ).current;

  const handleSearch = (query: string) => {
    setCurrentQuery(query);
    setIsSearchingUsers(query.length > 2);
    setIsSearchingBands(query.length > 2);
    searchRef(query);
    setHasSearched(query.length > 2);
  };

  const authSettings = [
    {
      label: "Settings",
      path: "/settings",
    },
    {
      label: "Profile",
      path: "/profile",
    },
    {
      label: "Sign Out",
      onClick: handleClickSignOut,
      path: "/",
    },
  ];

  const renderUserProfilePicture = (user: User) => {
    if (user.profilePictureUrl) {
      return (
        <Avatar
          src={user.profilePictureUrl}
          sx={{ width: 50, height: "auto" }}
        />
      );
    }
    return <NoUserPicture size={50} iconSize="xl" />;
  };
  const renderBandProfilePicture = (band: Band) => {
    if (band.profilePictureUrl) {
      return (
        <Avatar
          src={band.profilePictureUrl}
          sx={{ width: 50, height: "auto" }}
        />
      );
    }
    return <NoBandPicture size={50} iconSize="xl" />;
  };

  const renderSearchResults = () => {
    if (
      !hasSearched ||
      isSearchingUsers ||
      isSearchingBands ||
      (!userResults && !bandresults) ||
      (userResults.length === 0 && bandresults.length === 0)
    ) {
      return null;
    }
    return (
      <Card
        sx={{
          position: "absolute",
          width: "100%",
          zIndex: 99999,
        }}>
        <CardContent>
          {userResults.map((user) => (
            <Box
              onClick={() => {
                setCurrentQuery("");
                setHasSearched(false);
                navigate(`/users/${user.id}`);
              }}
              key={`user-${user.id}`}
              sx={{
                display: "flex",
                alignItems: "center",
                py: 1,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#fafafa",
                },
              }}>
              {renderUserProfilePicture(user)}
              <Box>
                <Typography sx={{ ml: 1 }}>
                  {user.firstName}, {user.lastName}
                </Typography>
                <Typography sx={{ ml: 1 }}>({user.username})</Typography>
              </Box>
              <Divider />
            </Box>
          ))}
          {bandresults.map((band) => (
            <Box
              onClick={() => {
                setCurrentQuery("");
                setHasSearched(false);
                navigate(`/bands/${band.id}`);
              }}
              key={`band-${band.id}`}
              sx={{
                display: "flex",
                alignItems: "center",
                py: 1,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#fafafa",
                },
              }}>
              {renderBandProfilePicture(band)}
              <Box>
                <Typography sx={{ ml: 1 }}>{band.name}</Typography>
                <Typography sx={{ ml: 1 }}>
                  (
                  {[band.city, band.state].filter((b) => Boolean(b)).join(", ")}
                  {!band.city && !band.state && "Unknown"})
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
            <RRLink to="/" style={{ color: "white", textDecoration: "none" }}>
              <img
                src="/images/TTMLogo-Flat.png"
                alt="ToTheMusic"
                style={{ width: 40 }}
              />
            </RRLink>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              alignItems: "center",
            }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate("/search")}
              sx={{
                backgroundColor: "white",
                "&:active, &:hover": {
                  backgroundColor: "white",
                },
              }}>
              <Search />
            </IconButton>

            <Drawer
              open={mobileDrawer}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "flex", md: "none" },
              }}>
              <List>
                {pages.map(({ label, path, icon }) => (
                  <ListItem key={label} disablePadding sx={{ pr: 3 }}>
                    <ListItemButton onClick={handleNavigate(path)}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              alignItems: "center",
            }}>
            <RRLink to="/" style={{ color: "white", textDecoration: "none" }}>
              <img
                src="/images/TTMLogo-Flat.png"
                alt="ToTheMusic"
                style={{ width: 40 }}
              />
            </RRLink>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}>
            {pages.map(({ label, path }) => (
              <Button
                key={label}
                onClick={handleNavigate(path)}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                }}>
                {label}
              </Button>
            ))}
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                outline: "none",
                borderRadius: 10,
              }}>
              {location.pathname !== "/search" && (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: 600,
                    mb: { xs: 2, md: 0, borderRadius: 10, outline: "none" },
                  }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setIsSearchingBands(false);
                      setIsSearchingUsers(false);
                      setHasSearched(false);
                      navigate(`/search?q=${currentQuery}`);
                      setCurrentQuery("");
                      return null;
                    }}>
                    <TextField
                      placeholder="Search ToTheMusic"
                      type="search"
                      id="search-query"
                      InputProps={{
                        startAdornment: <Search sx={{ mr: 1 }} />,
                      }}
                      onChange={(e) => handleSearch(e.target.value)}
                      value={currentQuery}
                      onBlur={(e) =>
                        setTimeout(() => {
                          setHasSearched(false);
                        }, 200)
                      }
                      sx={{
                        borderRadius: 1,
                        display: "flex",
                        backgroundColor: "#fff",
                        flex: 1,
                        maxWidth: 600,
                        border: "none",
                        outline: "none",
                        "& *": {
                          border: "none",
                        },
                      }}
                    />
                  </form>
                  {renderSearchResults()}

                  {(isSearchingUsers || isSearchingBands) && (
                    <Card
                      sx={{
                        position: "absolute",
                        bottom: "calc(-100% - 30px)",
                        width: "100%",
                      }}>
                      <CardContent>
                        <CircularProgress />
                      </CardContent>
                    </Card>
                  )}
                  {hasSearched &&
                    !isSearchingUsers &&
                    !isSearchingBands &&
                    userResults.length === 0 &&
                    bandresults.length === 0 && (
                      <Card
                        sx={{
                          position: "absolute",
                          bottom: "calc(-100% - 10px)",
                          width: "100%",
                        }}>
                        <CardContent>
                          <Typography>No results found</Typography>
                        </CardContent>
                      </Card>
                    )}
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            {notifications?.hasNotifications && (
              <Link
                component={RRLink}
                to="/notifications"
                sx={{ display: "flex", alignItems: "center" }}>
                <CircleNotificationsIcon
                  fontSize="large"
                  className="ringingBell"
                  sx={{
                    color: "white",
                  }}
                />
              </Link>
            )}
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                color="primary"
                sx={{
                  p: 0.5,
                  backgroundColor: "white",
                  border: "1px white solid",
                  "&:hover": {
                    color: "white",
                  },
                }}>
                {!profilePictureUrl && <House />}
                {profilePictureUrl && (
                  <Avatar
                    sx={{ height: 30, width: 30 }}
                    src={profilePictureUrl}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {isAuthed &&
                authSettings.map(({ label, onClick, path }) => (
                  <MenuItem
                    key={label}
                    onClick={() => {
                      if (onClick) {
                        onClick();
                      }
                      handleCloseUserMenu();
                      navigate(path);
                    }}>
                    <Typography>{label}</Typography>
                  </MenuItem>
                ))}
              {!isAuthed && (
                <MenuItem onClick={handleClickSignIn}>
                  <Typography>Sign In</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Dialog
        open={isShowingSignOut}
        onClose={() => setIsShowingSignOut(false)}>
        <DialogTitle>Are you sure you want to sign out?</DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", p: 3 }}>
          <Button
            onClick={handleConfirmSignOut}
            variant="contained"
            size="large">
            Sign Out
          </Button>
          <Button onClick={() => setIsShowingSignOut(false)} size="large">
            Cancel
          </Button>
        </Box>
      </Dialog>
    </AppBar>
  );
};
