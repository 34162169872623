import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  CardActionArea,
  Alert,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { getUser, getUserFans } from "./backend";
import NoBandPicture from "src/components/NoBandPicture";
import Friendship from "./segments/Friendship";
import NoUserPicture from "src/components/NoUserPicture";
import LightBox from "src/components/LightBox";

const useUser = (id: string | null) => {
  return useQuery(
    ["user", id, "profile"],
    async () => {
      if (!id) {
        return null;
      }
      const user = await getUser(id);
      return user;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useUserBandFans = (id?: string | null) => {
  return useQuery(
    ["user", id, "fans"],
    async () => {
      if (!id) {
        return null;
      }
      const fans = await getUserFans(id);
      return fans;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const Profile = () => {
  const { id } = useSelector((state: RootState) => state.user);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isMe, setIsMe] = useState(false);
  const [isViewingProfile, setIsViewingProfile] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: user,
    isFetching: isFetchingUser,
    isLoading: isLoadingUser,
  } = useUser(params?.id || id);

  const { data: bandFans } = useUserBandFans(id);
  const pictures = [user?.profilePictureUrl, user?.profileBannerUrl].filter(
    (p) => Boolean(p),
  ) as string[];

  useEffect(() => {
    setIsMe(!params.id || params.id === id);
  }, [id, params.id]);

  const renderBandFans = () => {
    if (!bandFans) {
      return null;
    }

    return (
      <Box>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h5" sx={{ mb: 2 }}>
          Followed Bands
        </Typography>
        <Grid container spacing={1}>
          {bandFans.map((fan) => {
            const { band } = fan;
            if (!band) {
              return null;
            }
            return (
              <Grid item key={`fans-${fan.id}`} xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea onClick={() => navigate(`/bands/${band.id}`)}>
                    {band?.profilePictureUrl && (
                      <CardMedia
                        component="img"
                        height="200"
                        src={band.profilePictureUrl}
                      />
                    )}
                    {!band?.profilePictureUrl && (
                      <CardMedia component="img" height="200">
                        <NoBandPicture size={200} iconSize="6x" />{" "}
                      </CardMedia>
                    )}
                    <CardContent>
                      <Typography>{band?.name}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };
  const renderFriendZone = () => {
    if (isMe || !user) {
      return null;
    }
    return (
      <Box sx={{ mt: 2 }}>
        <Friendship user={user} />
      </Box>
    );
  };

  if (isFetchingUser || isLoadingUser) {
    return (
      <Container maxWidth="md">
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  if (!user) {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 5 }}>
          User not found
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            flexDirection: { xs: "column", sm: "row" },
          }}>
          {user.profilePictureUrl && (
            <Avatar
              src={user.profilePictureUrl}
              onClick={() => setIsViewingProfile(true)}
              sx={{
                transition: "all .25s ease-in-out",
                cursor: "pointer",
                "&:hover": { boxShadow: "0 2px 5px 1px rgba(0,0,0,.1)" },
              }}
              alt={`profile of ${user.firstName} ${user.lastName}`}
              style={{ width: 150, height: "auto" }}
            />
          )}
          {!user.profilePictureUrl && (
            <NoUserPicture size={150} iconSize="6x" />
          )}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Box
              sx={{
                ml: { xs: 0, sm: 1 },
                textAlign: { xs: "center", sm: "left" },
              }}>
              <Typography variant="h4">
                {user.firstName}, {user.lastName}
              </Typography>
              <Typography variant="body1">({user.username})</Typography>
            </Box>
            {!isMobile && renderFriendZone()}
          </Box>
        </Box>
        {isMe && (
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: { xs: 2, sm: 0 },
            }}
            onClick={() => navigate("/profile/edit")}>
            Edit Profile
          </Button>
        )}
      </Box>
      {isMobile && renderFriendZone()}
      {renderBandFans()}
      {user.profilePictureUrl && (
        <LightBox
          activeIndex={0}
          onClose={() => setIsViewingProfile(false)}
          pictures={pictures}
          open={isViewingProfile}
        />
      )}
    </Container>
  );
};
