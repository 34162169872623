import { Venue } from "src/API";

type SkipOption = "address" | "city" | "state";
type SkipOptions = SkipOption[];

export const renderVenueAddress = (
  venue: Venue,
  options: { skip: SkipOptions },
): string => {
  const parts = [];
  if (venue.address && !options.skip.includes("address")) {
    parts.push(venue.address);
  }
  if (venue.city && !options.skip.includes("city")) {
    parts.push(venue.city);
  }
  if (venue.state && !options.skip.includes("state")) {
    parts.push(venue.state);
  }
  return parts.join(", ");
};
