import {
  differenceInHours,
  format,
  formatDistanceToNow,
  isTomorrow,
  isToday,
  isYesterday,
} from "date-fns";
import formatISO from "date-fns/formatISO";

export const getTimeStamp = (): string => {
  return formatISO(new Date());
};

export const getTimeDisplay = (time: string): string => {
  if (isTomorrow(new Date(time))) {
    return `Tomorrow at ${format(new Date(time), "p")}`;
  } else if (differenceInHours(new Date(time), new Date()) > 24) {
    return format(new Date(time), "E, MMM do 'at' p 'EST'");
  } else {
    return `${formatDistanceToNow(new Date(time), {
      addSuffix: true,
    })} at ${format(new Date(time), "p")}`;
  }
};

export const ago = (date: string): string => {
  const timestamp = new Date(date);
  if (isToday(timestamp)) {
    return formatDistanceToNow(timestamp, { addSuffix: true });
  } else if (isYesterday(timestamp)) {
    return `Yesterday at ${format(timestamp, "p")}`;
  }

  return format(timestamp, "MMM do 'at' p 'EST'");
};

/**
 * dynamicHoursAhead returns the number of hours between now and the end of day tomorrow.
 * This will get all the hours for the rest of today and for all the days provided.
 * @param days the number of days to get hours for
 * @returns number of hours between now and the end of the last day provided.
 */
export const dynamicHoursAhead = (days = 2): number => {
  const now = new Date();
  return days * 24 - now.getHours();
};
