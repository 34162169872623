import { Search } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { User } from "src/API";
import { useSearch } from "src/utils/search";
import {
  getBandMemberRequestsForBand,
  getBandMembers,
  inviteUserToBand,
  searchUsers,
} from "./backend";
import NoUserPicture from "src/components/NoUserPicture";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery } from "react-query";

const useMembers = (id?: string | null) => {
  return useQuery(
    ["band", id, "members"],
    async () => {
      if (!id) {
        return null;
      }

      const members = await getBandMembers(id);
      return members;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useMemberRequests = (id?: string | null) => {
  return useQuery(
    ["band", id, "memberRequests"],
    async () => {
      if (!id) {
        return null;
      }

      const requests = await getBandMemberRequestsForBand(id);
      return requests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const BandMemberForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const starterQuery = searchParams.get("query");
  const [query, setQuery] = useState<string>(starterQuery || "");

  const [isWorking, setIsWorking] = useState<boolean>(false);

  const { id: bandId } = useParams<{ id: string }>();

  const {
    data: members,
    isFetching: isFetchingMembers,
    refetch: refetchMembers,
  } = useMembers(bandId);
  const {
    data: requests,
    isFetching: isFetchingRequests,
    refetch: refetchRequests,
  } = useMemberRequests(bandId);

  const { hasSearched, isSearching, error, search } = useSearch(searchUsers);
  const [userResults, setUserResults] = useState<User[]>([]);

  const [success, setSuccess] = useState<string | null>(null);
  const [failure, setFailure] = useState<string | null>(null);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const navigate = useNavigate();

  const isInBand = (user: User) => {
    return members && members.some((member) => member.userId === user.id);
  };
  const isInvited = (user: User) => {
    return requests && requests.some((request) => request.userId === user.id);
  };

  const onClickSearch = async () => {
    const results = await search(query);
    setUserResults(results);
  };
  const handleClear = () => {
    setSelectedUser(null);
    setSuccess(null);
    setFailure(null);
  };
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    setSearchParams({ query });
    e.preventDefault();
    onClickSearch();
    return false;
  };

  const handleInviteToBand = (user: User) => {
    setSelectedUser(user);
  };
  const handleConfirmAddUser = async () => {
    try {
      if (!selectedUser || !bandId) {
        return;
      }

      setIsWorking(true);

      const input = { userId: selectedUser.id, bandId };
      const dummyResp = await inviteUserToBand(input);
      if (!dummyResp) {
        setFailure("Error inviting user to band");
      }
      // @ts-ignore
      const { success, message } = JSON.parse(dummyResp) as {
        success: boolean;
        message: string;
      };
      if (success) {
        await refetchMembers();
        await refetchRequests();
        setSuccess("Successfully invited user to the band");
        setSelectedUser(null);
      } else {
        setFailure(message);
      }
    } catch (err) {
      setFailure("Error inviting user to band");
    } finally {
      setIsWorking(false);
    }
  };
  useEffect(() => {
    if (starterQuery) {
      onClickSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderUserProfilePicture = (user: User) => {
    if (user.profilePictureUrl) {
      return (
        <Avatar
          src={user.profilePictureUrl}
          sx={{ width: 50, height: "auto" }}
        />
      );
    }
    return <NoUserPicture size={50} iconSize="xl" />;
  };
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <Alert severity="info">
        <Typography>To add a band member search by their username</Typography>
      </Alert>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          my: 3,
          mx: "auto",
          alignItems: "center",
        }}
        component="form"
        onSubmit={(e: FormEvent<HTMLFormElement>) => handleFormSubmit(e)}
        maxWidth="md">
        <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
          <InputLabel
            htmlFor="member-search-box"
            sx={{ backgroundColor: "white", pr: 1 }}>
            Member Search
          </InputLabel>
          <OutlinedInput
            id="member-search-box"
            value={query}
            type="search"
            onChange={(e) => setQuery(e.target.value)}
            endAdornment={
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={onClickSearch}>
                <Search />
              </Button>
            }
            label="Search Users"
          />
        </FormControl>
      </Box>
      <Box>
        {error && <Alert severity="error">{error}</Alert>}
        {isSearching && <Typography>Searching...</Typography>}
        {!isSearching &&
          hasSearched &&
          userResults &&
          userResults.length === 0 && <Typography>No results</Typography>}
        {!isSearching &&
          userResults &&
          userResults.map((user) => (
            <Card key={user.id} sx={{ mb: 2 }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {renderUserProfilePicture(user)}
                  <Box>
                    <Typography sx={{ ml: 1 }}>
                      {user.firstName}, {user.lastName}
                    </Typography>
                    <Typography sx={{ ml: 1 }}>({user.username})</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", md: "column" },
                    mt: { xs: 2, md: 0 },
                  }}>
                  <Button
                    variant="outlined"
                    sx={{ mb: { xs: 0, md: 1 }, mr: { xs: 1, md: 0 } }}
                    onClick={() => navigate(`/users/${user.id}`)}>
                    View Profile
                  </Button>
                  {!isFetchingMembers &&
                    !isFetchingRequests &&
                    !isInBand(user) &&
                    !isInvited(user) && (
                      <Button
                        variant="outlined"
                        onClick={() => handleInviteToBand(user)}>
                        Invite to band
                      </Button>
                    )}
                  {isInBand(user) && <Button disabled>Already in band</Button>}
                  {!isInBand(user) && isInvited(user) && (
                    <Button disabled>Already Invited</Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          ))}
      </Box>
      <Snackbar
        open={!!success}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}>
        <Alert severity="success">{success}</Alert>
      </Snackbar>
      <Dialog open={!!selectedUser}>
        <DialogContent>
          {failure && <Alert severity="error">{failure}</Alert>}
          <Typography>
            Are you sure you want to invite this user to your band?
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isWorking} onClick={handleConfirmAddUser}>
            Yes
          </LoadingButton>
          <Button onClick={handleClear}>No</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
