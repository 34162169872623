import { Add } from "@mui/icons-material";
import {
  Button,
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/Header";
import { useQuery } from "react-query";
import { getMyShows } from "./backend";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { Show } from "src/API";
import ShowCard from "src/components/ShowCard";

const useMyShows = (id?: string | null) => {
  return useQuery(
    ["myShows", id],
    async () => {
      if (!id) {
        return null;
      }

      const shows = await getMyShows(id);

      return shows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const MyShows = () => {
  const navigate = useNavigate();
  const { id: userId } = useSelector((state: RootState) => state.user);
  const { data: shows, isFetching } = useMyShows(userId);

  const renderLoading = () => {
    if (isFetching) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h4">Loading...</Typography>
        </Box>
      );
    }
  };
  const renderShow = (show: Show) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={show.id} sx={{ mb: 3 }}>
        <ShowCard show={show} isEditable showVenue={true} />
      </Grid>
    );
  };
  return (
    <Box>
      <Header
        title="My Shows"
        right={
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() => navigate("/shows/new")}>
            Add Show
          </Button>
        }
      />
      <Container maxWidth="lg" sx={{ mt: 3 }}>
        {renderLoading()}
        <Grid container spacing={1}>
          {shows && shows.map(renderShow)}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={"new-show-key"}
            sx={{
              mb: 3,
            }}>
            {shows && shows.length > 0 && (
              <Card>
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<Add />}
                    onClick={() => navigate("/shows/new")}>
                    Add Show
                  </Button>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
        {shows && shows.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Typography variant="h4">No shows</Typography>
            <Button variant="contained" onClick={() => navigate("/shows/new")}>
              Add a show
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};
