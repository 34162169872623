import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import { hydrateOnboarding } from "src/stores/OnboardingStore";
import Name from "./Name";
import ProfileImage from "./ProfileImage";
import Username from "./Username";
import { Box, Button, Container } from "@mui/material";
import { Auth } from "aws-amplify";

export const Onboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(hydrateOnboarding());
  }, [dispatch]);
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          onClick={() => {
            Auth.signOut();
            navigate("/");
          }}>
          SignOut
        </Button>
      </Box>
      <Routes>
        <Route path="/username" element={<Username />} />
        <Route path="/picture" element={<ProfileImage />} />
        <Route path="*" element={<Name />} />
      </Routes>
    </Container>
  );
};
