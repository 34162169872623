import { Add, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, Link as RRLink, useNavigate } from "react-router-dom";
import { BandMember } from "src/API";
import { getBand, getBandMembers, updateBandMember } from "./backend";
import NoUserPicture from "src/components/NoUserPicture";

const useMembers = (id?: string | null) => {
  return useQuery(
    ["band", id, "members"],
    async () => {
      if (!id) {
        throw new Error("No band id");
      }

      const members = await getBandMembers(id);
      return members;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBand = (id?: string | null) => {
  return useQuery(
    ["band", id],
    async () => {
      if (!id) {
        throw new Error("No band id");
      }
      const band = await getBand(id);
      return band;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const BandMembers = () => {
  const [showAlert, setShowAlert] = useState<boolean>(true);
  const [selectedMember, setSelectedMember] = useState<BandMember | null>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [instrumentValue, setInstrumentValue] = useState<string>("");
  const { data: members, isFetching, refetch: refetchMembers } = useMembers(id);
  const { data: band } = useBand(id);

  const handleSelectMember = (member: BandMember) => {
    setInstrumentValue(member?.instrument || "");
    setSelectedMember(member);
  };

  const handleSaveMember = async () => {
    setIsSaving(true);
    try {
      if (selectedMember) {
        await updateBandMember({
          memberId: selectedMember.id,
          instrument: instrumentValue,
        });
        refetchMembers();
        setSelectedMember(null);
      }
    } catch (e) {
      console.log("[ERROR] error updating member", e);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Container sx={{ mt: 3 }} maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}>
        <Breadcrumbs>
          <Link component={RRLink} to={`/bands/mine`}>
            My Bands
          </Link>
          <Link component={RRLink} to={`/bands/${id}`}>
            {band?.name || "..."}
          </Link>
          <Typography>Members</Typography>
        </Breadcrumbs>
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{ my: { xs: 2, sm: 0 } }}
          onClick={() => navigate(`/bands/${id}/members/add`)}>
          Add Member
        </Button>
      </Box>
      {isFetching && <Typography>Loading...</Typography>}
      {showAlert && (
        <Alert
          severity="warning"
          sx={{ mt: 3 }}
          closeText="Close"
          onClose={() => setShowAlert(false)}>
          <Typography>
            Band members serve as admins for the band. They can edit the
            information, add/remove shows, etc. Only add members you want with
            these roles.
          </Typography>
        </Alert>
      )}
      <Grid container spacing={1}>
        {members &&
          members.map((member) => {
            const { id: memberId, user, instrument } = member;
            return (
              <Grid item xs={12} sm={6} md={4} key={`member-${memberId}`}>
                <Card>
                  {user && user.profilePictureUrl && (
                    <CardMedia
                      component="img"
                      height="200"
                      src={user.profilePictureUrl}
                    />
                  )}
                  {(!user || !user.profilePictureUrl) && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <NoUserPicture size={200} iconSize="4x" />
                    </Box>
                  )}
                  <CardContent>
                    <Typography variant="h5">
                      {user?.firstName} {user?.lastName}{" "}
                      {instrument ? `- (${instrument})` : ""}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="outlined"
                      startIcon={<Edit />}
                      onClick={() => handleSelectMember(member)}>
                      Edit
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <Dialog open={selectedMember !== null}>
        <DialogTitle component="div">
          Edit {selectedMember?.user?.firstName}{" "}
          {selectedMember?.user?.lastName}
        </DialogTitle>
        <DialogContent>
          <Typography></Typography>
          <TextField
            sx={{ my: 2 }}
            fullWidth
            label="instrument"
            value={instrumentValue}
            onChange={(e) => setInstrumentValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isSaving} onClick={handleSaveMember}>
            Save
          </LoadingButton>
          <Button onClick={() => setSelectedMember(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
