import { Store } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Venue } from "src/API";

const VenuePicture = ({ venue, size }: { size?: number; venue: Venue }) => {
  const iconSize = size || 50;
  if (!venue.profileImageUrl) {
    return (
      <Box
        sx={{
          backgroundColor: "#dbdbdb",
          height: iconSize + 10,
          width: iconSize + 10,
          borderRadius: iconSize,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Store
          sx={{
            fontSize: iconSize,
          }}
        />
      </Box>
    );
  }
  return (
    <img
      src={venue.profileImageUrl}
      style={{ height: iconSize, width: iconSize }}
      alt={venue.name}
    />
  );
};

export default VenuePicture;
