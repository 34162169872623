import foursquareConfig from "src/config/foursquare-exports";

export type FSVenue = {
  fsq_id: string;
  distance: number;
  geocodes: {
    main: {
      latitude: number;
      longitude: number;
    };
  };
  location: {
    formatted_address: string;
    address: string;
    country: string;
    locality: string;
    postcode: string;
  };
  name: string;
};

type FSResponse = {
  results: Array<FSVenue>;
};

export const searchVenues = async (
  query: string,
  ll: string,
): Promise<FSVenue[]> => {
  const result = await fetch(
    `https://api.foursquare.com/v3/places/search?query=${query}&ll=${ll}&radius=8046`,
    {
      headers: {
        Accept: "application/json",
        Authorization: foursquareConfig.FOURSQUARE_API_KEY,
      },
    },
  );
  const json = (await result.json()) as FSResponse;
  return json.results;
};
