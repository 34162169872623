import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Snackbar,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useEffect, useReducer } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { UserPreferences } from "src/API";
import { RootState } from "src/stores";
import { getUserPreferncesForUser, saveUserPreferences } from "./backend";

import { useTimedMessage } from "src/utils/timedMessage";

const useUserPreferences = (userId?: string | null) => {
  return useQuery(
    ["user", userId, "userPreferences"],
    async () => {
      if (!userId) {
        return null;
      }
      const userPrefences = await getUserPreferncesForUser(userId);

      return userPrefences;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

/** NOTE: There are 3 places in this file to add a new preference flag
 * 1. Add the flag to the EMAIL_NOTIF_PREFS enum with human-readable label.
 * 2. Add the flag to the UserPreferencesState type with boolean | undefined | null type.
 * 3. Add the flag to the userPrefToUserPrefState function to pull it from the object.
 */

const EMAIL_NOTIF_PREFS = {
  wantsBandRequestEmailNotif:
    "Email notifications when someone invites me to join their band",
  wantsBandFriendEmailNotif:
    "Email notifications when someone adds my band as a friend",
  wantsUserFollowEmailNotif:
    "Email notifications of new follower counts (Usually sent weekly)",
};

type UserPreferenceState = {
  wantsEmailNotifications?: boolean | null;
  wantsBandRequestEmailNotif?: boolean | null;
  wantsBandFriendEmailNotif?: boolean | null;
  wantsUserFollowEmailNotif?: boolean | null;
};

/** have to do this to keep typescript happy about the conversion.
 *** NOTE: if you need to add a value to the state, make sure to add it here
 *** because it will need to be brought into the state */
const userPrefToUserPrefState = (
  userPref?: UserPreferences | null,
): UserPreferenceState | null => {
  if (!userPref) {
    return null;
  }
  return {
    wantsEmailNotifications: userPref?.wantsEmailNotifications,
    wantsBandRequestEmailNotif: userPref?.wantsBandRequestEmailNotif,
    wantsBandFriendEmailNotif: userPref?.wantsBandFriendEmailNotif,
    wantsUserFollowEmailNotif: userPref?.wantsUserFollowEmailNotif,
  };
};

type UserPreferencesAction =
  | {
      type: "SET_USER_PREFERENCES";
      payload: {
        key: keyof UserPreferenceState;
        value: boolean;
      };
    }
  | {
      type: "SET_STATE";
      payload: UserPreferenceState | null;
    };

const preferencesReducer = (
  state: UserPreferenceState | null,
  action: UserPreferencesAction,
) => {
  switch (action.type) {
    case "SET_STATE":
      return action.payload;
    case "SET_USER_PREFERENCES":
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};

export const Settings = () => {
  const { id } = useSelector((state: RootState) => state.user);

  const {
    message: error,
    clearMessage: clearError,
    setMessage: setError,
  } = useTimedMessage(5000);
  const {
    message: success,
    clearMessage: clearSuccess,
    setMessage: setSuccess,
  } = useTimedMessage(5000);

  const {
    data: userPreferences,
    isLoading: isLoadingPreferences,
    isFetching: isFetchingPreferences,
    isError: isErrorPreferences,
  } = useUserPreferences(id);

  const queryClient = useQueryClient();

  const [preferenceState, preferenceDispatch] = useReducer(
    preferencesReducer,
    userPreferences || null,
  );

  useEffect(() => {
    if (preferenceState === null) {
      preferenceDispatch({
        type: "SET_STATE",
        payload: userPrefToUserPrefState(userPreferences),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences]);

  const handleChangePreferenceValue = (
    key: keyof UserPreferenceState,
    value: boolean,
  ) => {
    preferenceDispatch({
      type: "SET_USER_PREFERENCES",
      payload: { key, value },
    });
  };
  const handleSave = async () => {
    if (preferenceState === null) {
      return;
    }
    if (userPreferences?.id) {
      try {
        clearError();
        clearSuccess();
        const result = await saveUserPreferences(
          userPreferences.id,
          preferenceState as UserPreferences,
        );
        if (result) {
          setSuccess("Preferences saved successfully!");
          queryClient.invalidateQueries(["user", id, "userPreferences"]);
        }
      } catch (e) {
        console.log("[ERROR] error saving preferences", e);
        setError("There was an error saving the settings. Please try again.");
      }
    }
  };
  const renderPreferences = () => {
    if (isLoadingPreferences || isFetchingPreferences) {
      return <CircularProgress />;
    }
    if (isErrorPreferences) {
      return <Alert severity="error">Error loading preferences</Alert>;
    }
    if (!userPreferences) {
      return null;
    }
    return (
      <Box>
        <Typography variant="h6">Email Notifications</Typography>
        <Card>
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    handleChangePreferenceValue(
                      "wantsEmailNotifications",
                      e.target.checked,
                    )
                  }
                  defaultChecked={
                    userPreferences["wantsEmailNotifications"] !== false
                  }
                />
              }
              label="Receive email notifications"
            />
            <Alert severity="warning">
              Disabling this will prevent you from receiving email
              notifications.
              <br />
              <em>
                Note: This does not affect system emails. (Forgot password,
                etc.)
              </em>
            </Alert>
          </CardContent>
        </Card>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography>Specific Email Notifications</Typography>
            {Object.keys(EMAIL_NOTIF_PREFS).map((key) => {
              const k = key as keyof typeof EMAIL_NOTIF_PREFS;
              const checked = preferenceState
                ? preferenceState[k] !== false &&
                  preferenceState["wantsEmailNotifications"] !== false
                : false;
              return (
                <Box
                  key={key}
                  sx={{
                    mb: { xs: 1, md: 0 },
                    pb: { xs: 1, md: 0 },
                    borderBottom: "1px #f0f0f0 solid",
                  }}>
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleChangePreferenceValue(k, e.target.checked)
                          }
                          checked={checked}
                          disabled={
                            preferenceState?.wantsEmailNotifications === false
                          }
                        />
                      }
                      label={EMAIL_NOTIF_PREFS[k]}
                    />
                  </>
                </Box>
              );
            })}
          </CardContent>
        </Card>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Button
            sx={{ width: "50%", minWidth: "200px" }}
            variant="contained"
            onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        onClose={clearError}>
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!success}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        onClose={clearSuccess}>
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      {renderPreferences()}
    </Container>
  );
};
