import { configureStore } from "@reduxjs/toolkit";
import AuthStore from "./AuthStore";
import ConfigStore from "./ConfigStore";
import OnboardingStore from "./OnboardingStore";
import UserStore from "./UserStore";
import LikeStore from "./LikeStore";
import CheersStore from "./CheersStore";

export const store = configureStore({
  reducer: {
    auth: AuthStore,
    user: UserStore,
    config: ConfigStore,
    onboarding: OnboardingStore,
    likes: LikeStore,
    cheers: CheersStore,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
