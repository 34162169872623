import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  isAuthed: boolean;
  wantsAuth: boolean;
  isAdmin: boolean;
}

const initialState: AuthState = {
  isAuthed: false,
  wantsAuth: false,
  isAdmin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserAuthed: (state, action: PayloadAction<boolean>) => {
      state.isAuthed = action.payload;
    },
    setWantsAuth: (state, action: PayloadAction<boolean>) => {
      state.wantsAuth = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserAuthed, setWantsAuth, setIsAdmin } = authSlice.actions;

export default authSlice.reducer;
