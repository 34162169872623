import { TextField, Typography, Box, Container, Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/stores";
import { setOnboardingName } from "src/stores/OnboardingStore";

const NameContainer = () => {
  const { firstName: defaultFirstName, lastName: defaultLastName } =
    useSelector((state: RootState) => state.onboarding);
  const [firstName, setFirstName] = useState<string>(defaultFirstName);
  const [lastName, setLastName] = useState<string>(defaultLastName);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNextClick = async () => {
    dispatch(setOnboardingName({ firstName, lastName }));
    navigate("/username");
  };
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h6">Welcome new user!</Typography>
        <Typography variant="h4">What is your name?</Typography>
      </Box>
      <TextField
        required
        fullWidth
        id="first-name"
        onChange={(e) => setFirstName(e.target.value)}
        label="First Name"
        defaultValue={defaultFirstName}
        sx={{ mb: 2 }}
      />
      <TextField
        required
        fullWidth
        onChange={(e) => setLastName(e.target.value)}
        id="last-name"
        label="Last Name"
        defaultValue={defaultLastName}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        disabled={firstName.length === 0 || lastName.length === 0}
        onClick={handleNextClick}>
        Next
      </Button>
    </Container>
  );
};

export default NameContainer;
