import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Header } from "src/components/Header";
import { RootState } from "src/stores";
import { useQuery } from "react-query";

import { getMyBandMembershipsForUser } from "./backend";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Add, CalendarMonth, Campaign, Edit } from "@mui/icons-material";
import NoBandPicture from "src/components/NoBandPicture";

const useMyBands = (id: string | null) => {
  return useQuery(
    ["my-bands", id],
    async () => {
      if (!id) {
        throw new Error("No user id");
      }
      const myBandMemberships = await getMyBandMembershipsForUser(id);
      return myBandMemberships;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const MyBands = () => {
  const { id } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const {
    data: myBandMemberships,
    isFetching: isFetchingMyBandMemberships,
    refetch: refetchBands,
  } = useMyBands(id);

  useEffect(() => {
    if (id) {
      refetchBands();
    }
  }, [id, refetchBands]);

  const renderMemberships = () => {
    if (isFetchingMyBandMemberships) {
      return (
        <Container
          sx={{
            mt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography variant="h4">Loading your bands...</Typography>
        </Container>
      );
    }
    if (myBandMemberships && myBandMemberships.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            {"No bands :("}
          </Typography>
          <Button onClick={() => navigate("/bands/new")} variant="contained">
            Create one
          </Button>
        </Box>
      );
    }
    return (
      <Grid container spacing={1}>
        {myBandMemberships?.map((membership) => {
          const { band, instrument } = membership;
          if (!band) {
            return null;
          }
          return (
            <Grid item xs={12} sm={6} key={membership.id}>
              <Card>
                <CardActionArea onClick={() => navigate(`/bands/${band.id}`)}>
                  {band.profilePictureUrl && (
                    <CardMedia
                      onClick={() => navigate(`/bands/${band.id}`)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#fafafa",
                        },
                      }}
                      component="img"
                      height="194"
                      image={band.profilePictureUrl}
                    />
                  )}
                  {!band.profilePictureUrl && (
                    <CardMedia
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <NoBandPicture size={194} iconSize="7x" />
                    </CardMedia>
                  )}
                  <CardContent>
                    <Typography>
                      {band.name} {instrument && `(${instrument})`}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    startIcon={<Edit />}
                    onClick={() => navigate(`/bands/${band.id}/edit`)}>
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    startIcon={<CalendarMonth />}
                    onClick={() => navigate(`/bands/${band.id}/shows`)}>
                    Shows
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    startIcon={<Campaign />}
                    onClick={() => navigate(`/bands/${band.id}/posts`)}>
                    Posts
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  return (
    <Box>
      <Header
        title="My Bands"
        right={
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() => navigate("/bands/new")}>
            Add Band
          </Button>
        }
      />
      <Container maxWidth="md" sx={{ mt: 3 }}>
        {renderMemberships()}
      </Container>
    </Box>
  );
};
