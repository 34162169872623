import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useQuery } from "react-query";
import { useNavigate, useParams, Link as RRLink } from "react-router-dom";
import {
  getAcceptedBandShowsForShow,
  getShow,
  getShowGoingByUserAndShow,
} from "./backend";
import { format } from "date-fns";
import NoVenuePicture from "src/components/NoVenuePicture";
import NoBandPicture from "src/components/NoBandPicture";
import { Band, BandShow } from "src/API";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/stores";
import { useEffect, useState } from "react";
import { addShowGoing, removeShowGoing } from "../Bands/backend";
import { followerCount } from "src/utils/numbers";
import { setWantsAuth } from "src/stores/AuthStore";
import VenuePicture from "src/components/VenuePicture";
import { LoadingButton } from "@mui/lab";

const useShow = (id?: string) => {
  return useQuery(
    ["show", id],
    async () => {
      if (!id) {
        return null;
      }
      const show = await getShow(id);
      return show;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useShowGoing = ({
  showId,
  userId,
}: {
  showId?: string;
  userId?: string | null;
}) => {
  return useQuery(["show", showId, "going"], async () => {
    if (!showId || !userId) {
      return null;
    }
    const going = await getShowGoingByUserAndShow({ showId, userId });
    return going;
  });
};

const useBandShowsForShow = (id?: string) => {
  return useQuery(
    ["show", id, "bands"],
    async () => {
      if (!id) {
        return null;
      }
      const bandShows = await getAcceptedBandShowsForShow(id);
      return bandShows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const ShowPage = () => {
  const { id } = useParams<{ id?: string }>();
  const { id: userId } = useSelector((state: RootState) => state.user);
  const [isGoingDict, setIsGoingDict] = useState<{ [id: string]: boolean }>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: show,
    isFetching: isFetchingShow,
    isError: isShowError,
  } = useShow(id);

  const [isGoingCount, setIsGoingCount] = useState<number>(
    show?.goingCount || 0,
  );

  const { data: showGoing, isLoading: isLoadingShowGoing } = useShowGoing({
    showId: id,
    userId,
  });

  const [isSettingGoing, setIsSettingGoing] = useState(false);

  const { data: bandShows, isFetching: isFetchingBands } =
    useBandShowsForShow(id);

  useEffect(() => {
    if (show && show.goingCount) {
      setIsGoingCount(show.goingCount);
    }
  }, [show]);

  const handleGoing = async (showId: string) => {
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      setIsSettingGoing(true);
      const result = await addShowGoing({
        userId,
        showId,
      });

      setIsGoingDict((d) => ({
        ...d,
        [showId]: true,
      }));

      if (result) {
        setIsGoingCount((c) => c + 1);
      }
    } catch (e) {
      console.log("[ERROR] error going", e);
    } finally {
      setIsSettingGoing(false);
    }
  };

  const handleNotGoing = async (showId: string) => {
    try {
      if (!userId) {
        dispatch(setWantsAuth(true));
        return;
      }
      setIsSettingGoing(true);
      const result = await removeShowGoing({
        userId,
        showId,
      });

      setIsGoingDict((d) => ({
        ...d,
        [showId]: false,
      }));

      if (result) {
        setIsGoingCount((c) => c - 1);
      }
    } catch (e) {
      console.log("[ERROR] error going", e);
    } finally {
      setIsSettingGoing(false);
    }
  };

  const renderGoing = () => {
    if (!show) {
      return null;
    }
    let goingStatus = false;
    if (isGoingDict[show.id] === undefined) {
      goingStatus = Boolean(showGoing);
    } else {
      goingStatus = isGoingDict[show.id];
    }
    const fn = goingStatus ? handleNotGoing : handleGoing;
    return (
      <LoadingButton
        onClick={() => fn(show.id)}
        color={goingStatus ? "secondary" : "primary"}
        variant="outlined"
        loading={isLoadingShowGoing || isSettingGoing}
        startIcon={
          goingStatus ? <EventAvailableIcon /> : <CalendarTodayIcon />
        }>
        I'm Going
      </LoadingButton>
    );
  };

  const renderBand = (band: Band) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ textAlign: "center" }}
        key={`band-row-${band.id}`}>
        <Card
          onClick={() => navigate(`/bands/${band.id}`)}
          sx={{
            cursor: "pointer",
          }}>
          {band.profilePictureUrl && (
            <CardMedia
              component="img"
              src={band.profilePictureUrl}
              height={150}
            />
          )}
          {!band.profilePictureUrl && (
            <CardMedia sx={{ display: "flex", justifyContent: "center" }}>
              <NoBandPicture size={150} iconSize="2x" />
            </CardMedia>
          )}
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Typography>{band.name}</Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  const renderFan = () => {
    return (
      <Avatar
        src="https://source.unsplash.com/random"
        sx={{ width: 32, height: 32 }}
      />
    );
  };
  const renderShow = () => {
    if (isFetchingShow) {
      return <CircularProgress />;
    }
    if (isShowError) {
      return <Alert severity="error">Error loading show</Alert>;
    }
    if (!show) {
      return null;
    }
    return (
      <Grid container spacing={2} key={`show-${show.id}`}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          {show.showPictureUrl && (
            <img
              src={show.showPictureUrl}
              alt="show main poster"
              style={{
                maxWidth: "100%",
                maxHeight: 300,
              }}
            />
          )}
          <Typography
            variant="h4"
            sx={{ mt: 2, textAlign: { xs: "center", sm: "left" } }}>
            {show.name}
          </Typography>
          <Typography variant="h6">
            {format(new Date(show.date), "PPP p")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}>
            {renderGoing()}
            {isGoingCount !== undefined && (
              <Typography variant="body2" sx={{ ml: { xs: 0, sm: 1 } }}>
                {followerCount(isGoingCount)}{" "}
                {isGoingCount === 1 ? "fan" : "fans"} going
              </Typography>
            )}
          </Box>
          {show.description && show.description.length > 0 && (
            <Card sx={{ my: 2 }}>
              <CardContent>
                <Typography sx={{ my: 2 }}>{show.description}</Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <>
            <Typography variant="h6">
              Bands ({(bandShows && bandShows.length) || 0})
            </Typography>
            <Grid container spacing={1}>
              {isFetchingBands && <CircularProgress />}
              {bandShows &&
                bandShows.map((bandShow: BandShow | null) => {
                  if (!bandShow || !bandShow.band) {
                    return null;
                  }
                  const { band } = bandShow;
                  return renderBand(band);
                })}
            </Grid>
          </>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Venue</Typography>
          {!show.venue && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <NoVenuePicture size={100} iconSize="3x" />
              <Alert severity="warning" sx={{ ml: 1 }}>
                <Typography>This show has not specified a venue yet</Typography>
              </Alert>
            </Box>
          )}
          {show.venue && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <VenuePicture size={100} venue={show.venue} />
              <Box sx={{ ml: 1 }}>
                <Link component={RRLink} to={`/venues/${show.venue.id}`}>
                  <Typography fontWeight="bold">{show.venue.name}</Typography>
                </Link>
                <Typography variant="body2">
                  {show.venue.address}, {show.venue.city}, {show.venue.state}
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Fans going</Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <AvatarGroup
              max={11}
              sx={{ display: "flex", justifyContent: "flex-start" }}>
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
              {renderFan()}
            </AvatarGroup>
          </Box>
        </Grid>
      </Grid>
    );
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      {renderShow()}
    </Container>
  );
};
