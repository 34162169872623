import { Box, Container, Typography } from "@mui/material";

type HeaderProps = {
  title: string;
  right?: React.ReactNode;
};

export const Header = ({ title, right }: HeaderProps) => {
  return (
    <Box sx={{ backgroundColor: "#000", color: "white" }}>
      <Container
        maxWidth="lg"
        sx={{
          py: 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">{title}</Typography>
        {right}
      </Container>
    </Box>
  );
};
