import { Container, Link } from "@mui/material";
import BandForm from "./BandForm";
import { Link as RRLink } from "react-router-dom";

export const NewBand = () => {
  return (
    <Container maxWidth="md" sx={{ pt: 5 }}>
      <Link component={RRLink} to={`/bands/mine`}>
        Back to my bands
      </Link>
      <BandForm isNew={true} />
    </Container>
  );
};
