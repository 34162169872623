export const getShow = /* GraphQL */ `
  query GetShow($id: ID!) {
    getShow(id: $id) {
      id
      name
      description
      date
      creatorId
      venueId
      showPictureUrl
      imGoing
      openingStatus
      goingCount
      venue {
        id
        name
        address
        city
        profileImageUrl
        state
      }
      createdAt
      updatedAt
    }
  }
`;

export const searchShowsByLocation = /* GraphQL */ `
  query SearchShows(
    $query: String
    $filters: String
    $searchableAttrs: [String]
    $typoTolerance: Boolean
    $aroundLatLng: String
  ) {
    searchShows(
      query: $query
      filters: $filters
      searchableAttrs: $searchableAttrs
      typoTolerance: $typoTolerance
      aroundLatLng: $aroundLatLng
    ) {
      items {
        id
        name
        description
        showPictureUrl
        openingStatus
        date
        creatorId
        createdAt
        updatedAt
        imGoing
        venue {
          id
          name
          address
          profileImageUrl
          city
          state
          icon
        }
      }
      nextToken
      total
    }
  }
`;

export const searchShows = /* GraphQL */ `
  query SearchShows(
    $query: String
    $filters: String
    $searchableAttrs: [String]
    $typoTolerance: Boolean
  ) {
    searchShows(
      query: $query
      filters: $filters
      searchableAttrs: $searchableAttrs
      typoTolerance: $typoTolerance
    ) {
      items {
        id
        name
        description
        showPictureUrl
        openingStatus
        imGoing
        date
        creatorId
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const showsByCreator = /* GraphQL */ `
  query ShowsByCreator(
    $creatorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByCreator(
      creatorId: $creatorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        showPictureUrl
        openingStatus
        date
        goingCount
        imGoing
        creatorId
        createdAt
        updatedAt
        venue {
          id
          address
          city
          createdAt
          icon
          foursquareId
          profileImageUrl
          latitude
          longitude
          name
          state
        }
      }
      nextToken
    }
  }
`;

export const addFSVenue = /* GraphQL */ `
  query AddFSVenue($foursquareId: String!) {
    addFSVenue(foursquareId: $foursquareId) {
      id
      address
      city
      createdAt
      icon
      foursquareId
      latitude
      longitude
      name
      state
      status
      updatedAt
      isBlocked
      _geoloc {
        lat
        lng
      }
    }
  }
`;

export const bandShowsByShow = /* GraphQL */ `
  query BandShowsByShow(
    $showId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandShowsByShow(
      showId: $showId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        bandId
        showId
        status
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          slug
          createdAt
          updatedAt
        }
        show {
          id
          name
          description
          date
          venueName
          venueAddress
          venueCity
          venueState
          imGoing
          venueZipcode
          showPictureUrl
          openingStatus
          venueId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandShowsByShowAndStatus = /* GraphQL */ `
  query BandShowsByShowAndStatus(
    $showId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandShowsByShowAndStatus(
      showId: $showId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        bandId
        showId
        status
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          slug
        }
        show {
          id
          name
          description
          date
          venueName
          venueAddress
          venueCity
          venueState
          venueZipcode
          showPictureUrl
          imGoing
        }
      }
      nextToken
    }
  }
`;

export const showGoingsByUserAndShow = /* GraphQL */ `
  query ShowGoingsByUserAndShow(
    $userId: ID!
    $showId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowGoingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showGoingsByUserAndShow(
      userId: $userId
      showId: $showId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        showId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
