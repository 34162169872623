export const followerCount = (count: number): number | string => {
  if (count < 1000) {
    return count;
  }
  return (count / 1000).toFixed(1) + "k";
};

export const round = (num: number, precision: number): number => {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
};
