import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

type NoBandPictureProps = {
  size: number;
  iconSize?:
    | "2xs"
    | "xs"
    | "sm"
    | "lg"
    | "xl"
    | "2xl"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";
};

const NoBandPicture = ({ size, iconSize }: NoBandPictureProps) => {
  const realIconSize = iconSize || "lg";
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: size / 30,
        border: "1px #e0e0e0 solid",
        backgroundColor: "#f0f0f0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {/* @ts-ignore */}
      <FontAwesomeIcon icon={faPeopleGroup} size={realIconSize} />
    </Box>
  );
};

export default NoBandPicture;
