import { Container } from "@mui/material";
import { ShowForm } from "./ShowForm";

export const NewShow = () => {
  return (
    <Container maxWidth="md" sx={{ pt: 5 }}>
      <ShowForm isNew={true} />
    </Container>
  );
};
