export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      username
      isFriend
      profilePictureUrl
      createdAt
      updatedAt
    }
  }
`;

export const searchUsersByUsername = /* GraphQL */ `
  query SearchUsers(
    $query: String
    $filters: String
    $searchableAttrs: [String]
    $typoTolerance: Boolean
  ) {
    searchUsers(
      query: $query
      filters: $filters
      searchableAttrs: $searchableAttrs
      typoTolerance: $typoTolerance
    ) {
      items {
        id
        firstName
        lastName
        username
        profilePictureUrl
        profileBannerUrl
      }
      nextToken
      total
    }
  }
`;

export const usersByUsername = /* GraphQL */ `
  query UsersByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        username
        isFriend
        profilePictureUrl
        profileBannerUrl
      }
      nextToken
    }
  }
`;

export const userPreferencesByUser = /* GraphQL */ `
  query UserPreferencesByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPreferencesByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        wantsEmailNotifications
        wantsBandRequestEmailNotif
        wantsBandFriendEmailNotif
        wantsUserFollowEmailNotif
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandFansByUser = /* GraphQL */ `
  query BandFansByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandFanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandFansByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bandId
        userId
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          slug
          createdAt
          updatedAt
        }
        creatorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const friendshipsByUserAndFriend = /* GraphQL */ `
  query FriendshipsByUserAndFriend(
    $userId: ID!
    $friendId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    friendshipsByUserAndFriend(
      userId: $userId
      friendId: $friendId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        friendId
        isHidden
        user {
          id
          firstName
          lastName
          username
          profilePictureUrl
          profileBannerUrl
          isFriend
        }
        friend {
          id
          firstName
          lastName
          username
          profilePictureUrl
          profileBannerUrl
          isFriend
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const friendshipRequestsBySenderAndReceiver = /* GraphQL */ `
  query FriendshipRequestsBySenderAndReceiver(
    $senderId: ID!
    $receiverId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendshipRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    friendshipRequestsBySenderAndReceiver(
      senderId: $senderId
      receiverId: $receiverId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        receiverId
        sender {
          id
          firstName
          lastName
          username
          profilePictureUrl
          profileBannerUrl
          isFriend
        }
        receiver {
          id
          firstName
          lastName
          username
          profilePictureUrl
          profileBannerUrl
          isFriend
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
