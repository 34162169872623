import { Close, Image as ImageIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "src/stores";
import {
  createBandPost,
  deleteBandPost,
  getBand,
  getBandPostsByBand,
} from "./backend";
import { BandPost as BandPostType } from "src/API";
import { BandPostCard } from "src/components/BandPostCard";
import { uploadToS3 } from "src/utils/images";

const useBandPosts = (id?: string | null) => {
  return useQuery(
    ["band", id, "posts"],
    async () => {
      if (!id) {
        return null;
      }
      const posts = await getBandPostsByBand(id);
      return posts;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBand = (id?: string | null) => {
  return useQuery(
    ["band", id],
    async () => {
      if (!id) {
        return null;
      }
      const band = await getBand(id);
      return band;
    },
    { refetchOnWindowFocus: false },
  );
};

export const BandPost = () => {
  const { id } = useParams<{ id: string }>();
  const { id: userId } = useSelector((state: RootState) => state.user);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDoneUploading, setIsDoneUploading] = useState<boolean>(false);
  const [isAddingMedia, setIsAddingMedia] = useState<boolean>(false);

  const [deletingBandPost, setDeletingBandPost] = useState<BandPostType | null>(
    null,
  );

  const [uploadingImageUrls, setUploadingImageUrls] = useState<string[] | null>(
    null,
  );

  const [newPostContent, setNewPostContent] = useState<string>("");

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const { data: posts, refetch: refetchPosts } = useBandPosts(id);

  const { data: band } = useBand(id);

  console.log(
    "posts",
    posts?.map((p) => p.id),
  );
  const handleDeleteClicked = (post: BandPostType) => {
    setDeletingBandPost(post);
  };

  const handleConfirmDeletePost = async () => {
    if (!deletingBandPost) {
      return;
    }
    try {
      setIsCreating(true);

      const result = await deleteBandPost(deletingBandPost.id);
      if (result) {
        refetchPosts();
      }
    } catch (err) {
      console.log("[ERROR] error deleting post", err);
    } finally {
      setIsCreating(false);
    }
    setDeletingBandPost(null);
  };

  const handleCreatePost = async () => {
    if (!id || !userId) {
      return;
    }
    try {
      setIsCreating(true);
      const newPost = await createBandPost({
        bandId: id,
        creatorId: userId,
        content: newPostContent,
        postImageUrls: uploadingImageUrls,
      });
      if (newPost) {
        setIsOpen(false);
        refetchPosts();
        setNewPostContent("");
        setUploadingImageUrls(null);
      }
    } catch (e) {
      console.log("[ERROR] error creating post", e);
    } finally {
      setIsCreating(false);
    }
  };

  const handleRemoveImage = (idx: number) => {
    setUploadingImageUrls((f) =>
      f ? [...f.slice(0, idx), ...f.slice(idx + 1)] : null,
    );
  };

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const numFiles = e.target.files.length;
      setIsUploading(true);
      setUploadProgress(0);
      setIsDoneUploading(false);
      let done = 0;
      const imageUrls = await Promise.all(
        Array.from(e.target.files).map(async (file) => {
          const url = await new Promise((res) => {
            const img = new Image();
            const canvas = document.createElement("canvas");

            const ctx = canvas.getContext("2d");

            img.onload = function () {
              // @ts-ignore
              const w = this.width;
              // @ts-ignore
              const h = this.height;

              let widthDivisor = 1;
              let heightDivisor = 1;
              let divisor = 1;

              const MAX_W = 1200;
              const MAX_H = 800;

              if (w > MAX_W || h > MAX_H) {
                widthDivisor = w / MAX_W;
                heightDivisor = h / MAX_H;

                divisor = Math.max(widthDivisor, heightDivisor);
              }

              const imageWidth = Math.round(w / divisor);
              const imageHeight = Math.round(h / divisor);

              canvas.width = imageWidth;
              canvas.height = imageHeight;

              if (!ctx) {
                return null;
              }

              ctx.imageSmoothingQuality = "high";

              // Actual resizing
              ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

              canvas.toBlob(
                (blob) => {
                  if (!blob) {
                    res(null);
                    return;
                  }
                  const croppedFile = new File([blob], file.name);
                  uploadToS3(croppedFile, "post-images").then(res);
                },
                "image/jpeg",
                1,
              );
            };
            img.src = URL.createObjectURL(file);
          });
          done++;
          setUploadProgress(done / numFiles);
          return url;
        }),
      );

      const filtered = imageUrls.filter((x) => x !== null) as string[];
      setIsUploading(false);

      setIsDoneUploading(true);
      setIsAddingMedia(false);

      setUploadingImageUrls((f) => (f ? [...f, ...filtered] : filtered));
    }
  };

  const renderUploadingResult = () => {
    if (isUploading || !isDoneUploading || !uploadingImageUrls) {
      return null;
    }
    return (
      <Box sx={{ width: "100%", maxWidth: 500, mt: 3, mx: "auto" }}>
        <Typography>
          {uploadingImageUrls.length}{" "}
          {uploadingImageUrls.length === 1 ? "image" : "images"} to add
        </Typography>
        <ImageList
          sx={{ width: "100%", maxWidth: 500 }}
          cols={3}
          rowHeight={125}>
          {uploadingImageUrls.map((imgSrc: string, idx: number) => {
            return (
              <ImageListItem
                key={`img-${idx}`}
                sx={{
                  border: "1px #eee solid",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                  <IconButton
                    size="small"
                    sx={{
                      backgroundColor: "rgba(255, 50, 50, 0.75)",
                      border: "1px #eee solid",
                      transform: "scale(.95)",
                      transition: "all 0.1s ease-in-out",
                      "&:hover": {
                        backgroundColor: "rgba(255, 50, 50, 1)",
                        transform: "scale(1.0)",
                      },
                    }}
                    onClick={() => handleRemoveImage(idx)}>
                    <Close sx={{ color: "white" }} />
                  </IconButton>
                </Box>
                <img
                  loading="lazy"
                  src={imgSrc}
                  alt="a test"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    );
  };

  return (
    <Container maxWidth="md" sx={{ mt: 2 }}>
      <Box
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
        maxWidth="sm">
        <TextField
          multiline
          rows={isOpen ? 5 : 1}
          onFocus={() => setIsOpen(true)}
          onChange={(e) => setNewPostContent(e.target.value)}
          value={newPostContent}
          label="Tell your fans something..."
          fullWidth
        />
        {isOpen && (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 3,
                justifyContent: "space-between",
              }}>
              <Button
                variant="contained"
                onClick={() => setIsAddingMedia(true)}
                startIcon={<ImageIcon />}>
                Add Images
              </Button>
              <Box>
                <LoadingButton
                  loading={isCreating}
                  variant="contained"
                  onClick={handleCreatePost}>
                  Create Post
                </LoadingButton>
                <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              </Box>
            </Box>
            {renderUploadingResult()}
          </Box>
        )}
      </Box>
      <Divider sx={{ my: 3 }} />
      {posts && posts.length === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            No Posts
          </Typography>
        </Box>
      )}
      {posts &&
        posts.map((post) => {
          if (!band) {
            return null;
          }
          return (
            <Box key={post.id} sx={{ mb: 2 }}>
              <BandPostCard
                band={band}
                post={post}
                handleDeleteClicked={() => handleDeleteClicked(post)}
              />
            </Box>
          );
        })}
      <Dialog open={deletingBandPost !== null}>
        <DialogTitle>Delete Post</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this post?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmDeletePost()}>Yes</Button>
          <Button onClick={() => setDeletingBandPost(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAddingMedia}>
        <DialogTitle>Add Media</DialogTitle>
        <DialogContent>
          <input
            accept="image/*"
            multiple
            max={3}
            id="contained-button-file"
            onChange={handleSelectFiles}
            type="file"
          />
          {isUploading && (
            <LinearProgress
              sx={{ mt: 3 }}
              variant="determinate"
              value={uploadProgress * 100}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddingMedia(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
