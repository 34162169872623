export const updateVenue = /* GraphQL */ `
  mutation UpdateVenue(
    $input: UpdateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    updateVenue(input: $input, condition: $condition) {
      id
      address
      city
      createdAt
      icon
      foursquareId
      latitude
      longitude
      name
      state
      status
      updatedAt
      isBlocked
      _geoloc {
        lat
        lng
      }
    }
  }
`;
