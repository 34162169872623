import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type OnboardingState = {
  id: string | null;
  firstName: string;
  lastName: string;
  username: string;
  profilePictureUrl?: string | null;
};

const initialState: OnboardingState = {
  id: null,
  firstName: "",
  lastName: "",
  username: "",
  profilePictureUrl: null,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    hydrateOnboarding: (state) => {
      const storedData = localStorage.getItem("onboarding");
      if (storedData) {
        const onboarding = JSON.parse(storedData);
        state.id = onboarding.id;
        state.firstName = onboarding.firstName;
        state.lastName = onboarding.lastName;
        state.username = onboarding.username;
        state.profilePictureUrl = onboarding.profilePictureUrl;
      }
    },
    setOnboardingProfilePictureUrl: (
      state,
      action: PayloadAction<{ profilePictureUrl?: string | null }>,
    ) => {
      state.profilePictureUrl = action.payload.profilePictureUrl;
      localStorage.setItem("onboarding", JSON.stringify(state));
    },
    resetOnboarding: (state) => {
      localStorage.removeItem("onboarding");
      Object.keys(initialState).forEach((key) => {
        // @ts-ignore
        state[key] = initialState[key];
      });
    },
    setOnboardingName: (
      state,
      action: PayloadAction<{ firstName: string; lastName: string }>,
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      localStorage.setItem("onboarding", JSON.stringify(state));
    },
    setOnboardingUsername: (
      state,
      action: PayloadAction<{ username: string }>,
    ) => {
      state.username = action.payload.username;
      localStorage.setItem("onboarding", JSON.stringify(state));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  hydrateOnboarding,
  resetOnboarding,
  setOnboardingName,
  setOnboardingUsername,
  setOnboardingProfilePictureUrl,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
