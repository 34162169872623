import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  CardActionArea,
  Box,
  Link,
} from "@mui/material";
import { useNavigate, Link as RRLink } from "react-router-dom";
import { format } from "date-fns";
import { Show } from "src/API";

const ShowCard = ({
  show,
  isEditable,
  showVenue,
}: {
  isEditable?: boolean;
  show: Show;
  showVenue?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardActionArea onClick={() => navigate(`/shows/${show.id}`)}>
        {show.showPictureUrl && (
          <CardMedia component="img" height={200} image={show.showPictureUrl} />
        )}
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}>
            {show.name}
          </Typography>
          {show.date && (
            <Typography>{format(new Date(show.date), "PPP p")}</Typography>
          )}
        </CardContent>
      </CardActionArea>
      {showVenue && show.venue && (
        <CardContent>
          <Box sx={{ my: -1 }}>
            <Link to={`/venues/${show.venue.id}`} component={RRLink}>
              {show.venue.name}
            </Link>
            <Typography>
              {show.venue.address}, {show.venue.city}, {show.venue.state}
            </Typography>
          </Box>
        </CardContent>
      )}

      {isEditable && (
        <CardActions>
          <Button
            variant="outlined"
            onClick={() => navigate(`/shows/${show.id}/edit`)}>
            Edit
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ShowCard;
