import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSwipeListener } from "src/utils/mobile";

const LightBox = ({
  pictures,
  open,
  onClose,
  activeIndex,
}: {
  pictures: string[];
  open: boolean;
  onClose: () => void;
  activeIndex: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(activeIndex);

  const theme = useTheme();

  const [hasSwiped, setHasSwiped] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const hasMultiple = pictures.length > 1;

  const onNext = () => {
    setHasSwiped(true);
    setCurrentIndex((currentIndex + 1) % pictures.length);
  };
  const onPrev = () => {
    setHasSwiped(true);
    setCurrentIndex((currentIndex - 1 + pictures.length) % pictures.length);
  };

  const onCloseResetSwipe = () => {
    setHasSwiped(false);
    onClose();
  };

  const { handleTouchStart, handleTouchMove } = useSwipeListener({
    onLeft: onPrev,
    onRight: onNext,
    onUp: onCloseResetSwipe,
  });

  useEffect(() => {
    if (activeIndex !== currentIndex) {
      setCurrentIndex(activeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    // @ts-ignore
    let timeout = null;
    if (open) {
      timeout = setTimeout(() => {
        setHasSwiped(true);
      }, 3000);
    }
    return () => {
      // @ts-ignore
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          endIcon={<Close />}
          onClick={onCloseResetSwipe}>
          Close
        </Button>
      </DialogTitle>
      <DialogContent
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchMove={(e) => handleTouchMove(e)}
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#000",
          position: "relative",
          alignItems: "center",
          overflow: "hidden",
        }}>
        <Box sx={{ p: 0, m: 0, lineHeight: 0 }}>
          <img
            src={pictures[currentIndex]}
            alt="post"
            style={{
              maxWidth: "100%",
              width: "100%",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
          {hasMultiple && (
            <Box
              sx={{
                position: "absolute",
                left: 10,
                bottom: "50%",
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.222)",
                zIndex: 9999,
                borderRadius: 100,
                cursor: "pointer",
              }}
              onClick={onPrev}>
              <ChevronLeft
                sx={{ fontSize: 50, color: "rgba(255, 255, 255, 0.85)" }}
              />
            </Box>
          )}
          {hasMultiple && (
            <Box
              sx={{
                position: "absolute",
                right: 10,
                bottom: "50%",
                backgroundColor: "rgba(255, 255, 255, 0.222)",
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
                borderRadius: 100,
                cursor: "pointer",
              }}
              onClick={onNext}>
              <ChevronRight
                sx={{ fontSize: 50, color: "rgba(255, 255, 255, 0.85)" }}
              />
            </Box>
          )}
        </Box>
        {isMobile && !hasSwiped && (
          <Box
            sx={{
              position: "absolute",
              bottom: 5,
              p: 0.5,
              backgroundColor: "rgba(0,0,0,0.5)",
              borderRadius: 100,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
            }}>
            <Typography color="white">Try swiping left / right</Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LightBox;
