import { Check, Close, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { User } from "src/API";
import { RootState } from "src/stores";
import {
  friendshipsByUserAndFriend,
  friendshipRequestBySenderAndReciever,
  requestFriendship,
  acceptFriendshipRequest,
  rejectFriendshipRequest,
  deleteFriendshipRequest,
  deleteFriendship,
} from "../backend";

const useFriendship = (userId?: string | null, friendId?: string | null) => {
  return useQuery(
    ["user", userId, "friendship", friendId],
    async () => {
      if (!userId || !friendId) {
        return null;
      }
      const friendship = await friendshipsByUserAndFriend({ userId, friendId });
      return friendship;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useFriendshipRequestToThem = (userId?: string | null) => {
  return useQuery(
    ["user", userId, "sentFriendshipRequests"],
    async () => {
      if (!userId) {
        return null;
      }
      const me = await Auth.currentAuthenticatedUser();
      const friendshipRequests = await friendshipRequestBySenderAndReciever({
        senderId: me.username,
        receiverId: userId,
      });
      return friendshipRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};
const useFriendshipRequestFromThem = (userId?: string | null) => {
  return useQuery(
    ["user", userId, "receivedFriendshipRequests"],
    async () => {
      if (!userId) {
        return null;
      }
      const me = await Auth.currentAuthenticatedUser();
      const friendshipRequests = await friendshipRequestBySenderAndReciever({
        senderId: userId,
        receiverId: me.username,
      });
      return friendshipRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

type FriendshipProps = {
  user: User;
};

const Friendship = ({ user }: FriendshipProps) => {
  const { id: userId } = useSelector((state: RootState) => state.user);
  const {
    data: friendship,
    isError,
    isFetching,
    refetch: refetchFriendship,
  } = useFriendship(userId, user.id);
  const {
    data: sentFriendshipRequest,
    isError: isSentRequestsError,
    isFetching: isFetchingSentRequests,
    refetch: refetchSentFriendshipRequests,
  } = useFriendshipRequestToThem(user.id);
  const {
    data: receivedFriendshipRequest,
    isError: isReceivedRequestsError,
    isFetching: isFetchingReceivedRequests,
    refetch: refetchReceivedFriendshipRequests,
  } = useFriendshipRequestFromThem(user.id);

  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isRejected, setIsRejected] = useState<boolean>(false);
  const [isRemovingFriend, setIsRemovingFriend] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [friendRequestAnchorEl, setFriendRequestAnchorEl] =
    useState<null | HTMLElement>(null);

  const refetchAll = async () => {
    await refetchFriendship();
    await refetchSentFriendshipRequests();
    await refetchReceivedFriendshipRequests();
  };

  const handleAddFriend = async () => {
    try {
      if (!user.id) {
        return;
      }
      const me = await Auth.currentAuthenticatedUser();
      if (!me) {
        return;
      }
      const request = await requestFriendship({
        userId: me.username,
        friendId: user.id,
      });
      if (request) {
        await refetchFriendship();
        await refetchSentFriendshipRequests();
        await refetchReceivedFriendshipRequests();
      }
    } catch (err) {
      console.log("[ERROR] error adding friend", err);
    }
  };
  const handleAcceptFriend = async () => {
    try {
      if (receivedFriendshipRequest) {
        setIsWorking(true);
        const result = await acceptFriendshipRequest(
          receivedFriendshipRequest.id,
        );
        if (result) {
          setIsAccepted(true);
          await refetchAll();
        }
      }
    } catch (e) {
      console.log("[ERROR] error acccepting", e);
    } finally {
      setIsWorking(false);
    }
  };
  const handleRejectFriend = async () => {
    try {
      if (receivedFriendshipRequest) {
        setIsWorking(true);
        const result = await rejectFriendshipRequest(
          receivedFriendshipRequest.id,
        );
        if (result) {
          setIsRejected(true);
          await refetchAll();
        }
      }
    } catch (e) {
      console.log("[ERROR] error rejecting", e);
    } finally {
      setIsWorking(false);
    }
  };

  const handleDeleteFriendRequest = async () => {
    try {
      setIsWorking(true);
      if (sentFriendshipRequest) {
        const result = await deleteFriendshipRequest(sentFriendshipRequest.id);
        if (result) {
          await refetchAll();
        }
      }
    } catch (e) {
      console.log("[ERROR] error deleting", e);
    } finally {
      setIsWorking(false);
    }
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleOpenResponseMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFriendRequestAnchorEl(e.currentTarget);
  };

  const handleRemoveFriend = () => {
    setIsRemovingFriend(true);
  };

  const handleRemoveFriendConfirm = async () => {
    try {
      if (friendship) {
        setIsRemovingFriend(false);
        setIsWorking(true);
        const result = await deleteFriendship({
          friendId: friendship.friendId,
        });
        if (result) {
          await refetchAll();
        }
      }
    } catch (e) {
      console.log("[ERROR] error removing", e);
    } finally {
      setIsWorking(false);
      setAnchorEl(null);
    }
  };
  if (
    isFetching ||
    isFetchingSentRequests ||
    isFetchingReceivedRequests ||
    isWorking
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
        }}>
        <CircularProgress />
      </Box>
    );
  }
  if (isSentRequestsError || isReceivedRequestsError || isError) {
    return null;
  }
  if (isAccepted) {
    return <Typography>Friendship accepted!</Typography>;
  }
  if (isRejected) {
    return <Typography>Friendship rejected!</Typography>;
  }
  if (friendship) {
    return (
      <Box>
        <LoadingButton
          variant="outlined"
          loading={isWorking}
          onClick={(e) => handleOpenMenu(e)}
          endIcon={<MoreHoriz />}>
          Friends
        </LoadingButton>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleRemoveFriend}>Remove friend</MenuItem>
        </Menu>
        <Dialog
          open={isRemovingFriend}
          onClose={() => setIsRemovingFriend(false)}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            Are you sure you want to remove this friend?
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={isWorking}
              onClick={handleRemoveFriendConfirm}>
              Yes
            </LoadingButton>
            <Button onClick={() => setIsRemovingFriend(false)}>No</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
  if (sentFriendshipRequest) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body2">Friend request pending</Typography>
        <Button
          color="error"
          variant="outlined"
          onClick={handleDeleteFriendRequest}>
          Delete Friend Request
        </Button>
      </Box>
    );
  }
  if (receivedFriendshipRequest) {
    return (
      <Box>
        <Button
          onClick={(e) => handleOpenResponseMenu(e)}
          variant="outlined"
          color="info">
          Respond to Friend Request
        </Button>
        <Menu
          open={Boolean(friendRequestAnchorEl)}
          anchorEl={friendRequestAnchorEl}
          onClose={() => setFriendRequestAnchorEl(null)}>
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "white",
              },
            }}>
            <Button
              onClick={handleAcceptFriend}
              variant="contained"
              startIcon={<Check />}
              color="secondary">
              Accept Friend Request
            </Button>
          </MenuItem>
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "white",
              },
            }}>
            <Button
              onClick={handleRejectFriend}
              color="error"
              startIcon={<Close />}
              variant="outlined">
              Reject friend Request
            </Button>
          </MenuItem>
        </Menu>
      </Box>
    );
  }
  return (
    <Button onClick={handleAddFriend} variant="contained">
      Add Friend
    </Button>
  );
};

export default Friendship;
