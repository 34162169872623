import { getMyBandRequests, respondToBandMemberRequest } from "../backend";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BandMemberRequest } from "src/API";
import { useEffect, useState } from "react";
import { refreshSession } from "src/utils/auth";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "@mui/icons-material";

const useMyBandRequests = (userId?: string | null) => {
  return useQuery(
    ["myBandRequests", userId],
    async () => {
      if (!userId) {
        return null;
      }
      const bandRequests = await getMyBandRequests(userId);
      return bandRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const BandMemberRequests = ({
  onDoneLoading,
}: {
  onDoneLoading: (n: number) => void;
}) => {
  const { id: userId } = useSelector((state: RootState) => state.user);

  const [isActing, setIsActing] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isExpanded, setIsExpanded] = useState<boolean>(!isMobile);

  const {
    data: bandRequests,
    isFetching: isFetchingBandRequests,
    isError: isBandRequestsError,
    isLoading,
    refetch,
  } = useMyBandRequests(userId);

  const navigate = useNavigate();

  const handleAcceptRequest = async (bandRequest: BandMemberRequest) => {
    setIsActing(true);
    try {
      await respondToBandMemberRequest(bandRequest, "ACCEPT");
      refreshSession();
      refetch();
    } catch (e) {
      console.error(e);
    } finally {
      setIsActing(false);
    }
  };

  const handleRejectRequest = async (bandRequest: BandMemberRequest) => {
    setIsActing(true);
    try {
      await respondToBandMemberRequest(bandRequest, "REJECT");
      refetch();
    } catch (e) {
      console.error(e);
    } finally {
      setIsActing(false);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      onDoneLoading(bandRequests?.length || 0);
    }
  }, [bandRequests?.length, isLoading, onDoneLoading]);

  const renderBandRequests = () => {
    if (isFetchingBandRequests || isBandRequestsError) {
      return null;
    }
    if (bandRequests && bandRequests.length > 0) {
      return (
        <Box sx={{ borderTop: "1px #f0f0f0 solid", mt: 3, pt: 1 }}>
          <Button
            onClick={() => setIsExpanded((e) => !e)}
            startIcon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowRight
                  sx={{
                    fontSize: 40,
                    transition: "all 0.111s ease-in-out",
                    transform: isExpanded ? "rotate(90deg)" : undefined,
                  }}
                />
              </Box>
            }>
            <Typography variant="h6">
              Band Member Requests {bandRequests && `(${bandRequests.length})`}
            </Typography>
          </Button>
          <Grid
            container
            spacing={1}
            sx={{ display: isExpanded ? undefined : "none" }}>
            {bandRequests.map((bandRequest) => {
              const { band } = bandRequest;
              if (!band) {
                return null;
              }
              return (
                <Grid item xs={12} md={6} key={bandRequest.id}>
                  <Card>
                    <CardContent
                      onClick={() => navigate(`/bands/${bandRequest.bandId}`)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#fafafa",
                        },
                      }}>
                      <Typography>
                        You've been invited to join the band
                      </Typography>
                      {band.profilePictureUrl ? (
                        <img
                          src={band.profilePictureUrl}
                          alt={band.name}
                          style={{ width: 100, height: 100 }}
                        />
                      ) : null}
                      <Typography>{band.name}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        sx={{ display: "flex", flexGrow: 1 }}
                        onClick={() =>
                          navigate(`/bands/${bandRequest.bandId}`)
                        }>
                        See Band
                      </Button>
                      <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={isActing}
                        onClick={() => handleAcceptRequest(bandRequest)}>
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        disabled={isActing}
                        onClick={() => handleRejectRequest(bandRequest)}>
                        Reject
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      );
    }
  };
  return <>{renderBandRequests()}</>;
};

export default BandMemberRequests;
