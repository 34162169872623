import { ArrowRight, Check, Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoUserPicture from "src/components/NoUserPicture";
import {
  acceptFriendshipRequest,
  rejectFriendshipRequest,
} from "src/containers/Profile/backend";
import { RootState } from "src/stores";
import { getMyPendingFriendRequests } from "../backend";

const usePendingFriendshipRequests = (id?: string | null) => {
  return useQuery(
    ["user", id, "pendingFriendshipRequests"],
    async () => {
      if (!id) {
        return null;
      }
      const pendingFriendRequests = await getMyPendingFriendRequests(id);
      return pendingFriendRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const FriendshipRequests = ({
  onDoneLoading,
}: {
  onDoneLoading: (n: number) => void;
}) => {
  const { id: userId } = useSelector((state: RootState) => state.user);
  const {
    data: friendshipRequests,
    isFetching,
    isLoading,
    refetch,
  } = usePendingFriendshipRequests(userId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isExpanded, setIsExpanded] = useState<boolean>(!isMobile);

  const [responding, setResponding] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const navigate = useNavigate();

  const handleAccept = async (friendshipRequestId: string) => {
    try {
      const result = await acceptFriendshipRequest(friendshipRequestId);
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error accepting band show request", e);
    }
  };
  const handleReject = async (friendshipRequestId: string) => {
    try {
      const result = await rejectFriendshipRequest(friendshipRequestId);
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error rejecting band show request", e);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      onDoneLoading(friendshipRequests?.length || 0);
    }
  }, [isLoading, onDoneLoading, friendshipRequests]);

  const renderFriendshipRequests = () => {
    if (isFetching || !friendshipRequests || friendshipRequests.length === 0) {
      return null;
    }
    return (
      <Box sx={{ borderTop: "1px #f0f0f0 solid", mt: 3, pt: 1 }}>
        <Button
          size="large"
          onClick={() => setIsExpanded((e) => !e)}
          startIcon={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowRight
                sx={{
                  fontSize: 40,
                  transition: "all 0.111s ease-in-out",
                  transform: isExpanded ? "rotate(90deg)" : undefined,
                }}
              />
            </Box>
          }>
          <Typography variant="h6">
            Friendship Requests{" "}
            {friendshipRequests && `(${friendshipRequests.length})`}
          </Typography>
        </Button>
        <List sx={{ my: 1, display: isExpanded ? undefined : "none" }}>
          {friendshipRequests.map((friendshipRequest) => {
            const { sender } = friendshipRequest;
            if (!sender) {
              return null;
            }
            return (
              <ListItem
                key={`request-${friendshipRequest.id}`}
                alignItems="flex-start"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  borderBottom: "1px #f0f0f0 solid",
                }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ListItemAvatar
                    onClick={() => navigate(`/users/${sender.id}`)}
                    sx={{ cursor: "pointer" }}>
                    {sender.profilePictureUrl ? (
                      <Avatar src={sender.profilePictureUrl} />
                    ) : (
                      <NoUserPicture size={50} />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    onClick={() => navigate(`/users/${sender.id}`)}
                    sx={{ cursor: "pointer", pr: 1 }}
                    primary="New Friend Request"
                    secondary={`${sender.firstName} ${sender.lastName} has sent you a friend
                    request`}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "auto" },
                    justifyContent: { xs: "flex-end", sm: "center" },
                  }}>
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      setResponding({
                        ...responding,
                        [friendshipRequest.id]: e.currentTarget,
                      });
                    }}>
                    Respond
                  </Button>
                  <Menu
                    open={Boolean(responding[friendshipRequest.id])}
                    onClose={() =>
                      setResponding({
                        ...responding,
                        [friendshipRequest.id]: null,
                      })
                    }
                    anchorEl={responding[friendshipRequest.id]}>
                    <MenuItem sx={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        startIcon={<Check />}
                        onClick={() => handleAccept(friendshipRequest.id)}>
                        Accept
                      </Button>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        startIcon={<Close />}
                        onClick={() => handleReject(friendshipRequest.id)}
                        sx={{ mt: 1 }}>
                        Reject
                      </Button>
                    </MenuItem>
                  </Menu>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  return renderFriendshipRequests();
};

export default FriendshipRequests;
