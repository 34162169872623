import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BandPost } from "src/API";

export interface CheersState {
  [key: string]: number;
}

const initialState: CheersState = {};

export const cheersSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addCheers: (state, action: PayloadAction<BandPost[]>) => {
      action.payload.forEach((post) => {
        state[post.id] = post.numCheers || 0;
      });
    },
    setPostCheer: (state, action: PayloadAction<BandPost>) => {
      state[action.payload.id] = action.payload.numCheers || 0;
    },

    setCheerCount: (
      state,
      action: PayloadAction<{ id: string; count: number }>,
    ) => {
      state[action.payload.id] = action.payload.count;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addCheers, setPostCheer, setCheerCount } = cheersSlice.actions;

export default cheersSlice.reducer;
