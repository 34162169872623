import API, { GraphQLResult } from "@aws-amplify/api";
import * as queries from "./queries";
import * as mutations from "./mutations";
import { apiGraphqlOperationWithAuth } from "src/utils/query";
import { Show, Venue } from "src/API";
import { formatISO } from "date-fns";
import { graphqlOperation } from "aws-amplify";

export const getVenueById = async (id: string): Promise<Venue> => {
  const result = (await apiGraphqlOperationWithAuth(queries.getVenue, {
    id,
  })) as GraphQLResult<{ getVenue: Venue }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.getVenue) {
    return result.data?.getVenue;
  }
  throw new Error("No venue found");
};

export const getShowsForVenue = async (venueId: string): Promise<Show[]> => {
  const now = formatISO(new Date());
  const result = (await apiGraphqlOperationWithAuth(
    queries.showsByVenueAndDate,
    {
      venueId,
      date: { ge: now },
    },
  )) as GraphQLResult<{ showsByVenueAndDate: { items: Show[] } }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.showsByVenueAndDate) {
    return result.data?.showsByVenueAndDate.items;
  }
  throw new Error("No shows found");
};

export const searchVenues = async (query: string): Promise<Venue[]> => {
  const result = (await apiGraphqlOperationWithAuth(queries.searchVenues, {
    query,
  })) as GraphQLResult<{ searchVenues: { items: Venue[] } }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.searchVenues) {
    return result.data?.searchVenues.items;
  }
  throw new Error("No venues found");
};

type UpdateVenueInput = {
  id: string;
  address?: string;
  profileImageUrl?: string | null;
  name?: string;
  status?: string;
};

export const updateVenue = async ({
  id,
  profileImageUrl,
  name,
  address,
  status,
}: UpdateVenueInput): Promise<Venue> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateVenue, {
      input: { id, profileImageUrl, name, address, status },
    }),
  )) as GraphQLResult<{ updateVenue: Venue }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.updateVenue) {
    return result.data?.updateVenue;
  }
  throw new Error("No venue found");
};
