import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import * as queries from "./queries";
import * as mutations from "./mutations";
import {
  BandPostCheer,
  BandPostTimeline,
  BandPostTimelineStatus,
  User,
} from "src/API";
import { getTimeStamp } from "src/utils/time";

export const getMyTimelines = async (
  userId: string,
): Promise<BandPostTimeline[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandPostTimelinesByUserAndStatusAndTime, {
      userId,
      createdAt: { ge: getTimeStamp() },
      status: BandPostTimelineStatus.VISIBLE,
      sortDirection: "DESC",
    }),
  )) as GraphQLResult<{
    bandPostTimelinesByUserAndStatusAndTime: { items: BandPostTimeline[] };
  }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting the band post timelines:",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  return result.data?.bandPostTimelinesByUserAndStatusAndTime?.items || [];
};

export const cheerPost = async ({
  userId,
  bandPostId,
}: {
  userId: string;
  bandPostId: string;
}): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createBandPostCheer, {
      input: {
        userId,
        bandPostId,
      },
    }),
  )) as GraphQLResult<{ createBandPostCheer: BandPostCheer }>;

  if (result.errors) {
    console.log(
      "[ERROR] error cheering the band post timeline:",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.createBandPostCheer) {
    return true;
  }
  throw new Error("[ERROR] error cheering the band post timeline");
};

export const searchUsers = async (query: string): Promise<User[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.searchUsers, { query }),
  )) as GraphQLResult<{
    searchUsers: { items: User[]; nextToken: string | null };
  }>;

  return result.data?.searchUsers.items ?? [];
};

export const unCheerPost = async ({
  userId,
  bandPostId,
}: {
  userId: string;
  bandPostId: string;
}): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandPostCheersByUserAndBandPost, {
      userId,
      bandPostId: { eq: bandPostId },
    }),
  )) as GraphQLResult<{
    bandPostCheersByUserAndBandPost: { items: BandPostCheer[] };
  }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting band post cheers:",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.bandPostCheersByUserAndBandPost?.items?.length) {
    await Promise.all(
      result.data?.bandPostCheersByUserAndBandPost?.items.map(async (cheer) => {
        await API.graphql(
          graphqlOperation(mutations.deleteBandPostCheer, {
            input: {
              id: cheer.id,
            },
          }),
        );
      }),
    );
    return true;
  }
  throw new Error("[ERROR] error uncheering the band post timeline");
};

export const hidePostTimeline = async (
  timeline: BandPostTimeline,
): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBandPostTimeline, {
      input: {
        id: timeline.id,
        status: BandPostTimelineStatus.HIDDEN,
        createdAt: timeline.createdAt,
      },
    }),
  )) as GraphQLResult<{ updateBandPostTimeline: BandPostTimeline }>;

  if (result.errors) {
    console.log(
      "[ERROR] error hiding the band post timeline:",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  return true;
};

export const unHidePostTimeline = async (
  timeline: BandPostTimeline,
): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBandPostTimeline, {
      input: {
        id: timeline.id,
        status: BandPostTimelineStatus.VISIBLE,
        createdAt: timeline.createdAt,
      },
    }),
  )) as GraphQLResult<{ updateBandPostTimeline: BandPostTimeline }>;

  if (result.errors) {
    console.log(
      "[ERROR] error unhiding the band post timeline:",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  return true;
};

export const flagPostTimeline = async (
  timeline: BandPostTimeline,
): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBandPostTimeline, {
      input: {
        id: timeline.id,
        status: BandPostTimelineStatus.FLAGGED,
        createdAt: timeline.createdAt,
      },
    }),
  )) as GraphQLResult<{ updateBandPostTimeline: BandPostTimeline }>;

  if (result.errors) {
    console.log(
      "[ERROR] error flagging the band post timeline:",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  return true;
};
